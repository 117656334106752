import React, { Component } from "react";
import {OTSession, OTStreams, OTSubscriber} from "opentok-react";

import {IJoinToTheCallResponse, IFullEvent, IUserInfo, UserShortDTO} from '../store';

import {EventShortDescription} from "./EventShortDescription";
import {VideoCallControls} from "./VideoCallControls";
import {VideoCallHeader} from "./VideoCallHeader";

interface IProps {
  credentials: IJoinToTheCallResponse,
  cancelBtnClick: () => void,
  event: IFullEvent,
  doctor?: UserShortDTO
}

interface IState {
  dimensions: {width: number, height: number},
  isMinimizedMode: boolean,
  sessionStatus: boolean,
  subscriberStatus: boolean
}

const apiKey = '46573072';
let timer: number | null | NodeJS.Timeout = null;

export default class OTSessionWrapper extends Component<IProps, IState>{
  state = {
    dimensions: {
      width: window.innerWidth,
      height: window.innerHeight
    },
    isMinimizedMode: false,
    sessionStatus: false,
    subscriberStatus: false
  }

  OTSessionRef = React.createRef<any>();

  componentDidMount() {
    window.addEventListener("resize", this.handleResize)
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
    if (prevState.sessionStatus !== this.state.sessionStatus || prevState.isMinimizedMode !== this.state.isMinimizedMode) {
      if (this.state.sessionStatus) {
        if (this.state.isMinimizedMode) {
          if (typeof timer === "number") clearTimeout(timer);
        } else {
          timer = setTimeout(() => this.toggleVisibleMode(), 4000);
        }
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    if (typeof timer === "number") clearTimeout(timer);
  }

  handleResize = () => {
    this.setState({dimensions: {width: window.innerWidth, height: window.innerHeight}})
  }

  toggleVisibleMode = () => {
    if (this.state.sessionStatus) {
      this.setState(prevValue => ({
        isMinimizedMode: (!prevValue.isMinimizedMode)
      }));
    }
  };

  eventHandlers = {
    connectionCreated: () => {
      if(this.state.sessionStatus){
        this.setState({ subscriberStatus: true });
      } else {
        this.setState({ sessionStatus: true });
      }
    },
    connectionDestroyed: () => {
      this.setState({ sessionStatus: false });
    },
  };

  render() {
    const { dimensions, sessionStatus, subscriberStatus, isMinimizedMode } = this.state;
    const { cancelBtnClick, event, credentials, doctor } = this.props;

    let widthSubscriber = dimensions.width > 700 ? 700 : dimensions.width;
    let heightSubscriber = widthSubscriber / 16 * 9;

    return(
      <section className={"videocall" + (sessionStatus && subscriberStatus ? " light" : "")}>
      <div className="container">
      <OTSession
        apiKey={apiKey}
        sessionId={credentials.sessionId}
        token={credentials.token}
        eventHandlers={this.eventHandlers}
        ref={this.OTSessionRef}>
        {sessionStatus && subscriberStatus ? (
          <>
            <VideoCallHeader
              name={doctor?.name || "Unknown"}
              startDate={new Date(event.startDate)}
              endDate={new Date(event.endDate)}
            />
            <div className="doctor-frame-wrap" onClick={this.toggleVisibleMode}>
              <OTStreams>
                <OTSubscriber
                  onSubscribe={() => {
                    this.setState({ subscriberStatus: true })
                  }}
                  properties={{ width: widthSubscriber, height: heightSubscriber }}
                  className="doctor-frame"
                  onError={(e:any) => {
                    this.setState({ subscriberStatus: false })
                  }}
                />
              </OTStreams>
            </div>
          </>
        ) : (
          <EventShortDescription {...event}/>
        )}

        <VideoCallControls
          onViewPress={this.toggleVisibleMode}
          isMinimizedMode={isMinimizedMode}
          cancelBtnClick={cancelBtnClick}
          isMobile={dimensions.width < 768}
        />
      </OTSession>
        </div>
      </section>
    )
  }
}
