import React, {CSSProperties} from 'react';

interface IFiledIconProps {
  style?: CSSProperties;
  fillColor?: string;
  className?: string;
}

export const FiledIcon: React.FC<IFiledIconProps> = props => {
  const { style, fillColor, className } = props;

  return (
    <svg style={style} viewBox="0 0 12 12" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 11.0769C8.80391 11.0769 11.0769 8.80391 11.0769 6C11.0769 3.19609 8.80391 0.923077 6 0.923077C3.19609 0.923077 0.923077 3.19609 0.923077 6C0.923077 8.80391 3.19609 11.0769 6 11.0769ZM6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z"
        fill={fillColor || '#F06C5D'}
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2C6.27614 2 6.5 2.22386 6.5 2.5L6.5 6.5C6.5 6.77614 6.27614 7 6 7C5.72386 7 5.5 6.77614 5.5 6.5L5.5 2.5C5.5 2.22386 5.72386 2 6 2Z"
        fill={fillColor || '#F06C5D'}
      />

      <path
        d="M6.5 8.5C6.5 8.77614 6.27614 9 6 9C5.72386 9 5.5 8.77614 5.5 8.5C5.5 8.22386 5.72386 8 6 8C6.27614 8 6.5 8.22386 6.5 8.5Z"
        fill={fillColor || '#F06C5D'}
      />
    </svg>
  );
};
