import * as React from 'react';

// Views
import { RootView } from './views/Root';

const App = () => (
  <RootView />
);

export default App;
