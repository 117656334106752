import React from 'react';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { getUserAttributes, getUserState, updateProfileAction } from '../../store';

// Components
import { AvatarField } from '../../components/AvatarField';
import { TextField } from '../../components/TextField';
import { Loader } from '../../components/Loader';
import { Notification } from '../../components/Notification'
import Link from "../../routing/Link";

import { notificationsService } from '../../services/notificationsService'

// constants
import { IUserFormFields, userFormValidation } from '../../constants/validation';

//Images
import {BackArrowIcon} from "../../assets/img/icons/Arrow";
import {personalNumberFormatting} from "../../utils/personalNumberFormatting";

export const EditAccount: React.FC = () => {
  const dispatch = useDispatch();
  const userState = useSelector(getUserState);
  const attributes = useSelector(getUserAttributes);
  const [error, setError] = React.useState<string>('');

  const [selectedAvatar, setSelectedAvatar] = React.useState<string | null>(attributes?.profileImageUrl || null)

  const [avatar, setAvatarFile] = React.useState<File | null>(null);
  const initialValues: IUserFormFields = {
    email: attributes?.email || "",
    phone: attributes?.phoneNumber?.replace(/^\+/, "") || "",
  };

  const isFormValid = (values: { email: string, phone: string }): boolean => {
    return Object.keys(userFormValidation(values)).length == 0;
  }

  const accountDataUpdated = (updatedValues: { email: string, phone: string }): boolean => {
    return (
      initialValues.email !== updatedValues.email ||
      initialValues.phone !== updatedValues.phone.replace(/[\s+]/g, '')
    );
  }

  const handleSubmit = (values: IUserFormFields) => {
    const formData = new FormData();

    attributes?.personalNumber && formData.append('personalNumber', attributes?.personalNumber);
    formData.append('phone', values.phone.split(' ').join('') );

    if (avatar && avatar.name && "lastModified" in avatar) formData.append('avatar', avatar);
    if (attributes?.profileImageUrl) formData.append('avatarUri', attributes?.profileImageUrl);
    if (values.email) formData.append('email', values.email);

    try {
      dispatch(updateProfileAction.request({
        data: formData,
        cb: (msg: string) => {
          notificationsService.success(msg);
        }
      }));
    } catch (e) {
      notificationsService.error('Uppdateringen misslyckades');
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={userFormValidation}>
      {({ handleSubmit, values }) => (
        <section className="edit-acc">
          <div className="container">
            {userState?.isLoading && <Loader />}
            <div className="title-section">
              <Link to="account" className="btn-close">
                <BackArrowIcon className="arrow-back"/>
              </Link>
              Redigera kontot
            </div>

            <div className="user-info">
              <div className="user-photo">
                <AvatarField {...{ selectedAvatar, setAvatarFile, setSelectedAvatar, setError }} />
              </div>

              { attributes ? <span className="user-name text">{attributes.name}</span> : ""}
              { attributes && attributes.personalNumber ? <span className="user-code text">{personalNumberFormatting(attributes.personalNumber)}</span> : ""}
            </div>

            <div className="form form-edit_account">
              <TextField
                required
                labelText="Telefon"
                name="phone"
                type="phone"
              />

              <TextField
                required
                labelText="Mejladress"
                name="email"
              />
              <div className="fixed-btn">
                <button
                  className="btn btn-darker"
                  type="button"
                  onClick={() => handleSubmit()}
                  disabled={!accountDataUpdated(values)}
                >
                  Spara ändringar
                </button>
              </div>
            </div>
            <Notification />
          </div>
        </section>
      )}
    </Formik>
  )
};
