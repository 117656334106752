export interface IUserFormFields {
  email: string;
  phone: string;
}

export const userFormValidation = ({ email, phone }: IUserFormFields) => {
  const errors: Partial<IUserFormFields> = {};

  if (!email) {
    errors.email = 'Mejladress krävs!';
  } else if (
    !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email,
    )
  ) {
    errors.email = 'Ej giltig mejladress!';
  }
  if (!phone) {
    errors.phone = 'Telefonnummer krävs!';
  } else if (phone) {
    let testedPhone = phone.split(' ').join('');
    if(testedPhone[0] === "+"){
      testedPhone = testedPhone.slice(1);
    }
    if(!(/^\d{9}$/.test(testedPhone) || /^\d{10}$/.test(testedPhone) || /^\d{11}$/.test(testedPhone) || /^\d{12}$/.test(testedPhone))){
      errors.phone = 'Ej giltigt telefonnummer!';
    }
  }

  return errors;
};
