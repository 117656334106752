import { select, take, put } from "redux-saga/effects";

// Models
import { IEvent } from '../../../API';

// Api
import { EventsAPI } from '../../../API';

// Exceptions
import { Unauthorized } from '../../../Exceptions';

// Actions
import { fetchEventsAction } from '../actions';
import { forceLogout } from "../../User/handlers/auth";

// Selectors
import { getUserId, getAuthToken } from '../../User';

export function* fetchEventsSaga() {
  while (true) {
    yield take(fetchEventsAction.request);

    const userId: number = yield select(getUserId);
    const authToken: string = yield select(getAuthToken);

    console.log({
      userId,
      authToken,
    });

    if (!userId || !authToken) {
      yield put(fetchEventsAction.failure());
    } else {
      try {
        const res = (yield EventsAPI.fetchUserEvents(
          userId,
          authToken
        )) as IEvent[];

        yield put(
          fetchEventsAction.success({
            events: res,
          })
        );
      } catch (error) {
        yield put(fetchEventsAction.failure());

        if (error instanceof Unauthorized) {
          yield forceLogout();
        }
      }
    }
  }
}
