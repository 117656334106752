import React from 'react';

interface ICloseIconProps {
  style?: React.CSSProperties;
  fillColor?: string;
  className?: string;
}

export const CloseIcon: React.FC<ICloseIconProps> = props => {
  const { style, fillColor, className } = props;

  return (
    <svg
      style={style}
      className={className || ""}
      viewBox="0 0 10 10"
      width="10"
      height="10"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fillColor || "white"}
        d="M1.35359 0.646484L9.35359 8.64648L8.64648 9.35359L0.646484 1.35359L1.35359 0.646484Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fillColor || "white"}
        d="M0.646484 8.64653L8.64648 0.646532L9.35359 1.35364L1.35359 9.35364L0.646484 8.64653Z"
      />
    </svg>
  );
};

export const CloseStatusIcon: React.FC<ICloseIconProps> = props => {
  const { style, fillColor } = props;

  return (
    <svg style={style} viewBox="0 0 12 12">
      <path
        d="M10 2L2 10"
        stroke={fillColor || "#F06C5D"}
      />

      <path
        d="M10 10L2 2"
        stroke={fillColor || "#F06C5D"}
      />
    </svg>
    // <svg
    //   width="24"
    //   height="24"
    //   xmlns="http://www.w3.org/2000/svg"
    //   fill-rule="evenodd"
    //   clip-rule="evenodd"
    // >
    //   <path d="M12 11.293l10.293-10.293.707.707-10.293 10.293 10.293 10.293-.707.707-10.293-10.293-10.293 10.293-.707-.707 10.293-10.293-10.293-10.293.707-.707 10.293 10.293z"/>
    // </svg>
  );
};