import React from 'react';

interface Props {
   style?: React.CSSProperties;
   fillColor?: string;
   className?: string;
}

export const DownloadAppQR: React.FC<Props> = (props) => {
   return (
      <svg
         version="1.1"
         id="Layer_1"
         x="0px"
         y="0px"
         width="90px"
         height="90px"
         viewBox="0 0 478.2 478.2"
         // xmlns="http://www.w3.org/2000/svg"
         // xmlns:xlink="http://www.w3.org/1999/xlink"
         // xml:space="preserve"
      >
         <g id="sINH7C.tif">
            <g>
               <path
                  d="M351.6,440.1c-15.9,0-31.9,0-47.8,0c-0.5,0-1.1,0-1.6,0c-2.3-0.1-3.9-1.5-3.9-3.8c-0.1-3.3-0.1-6.6,0-9.9
                  c0-2.3,1.5-3.8,3.8-4c0.6,0,1.2,0,1.8,0c19.9,0,39.8,0,59.7,0c0.6,0,1.2,0,1.8,0c2.3-0.1,3.7-1.5,3.8-3.7c0-0.5,0-1.1,0-1.6
                  c0-8.3,0-16.5,0-24.8c0-0.4,0-0.8,0-1.2c-0.1-2.6-1.5-4.1-4.1-4.1c-3.2,0-6.3,0-9.5,0c-2.6,0-4.1,1.5-4.1,4.1
                  c-0.1,2.7,0,5.4,0,8.1c0,0.6,0,1.2,0,1.8c-0.1,2.2-1.5,3.7-3.6,3.7c-3.5,0.1-7,0.1-10.5,0c-2.2-0.1-3.5-1.7-3.6-4
                  c-0.1-2.7,0-5.4,0-8.1c0-0.6,0-1.2,0-1.8c-0.2-2.4-1.6-3.8-4-3.8c-3.2,0-6.5,0-9.7,0c-2.5,0-3.9,1.5-4,4c0,3.1,0,6.2,0,9.3
                  c0,2.9-1.5,4.4-4.4,4.5c-8.9,0-17.9,0-26.8,0c-2.9,0-4.4-1.5-4.4-4.4c0-3,0.1-6,0-8.9c-0.1-3,2-4.6,4.6-4.4
                  c2.9,0.2,5.8-0.1,8.7,0.1c2.4,0.1,4.5-1.6,4.5-4.4c-0.1-20.8,0-41.5,0-62.3c0-2.9-1.5-4.3-4.4-4.3c-2.9,0-5.8,0-8.7,0
                  c-3.1,0-4.5,1.4-4.5,4.5c0,6.4,0,12.8,0,19.2c0,12.6,1.2,11.9-11.9,11.8c-0.4,0-0.8,0-1.2,0c-3.3,0-4.7-1.4-4.7-4.8
                  c0-14.6,0-29.2,0-43.8c0-3.4,1.4-4.7,4.9-4.7c14.4,0,28.7,0,43.1,0c4.2,0,5.3-1,5.3-5.2c0-8.3,0-16.5,0-24.8c0-0.4,0-0.8,0-1.2
                  c-0.1-2.7-1.5-4.2-4.2-4.2c-3,0-6.1,0-9.1,0c-3,0-4.4-1.4-4.4-4.3c0-3,0-6,0-8.9c0-3,1.5-4.5,4.5-4.5c3.2,0,6.5,0,9.7,0
                  c5.6,0,11.1-0.1,16.7,0c3.2,0.1,4.7-2,4.6-4.6c-0.1-2.7,0-5.4,0-8.1c0-0.5,0-1.1,0-1.6c0.2-1.9,1.4-3.1,3.4-3.3
                  c0.6-0.1,1.2-0.1,1.8-0.1c20.2,0,40.3,0,60.5,0c0.4,0,0.8,0,1.2,0c2.7,0.1,4,1.4,4,4.1c0.1,3.2,0.1,6.3,0,9.5c0,2.7-1.4,4-4,4.1
                  c-2.3,0.1-4.6,0-6.9,0c-0.9,0-1.9,0-2.8,0c-2.6,0.1-3.8,1.5-3.9,4c0,0.5,0,0.9,0,1.4c0,8.4-0.1,16.8,0,25.2c0,3-1.7,5-4.8,4.9
                  c-2.8-0.1-5.7,0-8.5,0c-3.1,0-4.5-1.4-4.5-4.5c0-2.9,0-5.8,0-8.7c0-3.2-1.4-4.6-4.6-4.6c-2.8,0-5.7,0-8.5,0
                  c-3.1,0-4.5,1.4-4.6,4.5c0,2.9,0,5.8,0,8.7c0,3.2-1.4,4.6-4.6,4.6c-3,0-6,0-8.9,0c-2.8,0-4.1,1.4-4.1,4.2c0,3,0.1,6,0,8.9
                  c-0.1,2.7,1.5,4.6,4.6,4.6c20.1-0.1,40.2,0,60.3,0c0.6,0,1.2,0,1.8,0c2.7,0.1,4.2,1.5,4.2,4.2c0,3.2,0.1,6.3,0,9.5
                  c0,2.7-1.4,4-4.1,4c-2.8,0-5.6,0-8.3,0c-0.5,0-0.9,0-1.4,0c-2.4,0.2-3.8,1.6-3.8,3.9c0,9.2,0,18.4,0,27.6c0,2.5,1.4,3.8,4,3.9
                  c2.9,0,5.8,0,8.7,0c0.8,0,1.6,0.1,2.3,0.3c1.5,0.4,2.3,1.5,2.5,3c0.1,0.5,0.1,1.1,0.1,1.6c0,8.7,0,17.3,0,26c0,0.3,0,0.7,0,1
                  c-0.2,2.3-1.6,3.7-3.8,3.7c-3,0.1-6,0-8.9,0c-0.5,0-1.1,0-1.6,0c-1.8,0.2-3.1,1.6-3.3,3.4c0,0.3,0,0.7,0,1c0,8.9,0,17.7,0,26.6
                  c0,0.2,0,0.4,0,0.6c0.1,2.3,1.4,3.7,3.7,3.7c2.8,0.1,5.7,0,8.5,0c0.5,0,1.1,0,1.6,0c2.3,0.2,3.7,1.6,3.8,3.8
                  c0.1,3.4,0.1,6.7,0,10.1c-0.1,2.4-1.5,3.8-3.9,3.8c-1.3,0-2.5,0-3.8,0C381.9,440.1,366.8,440.1,351.6,440.1z M342.6,369.1
                  c7.4,0,14.8,0,22.2,0c3.1,0,4.4-1.3,4.5-4.5c0-0.1,0-0.1,0-0.2c0-14.6,0-29.2,0-43.8c0-3.3-1.4-4.7-4.7-4.7c-14.6,0-29.2,0-43.8,0
                  c-3.3,0-4.6,1.4-4.6,4.8c0,3,0,6.1,0,9.1c0,11.3,0,22.6,0,33.9c0,0.5,0,0.9,0,1.4c0.1,2.6,1.5,4,4.1,4
                  C327.7,369.1,335.1,369.1,342.6,369.1z"
               />
               <path
                  d="M165.4,156.3c12.9,0,25.8,0,38.7,0c0.5,0,1.1,0,1.6,0c2.4,0.1,3.9,1.4,4,3.8c0.1,3.4,0.1,6.7,0,10.1
                  c-0.1,2.4-1.7,3.8-4.2,3.9c-2.8,0.1-5.6,0-8.3,0c-0.5,0-1.1,0-1.6,0c-2.1,0.1-3.4,1.4-3.7,3.4c0,0.4,0,0.8,0,1.2
                  c0,8.7,0,17.3,0,26c0,0.3,0,0.5,0,0.8c0.1,2.4,1.6,3.9,4,4c3.2,0.1,6.3,0,9.5,0c2.6,0,4.1-1.5,4.2-4.2c0.1-2.8,0-5.7,0-8.5
                  c0-3.8,1.2-5,5-5c20.1,0,40.2,0,60.3,0c0.7,0,1.3,0,2,0c2.1-0.1,3.4-1.4,3.6-3.5c0.1-0.7,0-1.3,0-2c0-8.2,0-16.4,0-24.6
                  c0-0.7,0-1.3,0.1-2c0.3-2,1.6-3.3,3.6-3.3c3.5-0.1,7-0.1,10.5,0c2.2,0,3.6,1.6,3.6,3.9c0.1,3,0,6,0,8.9c0,0.7,0,1.3,0.2,2
                  c0.4,1.6,1.5,2.7,3.2,2.7c3.6,0.1,7.3,0.1,10.9,0c2.1-0.1,3.4-1.5,3.4-3.9c0.1-2.8,0-5.6,0-8.3c0-0.6,0-1.2,0-1.8
                  c0.1-2,1.5-3.5,3.5-3.6c3.6-0.1,7.1-0.1,10.7,0c1.7,0,3,1.2,3.4,2.9c0.2,0.7,0.2,1.4,0.2,2.2c0,8.3,0,16.5,0,24.8
                  c0,0.5,0,1.1,0,1.6c0.1,2.5,1.4,3.9,3.9,3.9c2.9,0,5.8,0,8.7,0c0.5,0,1.1,0,1.6,0c1.9,0.3,3.3,1.6,3.4,3.6c0.1,3.5,0.1,7,0,10.5
                  c-0.1,2.2-1.6,3.6-3.9,3.6c-2.9,0-5.8,0-8.7,0c-0.5,0-0.9,0-1.4,0c-2.1,0.2-3.6,1.6-3.6,3.7c-0.1,3,0,6.1,0,9.1
                  c0,3.6-1.4,4.9-4.9,4.9c-8.6,0-17.2-0.1-25.8,0c-2.8,0-5.1-1.4-4.9-5c0.1-2.8,0-5.6,0-8.3c0-3.1,1.4-4.5,4.5-4.5
                  c2.9,0,5.8,0,8.7,0c3.1,0,4.5-1.4,4.5-4.5c0-3,0-6,0-8.9c0-2.9-1.4-4.3-4.4-4.4c-2.9,0-5.8,0-8.7,0c-3.1,0-4.5,1.4-4.5,4.5
                  c0,2.9-0.1,5.8,0,8.7c0.1,3.1-2.1,4.6-4.6,4.6c-8.5-0.1-17.1,0-25.6,0c-0.5,0-0.9,0-1.4,0c-2.3,0.1-3.8,1.6-3.8,3.9
                  c0,2.8,0,5.7,0,8.5c0,6.1,0,12.2,0,18.3c0,3.5-1.3,4.8-4.8,4.8c-2.8,0-5.7,0-8.5,0c-3.1-0.1-4.4-1.4-4.5-4.5c0-8.5,0-17.1,0-25.6
                  c0-0.5,0-1.1,0-1.6c-0.1-2.1-1.5-3.7-3.6-3.7c-3.5-0.1-7-0.1-10.5,0c-1.9,0.1-3.3,1.5-3.5,3.4c-0.1,0.7-0.1,1.3-0.1,2
                  c0,8.2,0,16.4,0,24.6c0,0.6,0,1.2,0,1.8c-0.2,2.1-1.4,3.4-3.5,3.6c-0.4,0-0.8,0.1-1.2,0.1c-2.8,0-5.6,0-8.3,0
                  c-3.4,0-4.7-1.3-4.7-4.7c0-2.8,0-5.6,0-8.3c0-0.7,0-1.5-0.3-2.1c-0.5-1.5-1.6-2.5-3.2-2.5c-3.6-0.1-7.1-0.1-10.7,0
                  c-2,0-3.5,1.5-3.5,3.6c-0.1,3.4-0.1,6.9,0,10.3c0.1,2.2,1.6,3.6,3.8,3.7c3,0.1,6.1,0,9.1,0c3.4,0,4.8,1.4,4.8,4.8
                  c0,2.8,0,5.6,0,8.3c0,3.2-1.4,4.6-4.6,4.6c-14.7,0-29.5,0-44.2,0c-3.1,0-4.4-1.3-4.4-4.5c0-2.8,0-5.7,0-8.5c0-3.4-1.3-4.6-4.7-4.7
                  c-8.7,0-17.3,0-26,0c-3.5,0-4.7,1.3-4.7,4.8c0,2.8-0.1,5.7,0,8.5c0.1,2.9-2.1,4.4-4.5,4.4c-14.8-0.1-29.6-0.1-44.4,0
                  c-2.9,0-4.5-2.1-4.4-4.4c0.1-2.9,0-5.8,0-8.7c0-3.3,1.4-4.7,4.8-4.7c8.4,0,16.8,0,25.2,0c0.6,0,1.2,0,1.8,0
                  c2.2-0.1,3.5-1.4,3.7-3.6c0.1-1.1,0-2.1,0-3.2c0-1.9,0.1-3.8,0-5.8c-0.2-3.2,1-5.4,5.2-5.2c1.2,0.1,2.4,0,3.6,0
                  c6.9,0,13.9,0,20.8,0c0.5,0,1.1,0,1.6,0c2.9-0.1,4.2-1.5,4.3-4.3c0-2.6,0-5.3,0-7.9c0-0.5,0-1.1,0-1.6c0.1-2.4,1.5-3.8,3.9-3.8
                  c3.1,0,6.2,0,9.3,0c3.1,0,4.5-1.4,4.5-4.5c0-2.9,0-5.8,0-8.7c0-3.2,1.4-4.6,4.6-4.6c3,0,6,0,8.9,0c2.8,0,4.1-1.4,4.1-4.2
                  c0-3,0-6.1,0-9.1c0-3-1.3-4.3-4.4-4.3c-14,0-28,0-42.1,0c-0.7,0-1.3,0-2,0c-3.3,0-4.7-1.4-4.8-4.7c0-2.9,0-5.8,0-8.7
                  c0-2.9,1.4-4.3,4.4-4.3C138.7,156.3,152,156.3,165.4,156.3z"
               />
               <path
                  d="M156.4,413.5c0-12.8,0-25.5,0-38.3c0-0.5,0-1.1,0-1.6c0.1-3.1,1.4-4.5,4.5-4.5c3,0,6,0,8.9,0c2.9,0,4.2-1.3,4.2-4.3
                  c0-3.1,0-6.2,0-9.3c0-2.7-1.4-4-4-4c-2.9,0-5.8,0-8.7,0c-0.6,0-1.2,0-1.8-0.1c-1.9-0.4-2.9-1.6-3.1-3.6c0-0.6,0-1.2,0-1.8
                  c0-8.2,0-16.4,0-24.6c0-0.6,0-1.2,0-1.8c-0.2-2.1-1.6-3.5-3.8-3.7c-0.5,0-1.1,0-1.6,0c-8.5,0-16.9,0-25.4,0
                  c-3.5,0-4.8-1.3-4.8-4.8c0-2.8,0-5.7,0-8.5c0-2.9,1.5-4.3,4.4-4.4c0.5,0,0.9,0,1.4,0c25.7,0,51.4,0,77.2,0c0.2,0,0.4,0,0.6,0
                  c3.4-0.3,5.3,1.5,5.3,5.2c-0.1,8.4,0,16.8,0,25.2c0,0.4,0,0.8,0,1.2c-0.2,2.4-1.7,3.9-4.1,3.9c-3.1,0-6.2,0-9.3,0
                  c-3,0-4.4,1.4-4.4,4.4c0,3,0,6,0,8.9c0,2.9,1.3,4.3,4.2,4.3c3.1,0,6.2,0,9.3,0c2.8,0,4.1-1.4,4.2-4.1c0.1-3,0.1-6.1,0-9.1
                  c-0.1-2.7,1.9-4.4,4.4-4.4c8.9,0.1,17.9,0,26.8,0.1c2.9,0,4.3,1.3,4.3,4.2c0,3.1,0,6.2,0,9.3c0,2.7-1.5,4.1-4.2,4.2
                  c-3,0.1-6.1,0.1-9.1,0c-2.9-0.1-4.5,1.9-4.4,4.4c0.1,3,0.1,6,0,8.9c-0.1,2.8,1.9,4.4,4.4,4.4c14.4-0.1,28.8,0,43.2,0
                  c0.6,0,1.2,0,1.8,0c2.2,0.2,3.7,1.5,3.7,3.6c0.1,3.5,0.1,7,0,10.5c-0.1,2.4-1.7,3.6-4.3,3.7c-8.5,0-17.1,0.1-25.6,0
                  c-4.8-0.1-5.8,2.1-5.6,5.7c0.1,2.4,0,4.8,0,7.1c0,3.7,1.2,4.9,4.8,4.9c8.3,0,16.7,0,25,0c0.7,0,1.5,0,2.2,0.1
                  c2,0.2,3.3,1.5,3.4,3.5c0.1,3.6,0.1,7.1,0,10.7c-0.1,2.1-1.5,3.3-3.6,3.5c-0.7,0.1-1.3,0-2,0c-8.1,0-16.1,0-24.2,0
                  c-0.6,0-1.2,0-1.8,0c-2.4,0.2-3.8,1.5-3.8,4c-0.1,2.8,0,5.7,0,8.5c0,0.6,0,1.2,0,1.8c-0.2,1.9-1.6,3.4-3.5,3.4
                  c-3.6,0.1-7.1,0.1-10.7,0c-1.8,0-3.2-1.4-3.5-3.2c-0.1-0.7-0.1-1.4-0.1-2.2c0-13.8,0-27.6,0-41.5c0-0.5,0-1.1,0-1.6
                  c0-3.4-1.2-4.7-4.6-4.7c-2.6,0-5.3,0-7.9,0c-0.4,0-0.8,0-1.2,0c-2.4-0.1-3.8-1.5-3.9-3.9c-0.1-2.8,0-5.6,0-8.3c0-0.6,0-1.2,0-1.8
                  c-0.2-2.2-1.6-3.7-3.8-3.8c-3.3-0.1-6.6-0.1-9.9,0c-2.5,0.1-3.9,1.6-4,4c-0.1,3.1,0,6.2,0,9.3c0,3-1.4,4.4-4.4,4.4c-3,0-6,0-8.9,0
                  c-3,0-4.4,1.4-4.4,4.3c0,3,0,6.1,0,9.1c0,2.8,1.4,4.1,4.2,4.2c2.7,0.1,5.4,0,8.1,0c0.6,0,1.2,0,1.8,0c2.3,0.2,3.6,1.6,3.6,3.9
                  c0,3.1,0,6.2,0,9.3c0,5.9-0.1,11.8,0,17.7c0.1,2.9-1.8,4.8-4.6,4.6c-2.9-0.2-5.8-0.1-8.7,0c-2.9,0-4.4,1.5-4.4,4.4c0,3,0,6,0,8.9
                  c0,3.1-1.4,4.4-4.5,4.5c-2.8,0-5.7,0-8.5,0c-3.3,0-4.7-1.3-4.7-4.7C156.4,439.9,156.4,426.7,156.4,413.5z"
               />
               <path
                  d="M14.5,395.7c0-19,0-38,0-56.9c0-0.6,0-1.2,0-1.8c0.2-1.9,1.6-3.2,3.5-3.3c0.5,0,0.9,0,1.4,0c38.1,0,76.2,0,114.3,0
                  c0.5,0,0.9,0,1.4,0c2,0.1,3.4,1.5,3.6,3.5c0,0.5,0,1.1,0,1.6c0,38,0,75.9,0,113.9c0,0.5,0,0.9,0,1.4c-0.1,2.2-1.5,3.7-3.8,3.8
                  c-0.5,0-0.9,0-1.4,0c-38,0-75.9,0-113.9,0c-0.5,0-1.1,0-1.6,0c-2.1-0.2-3.5-1.6-3.6-3.8c0-0.6,0-1.2,0-1.8
                  C14.5,433.4,14.5,414.6,14.5,395.7z M32.3,395.6c0,13.6,0,27.1,0,40.7c0,0.6,0,1.2,0,1.8c0.1,1.1,0.8,1.8,1.9,2
                  c0.5,0.1,1.1,0,1.6,0c27.2,0,54.3,0,81.5,0c0.5,0,0.9,0,1.4,0c1.3-0.1,2-0.8,2.2-2.1c0-0.5,0-0.9,0-1.4c0-27.2,0-54.3,0-81.5
                  c0-0.5,0-0.9,0-1.4c-0.1-1.4-0.7-2-2.1-2.1c-0.5,0-0.9,0-1.4,0c-27.2,0-54.3,0-81.5,0c-0.3,0-0.7,0-1,0c-1.9,0.1-2.5,0.6-2.6,2.5
                  c0,0.5,0,0.9,0,1.4C32.3,368.8,32.3,382.2,32.3,395.6z"
               />
               <path
                  d="M76.5,138.5c-18.9,0-37.8,0-56.7,0c-0.6,0-1.2,0-1.8,0c-1.9-0.1-3.3-1.5-3.5-3.4c0-0.5,0-1.1,0-1.6c0-38,0-76,0-114.1
                  c0-0.5,0-0.9,0-1.4c0.1-2,1.4-3.5,3.5-3.7c0.6-0.1,1.2,0,1.8,0c37.9,0,75.8,0,113.7,0c0.6,0,1.2,0,1.8,0c2,0.2,3.4,1.6,3.5,3.7
                  c0,0.5,0,0.9,0,1.4c0,38,0,76,0,114.1c0,0.6,0,1.2,0,1.8c-0.2,1.8-1.6,3.1-3.4,3.2c-0.6,0-1.2,0-1.8,0
                  C114.5,138.5,95.5,138.5,76.5,138.5z M32.3,76.5c0,13.4,0.1,26.8,0,40.3c0,4.1,1,3.9,4,3.9c26.9,0,53.8,0,80.7,0
                  c0.5,0,1.1,0,1.6,0c1.6-0.1,2.2-0.7,2.3-2.3c0-0.5,0-1.1,0-1.6c0-26.9,0-53.8,0-80.7c0-0.5,0-0.9,0-1.4c-0.1-1.8-0.8-2.5-2.5-2.6
                  c-0.4,0-0.8,0-1.2,0c-27,0-54.1,0-81.1,0c-0.5,0-1.1,0-1.6,0c-1.3,0.1-2,0.9-2.1,2.2c0,0.5,0,1.1,0,1.6
                  C32.3,49.4,32.3,63,32.3,76.5z"
               />
               <path
                  d="M395.9,138.5c-19,0-38,0-56.9,0c-0.6,0-1.2,0-1.8,0c-1.7-0.1-3-1.3-3.2-3c-0.1-0.7-0.1-1.4-0.1-2.2c0-37.9,0-75.8,0-113.7
                  c0-0.3,0-0.7,0-1c0.1-2.9,1.4-4.3,4.3-4.3c0.4,0,0.8,0,1.2,0c37.8,0,75.5,0,113.3,0c4,0,5.5,1.6,5.5,5.4c-0.1,37.8,0,75.7,0,113.5
                  c0,4.4-1,5.3-5.4,5.3C433.7,138.5,414.8,138.5,395.9,138.5z M395.7,120.7c13.5,0,27,0,40.5,0c3.7,0,4-0.3,4-3.9
                  c0-26.9,0-53.8,0-80.7c0-0.4,0-0.8,0-1.2c-0.1-2-0.8-2.7-2.8-2.8c-0.4,0-0.8,0-1.2,0c-26.9,0-53.8,0-80.7,0c-0.5,0-0.9,0-1.4,0
                  c-1.8,0.1-2.5,0.8-2.6,2.6c0,0.4,0,0.8,0,1.2c0,27,0,54.1,0,81.1c0,0.6,0,1.2,0.1,1.8c0.2,1.2,0.9,1.8,2.1,1.9c0.6,0,1.2,0,1.8,0
                  C368.9,120.7,382.3,120.7,395.7,120.7z"
               />
               <path
                  d="M236.3,32.1c7.3,0,14.6,0,21.8,0c3.4,0,4.7-1.3,4.7-4.7c0-2.8,0-5.7,0-8.5c0-3.2,1.4-4.6,4.6-4.6c2.8,0,5.7,0,8.5,0
                  c3.2,0,4.6,1.4,4.6,4.6c0,8.8,0,17.6,0,26.4c0,3.2-1.4,4.6-4.6,4.6c-14.4,0-28.7,0-43.1,0c-0.7,0-1.3,0-2,0
                  c-1.9,0.1-3.2,1.3-3.5,3.2c-0.1,0.6-0.1,1.3-0.1,2c0,8.2,0,16.4,0,24.6c0,0.6,0,1.2,0,1.8c-0.1,2.4-1.5,3.8-3.9,3.8
                  c-3.3,0-6.6,0-9.9,0c-2.4,0-3.8-1.4-3.9-3.9c-0.1-2.8,0-5.7,0-8.5c0-0.6,0-1.2,0-1.8c-0.3-2.1-1.6-3.4-3.7-3.4
                  c-3.4-0.1-6.7-0.1-10.1,0c-2.5,0-3.8,1.5-3.9,4c0,3-0.1,6.1,0,9.1c0.1,2.8-1.8,4.5-4.5,4.5c-8.9-0.1-17.7,0-26.6-0.1
                  c-3,0-4.4-1.3-4.4-4.3c0-3,0-6.1,0-9.1c0-2.8,1.5-4.3,4.3-4.3c3,0,6.1,0,9.1,0c2.9,0,4.2-1.3,4.2-4.3c0-3.1,0-6.2,0-9.3
                  c0-2.6-1.4-4-4.1-4c-3,0-6,0-8.9,0c-3.3,0-4.6-1.3-4.6-4.5c0-2.9,0.1-5.8,0-8.7c-0.1-3,2-4.7,4.7-4.5c2.9,0.2,5.8,0.1,8.7,0
                  c2.9,0,4.3-1.4,4.3-4.3c0-2.9,0-5.8,0-8.7c0-3.4,1.4-4.8,4.9-4.8c2.8,0,5.6,0,8.3,0c3.2,0,4.6,1.4,4.6,4.6c0,8.5,0,17.1,0,25.6
                  c0,0.5,0,1.1,0,1.6c0.1,2.1,1.4,3.6,3.4,3.7c3.6,0.2,7.1,0.2,10.7,0c2.2-0.1,3.5-1.6,3.6-4c0.1-2.8,0-5.6,0-8.3c0-0.6,0-1.2,0-1.8
                  c0.2-2,1.6-3.4,3.6-3.5c0.5,0,0.9,0,1.4,0C221.8,32.1,229,32.1,236.3,32.1z"
               />
               <path
                  d="M458,307.1c0,13-0.1,25.9,0,38.9c0,4.3-1.9,5.5-5.6,5.5c-14.1-0.1-28.2,0-42.2,0c-0.7,0-1.3,0-2-0.1
                  c-2-0.3-3.4-1.7-3.5-3.7c-0.1-3.4-0.1-6.9,0-10.3c0.1-2.4,1.7-3.7,4.3-3.7c8.5,0,17.1-0.1,25.6,0c4.3,0.1,5.8-1.6,5.7-5.8
                  c-0.2-8.1,0-16.3,0-24.4c0-0.5,0-1.1,0-1.6c-0.2-2.3-1.5-3.6-3.8-3.7c-2.8,0-5.6,0-8.3,0c-0.5,0-1.1,0-1.6,0
                  c-2.6-0.2-3.9-1.7-4-4.3c0-3,0-5.9,0-8.9c0-3.3-1.3-4.6-4.6-4.6c-2.9,0-5.8,0-8.7,0c-3,0-4.5-1.5-4.5-4.5c0-2.9,0.1-5.8,0-8.7
                  c-0.1-2.8,1.9-4.6,4.7-4.6c14.7,0.1,29.4,0,44,0c3.2,0,4.6,1.3,4.6,4.6C458,280.5,458,293.8,458,307.1z"
               />
               <path
                  d="M262.7,103.1c4.4,0,8.7,0,13.1,0c3.3,0,4.7-1.3,4.7-4.7c0-8.5,0-16.9,0-25.4c0-0.6,0-1.2,0-1.8c0.2-2,1.4-3.3,3.3-3.6
                  c0.4-0.1,0.8-0.1,1.2-0.1c8.8,0,17.6,0,26.4,0c0.2,0,0.4,0,0.6,0c2.5,0.2,4,1.6,4,4.2c0,1.7,0,3.4,0,5.2c0,7.1-0.1,14.3,0,21.4
                  c0.1,3-1.6,5-4.9,4.8c-2.9-0.1-5.8-0.1-8.7,0c-2.7,0-4.1,1.4-4.2,4.2c0,3,0,6,0,8.9c0,3.3-1.5,4.7-4.7,4.7c-2.9,0-5.8,0.1-8.7,0
                  c-2.6-0.1-4.4,1.7-4.3,4.3c0.1,2.7,0,5.4,0,8.1c0,0.5,0,1.1,0,1.6c-0.2,2.3-1.6,3.6-3.9,3.6c-3.2,0-6.4-0.1-9.5,0.1
                  c-2.3,0.1-4.5-1.5-4.4-4.4c0.1-2.8,0-5.6,0-8.3c0-0.5,0-1.1-0.1-1.6c-0.3-2-1.6-3.2-3.6-3.3c-3-0.1-6.1,0-9.1,0
                  c-0.7,0-1.5-0.1-2.1-0.3c-1.7-0.5-2.7-1.7-2.8-3.4c-0.1-3.5-0.1-7,0-10.5c0-2,1.8-3.6,3.9-3.6
                  C253.6,103.2,258.2,103.1,262.7,103.1z"
               />
               <path
                  d="M76.5,315.9c-7.3,0-14.7-0.1-22,0c-3.1,0.1-4.6-1.9-4.6-4.6c0.1-14.7,0-29.4,0-44c0-3.2,1.4-4.6,4.6-4.6c3,0,6,0,8.9,0
                  c2.7,0,4.2,1.4,4.2,4.1c0,3,0,6,0,8.9c0,3.3,1.4,4.7,4.7,4.7c8.5,0,17.1,0,25.6,0c0.6,0,1.2,0,1.8,0c1.6,0.1,2.8,1.1,3.2,2.7
                  c0.2,0.7,0.2,1.4,0.2,2.2c0,8.5,0,17.1,0,25.6c0,0.5,0,1.1-0.1,1.6c-0.3,2-1.6,3.2-3.6,3.3c-1.4,0.1-2.8,0-4.2,0
                  C89.1,315.9,82.8,315.9,76.5,315.9z"
               />
               <path
                  d="M58.7,156.3c13.2,0,26.5,0,39.7,0c0.8,0,1.6,0,2.3,0.2c1.6,0.6,2.4,1.8,2.5,3.5c0.1,3.4,0.1,6.7,0,10.1
                  c-0.1,2.5-1.7,3.9-4.3,3.9c-3,0-6,0-8.9,0c-3.2,0-4.4,1.3-4.5,4.5c0,2.9,0,5.8,0,8.7c0,3.1-1.4,4.4-4.5,4.4c-3,0-6,0-8.9,0
                  c-3,0-4.3-1.4-4.4-4.4c0-2.8,0-5.7,0-8.5c0-3.5-1.2-4.7-4.8-4.7c-14.4,0-28.7,0-43.1,0c-0.7,0-1.5,0-2.2-0.1
                  c-2-0.4-3.1-1.7-3.2-3.8c-0.1-3.4-0.1-6.7,0-10.1c0-2.3,1.5-3.7,3.9-3.8c3.5,0,7,0,10.5,0C38.9,156.3,48.8,156.3,58.7,156.3z"
               />
               <path
                  d="M32.3,271.6c0,13,0,26,0,39.1c0,0.6,0,1.2,0,1.8c-0.3,2-1.7,3.3-3.8,3.4c-3.4,0.1-6.9,0.1-10.3,0c-2.1,0-3.6-1.5-3.7-3.6
                  c0-0.5,0-0.9,0-1.4c0-26.2,0-52.3,0-78.5c0-0.6,0-1.2,0-1.8c0.2-1.7,1.5-3.2,3.2-3.2c3.8-0.1,7.5-0.1,11.3,0
                  c1.8,0.1,3,1.6,3.2,3.5c0.1,0.6,0,1.2,0,1.8C32.3,245.6,32.3,258.6,32.3,271.6z"
               />
               <path
                  d="M227.3,147.4c0-7,0-14,0-21c0-0.5,0-1.1,0-1.6c0.1-2.4,1.6-4,4-4c3.2-0.1,6.5-0.1,9.7,0c2.8,0.1,4.1,2.1,4.1,4.3
                  c-0.1,8.5,0,16.9,0,25.4c0,0.6,0,1.2,0,1.8c0.1,2.5,1.6,4,4.1,4.1c3,0,6.1,0,9.1,0c3.1,0,4.5,1.4,4.5,4.4c0,2.9,0,5.8,0,8.7
                  c0,3-1.5,4.6-4.4,4.6c-8.9,0-17.8,0-26.8,0c-2.9,0-4.4-1.6-4.4-4.5C227.3,162.2,227.3,154.8,227.3,147.4z"
               />
               <path
                  d="M431.2,457.9c-7.2,0-14.4,0-21.6,0c-3.6,0-4.9-1.4-4.9-4.9c0-2.8,0-5.6,0-8.3c0-3.1,1.5-4.5,4.5-4.5c3,0,6,0,8.9,0
                  c2.9,0,4.3-1.5,4.4-4.4c0-3,0-6,0-8.9c0-2.9,1.4-4.4,4.4-4.4c3,0,6.1,0,9.1,0c2.8,0,4.3,1.5,4.3,4.3c0,2.8,0,5.7,0,8.5
                  c0,3.7,1.2,4.9,4.8,4.9c2.8,0,5.7,0,8.5,0c2.9,0,4.3,1.4,4.3,4.2c0.1,3.1,0.1,6.2,0,9.3c0,2.8-1.5,4.2-4.4,4.2
                  C446.1,457.9,438.7,457.9,431.2,457.9z"
               />
               <path
                  d="M351.5,174c0-4.4,0-8.7,0-13.1c0-3.2,1.4-4.6,4.6-4.6c3,0,6.1,0,9.1,0c2.6,0,4.1,1.4,4.1,4c0,3,0,6.1,0,9.1
                  c0,3.2,1.3,4.4,4.5,4.5c2.8,0,5.7,0,8.5,0c3.4,0,4.8,1.5,4.8,4.9c0,8.7,0,17.3,0,26c0,3.3-1.4,4.7-4.7,4.7c-3,0-6,0-8.9,0
                  c-2.7,0-4.2-1.4-4.2-4.2c0-3,0-5.9,0-8.9c0-3.3-1.4-4.7-4.7-4.7c-2.7,0-5.4,0-8.1,0c-3.7,0-4.9-1.3-4.9-5
                  C351.4,182.4,351.5,178.2,351.5,174z"
               />
               <path
                  d="M191.9,111.9c0-7.3,0.1-14.7,0-22c-0.1-2.9,1.8-4.9,4.8-4.7c2.7,0.2,5.4,0,8.1,0c3.6,0,4.9,1.4,4.9,4.9
                  c0,10.8,0,21.7,0,32.5c0,3.8,0.1,7.5,0,11.3c0,1-0.3,2.1-0.8,3c-0.7,1.2-1.9,1.5-3.2,1.5c-3.1,0-6.2,0-9.3,0c-3,0-4.4-1.3-4.4-4.3
                  c0-6.9,0-13.9,0-20.8C191.9,112.9,191.9,112.4,191.9,111.9z"
               />
               <path
                  d="M440.2,386.9c4.4,0,8.9,0,13.3,0c3.1,0,4.5,1.3,4.5,4.5c0,8.9,0,17.7,0,26.6c0,3-1.3,4.4-4.3,4.4c-3,0-6.1,0-9.1,0
                  c-3,0-4.3-1.4-4.3-4.4c0-2.7,0-5.4,0-8.1c0-0.4,0-0.8,0-1.2c0-2.2-1.6-4.1-4.2-4c-2.7,0.1-5.4,0-8.1,0c-0.5,0-0.9,0-1.4,0
                  c-2.6-0.1-4-1.6-4-4.2c0-3.2,0-6.3,0-9.5c0-2.6,1.5-4,4.1-4.1C431.1,386.9,435.6,386.9,440.2,386.9z"
               />
               <path
                  d="M245.3,280.5c4.4,0,8.9,0,13.3,0c2.9,0,4.3,1.4,4.3,4.4c0,3,0,5.9,0,8.9c0,2.9-1.5,4.4-4.4,4.5c-3,0.1-6.1,0-9.1,0
                  c-2.9,0-4.2,1.3-4.2,4.3c0,3,0,6.1,0,9.1c0,2.9-1.4,4.2-4.3,4.2c-3.1,0-6.2,0-9.3,0c-2.7,0-4.2-1.4-4.2-4.2c0-9,0-18,0-27
                  c0-2.4,1.6-4.4,4.3-4.3C236.1,280.6,240.7,280.5,245.3,280.5z"
               />
               <path
                  d="M32.2,209.4c0-4.4,0-8.7,0-13.1c0-3.2,1.4-4.6,4.6-4.6c8.8,0,17.6,0,26.4,0c3.1,0,4.5,1.4,4.5,4.4c0,3,0,6.1,0,9.1
                  c0,2.8-1.5,4.2-4.3,4.2c-3,0-5.9,0-8.9,0c-3.1,0-4.4,1.4-4.5,4.5c0,2.9,0,5.8,0,8.7c0,3.2-1.4,4.5-4.6,4.6c-2.8,0-5.7,0-8.5,0
                  c-3.2,0-4.6-1.4-4.6-4.6C32.2,218.2,32.2,213.8,32.2,209.4z"
               />
               <path
                  d="M85.5,209.5c4.4,0,8.9,0,13.3,0c3,0,4.4,1.4,4.5,4.3c0,3,0,6.1,0,9.1c0,2.8-1.5,4.3-4.3,4.3c-9,0-18,0-27,0
                  c-2.8,0-4.2-1.5-4.3-4.4c0-3,0.1-6.1,0-9.1c-0.1-2.4,1.8-4.4,4.3-4.3C76.5,209.6,81,209.5,85.5,209.5z"
               />
               <path
                  d="M262.8,262.6c0-4.3,0-8.6,0-12.9c0-3.5,1.3-4.8,4.8-4.8c2.8,0,5.7,0,8.5,0c3.1,0,4.5,1.4,4.5,4.5c0,8.8,0,17.7,0,26.5
                  c0,3.1-1.4,4.5-4.5,4.5c-3,0-5.9,0-8.9,0c-3,0-4.4-1.4-4.4-4.4C262.8,271.6,262.8,267.1,262.8,262.6z"
               />
               <path
                  d="M138.8,262.7c4.4,0,8.7,0.1,13.1,0c2.5-0.1,4.8,1.5,4.7,4.6c-0.1,2.9,0,5.8,0,8.7c0,3-1.5,4.5-4.5,4.5
                  c-8.8,0-17.7,0-26.5,0c-3,0-4.5-1.5-4.5-4.5c0-3,0.1-5.9,0-8.9c-0.1-2.6,1.9-4.5,4.4-4.4C129.8,262.8,134.3,262.7,138.8,262.7z"
               />
               <path
                  d="M316.1,138.5c0,4.4-0.1,8.7,0,13.1c0.1,2.6-1.4,4.9-4.7,4.7c-2.9-0.2-5.8,0-8.7,0c-2.9,0-4.4-1.5-4.4-4.4
                  c0-8.9,0-17.8,0-26.7c0-2.9,1.5-4.4,4.4-4.4c3,0,5.9,0,8.9,0c3,0,4.5,1.5,4.5,4.6C316.1,129.7,316.1,134.1,316.1,138.5z"
               />
               <path
                  d="M440.4,227.2c4.4,0,8.7,0,13.1,0c1,0,2,0.2,2.8,0.7c1.1,0.6,1.6,1.7,1.7,3c0,3.4,0.1,6.9,0,10.3c-0.1,2.2-1.4,3.5-3.6,3.7
                  c-0.5,0.1-1.1,0-1.6,0c-8.4,0-16.8,0-25.2,0c-0.4,0-0.8,0-1.2,0c-2.5-0.2-3.9-1.5-3.9-4.1c0-3.1,0-6.2,0-9.3c0-3,1.4-4.3,4.4-4.4
                  C431.4,227.2,435.9,227.2,440.4,227.2z"
               />
               <path
                  d="M156.4,120.6c0-4.2,0-8.3,0-12.5c0-0.5,0-0.9,0-1.4c0.2-2.2,1.5-3.6,3.7-3.6c3.4-0.1,6.9-0.1,10.3,0
                  c2.2,0,3.5,1.4,3.7,3.6c0,0.5,0,1.1,0,1.6c0,8.3,0,16.7,0,25c0,0.6,0,1.2,0,1.8c-0.2,2-1.5,3.4-3.5,3.4c-3.6,0.1-7.1,0.1-10.7,0
                  c-1.9,0-3.2-1.3-3.4-3.2c-0.1-0.6-0.1-1.2-0.1-1.8C156.4,129.2,156.4,124.9,156.4,120.6z"
               />
               <path
                  d="M129.8,209.5c-1.6,0-3.3,0-4.9,0c-2.3-0.1-3.9-1.5-3.9-3.9c-0.1-3.4-0.1-6.7,0-10.1c0.1-2.2,1.6-3.7,3.8-3.8
                  c3.4-0.1,6.9-0.1,10.3,0c2.2,0.1,3.6,1.6,3.7,3.9c0.1,3.3,0.1,6.6,0,9.9c0,2.5-1.5,3.9-4,4C133.1,209.5,131.4,209.5,129.8,209.5z"
               />
               <path
                  d="M413.5,209.5c-1.6,0-3.2,0-4.8,0c-2.4-0.1-4-1.5-4.1-3.9c-0.1-3.3-0.1-6.6,0-9.9c0.1-2.4,1.6-3.9,4-4
                  c3.2-0.1,6.5-0.1,9.7,0c2.6,0,4,1.5,4,4.1c0,3.2,0,6.3,0,9.5c0,2.7-1.5,4.1-4.2,4.2c-0.1,0-0.1,0-0.2,0
                  C416.6,209.5,415.1,209.5,413.5,209.5z"
               />
               <path
                  d="M236.3,85.3c1.6,0,3.2,0,4.8,0c2.5,0.1,4,1.5,4,3.9c0.1,3.3,0.1,6.6,0,9.9c0,2.4-1.5,3.9-4,3.9c-3.2,0.1-6.5,0.1-9.7,0
                  c-2.4,0-4-1.5-4.1-3.9c-0.1-3.3-0.1-6.6,0-9.9c0.1-2.4,1.6-3.9,4.1-3.9C233,85.3,234.7,85.3,236.3,85.3z"
               />
               <path
                  d="M316.1,23.3c0,1.6,0,3.2,0,4.8c-0.1,2.6-1.5,4-4.1,4c-3.2,0-6.5,0-9.7,0c-2.4,0-3.9-1.5-4-4c-0.1-3.2-0.1-6.5,0-9.7
                  c0-2.5,1.5-4,4.1-4.1c3.2-0.1,6.3-0.1,9.5,0c2.7,0,4.1,1.5,4.2,4.2C316.1,20.1,316.1,21.7,316.1,23.3z"
               />
               <path
                  d="M245.1,253.9c0,1.6,0,3.3,0,4.9c-0.1,2.4-1.5,3.8-3.9,3.8c-3.3,0-6.6,0-9.9,0c-2.4,0-3.9-1.4-4-3.7
                  c-0.1-3.4-0.1-6.7,0-10.1c0.1-2.3,1.6-3.8,3.9-3.9c3.3-0.1,6.6-0.1,9.9,0c2.5,0.1,3.9,1.6,4,4c0,0.1,0,0.3,0,0.4
                  C245.1,250.8,245.1,252.4,245.1,253.9C245.1,253.9,245.1,253.9,245.1,253.9z"
               />
               <path
                  d="M191.9,395.6c0-1.6,0-3.2,0-4.8c0.1-2.5,1.5-3.9,4-3.9c3.3,0,6.6,0,9.9,0c2.2,0,3.8,1.4,3.9,3.7c0.1,3.4,0.1,6.9,0,10.3
                  c-0.1,2.2-1.6,3.7-3.8,3.8c-3.4,0.1-6.7,0.1-10.1,0c-2.4-0.1-3.8-1.6-3.9-4.1C191.9,398.9,191.9,397.2,191.9,395.6z"
               />
               <path
                  d="M431.4,156.3c1.6,0,3.3,0,4.9,0c2.3,0.1,3.9,1.5,3.9,3.8c0.1,3.4,0.1,6.7,0,10.1c-0.1,2.2-1.5,3.7-3.8,3.8
                  c-3.4,0.1-6.9,0.1-10.3,0c-2.3-0.1-3.7-1.7-3.7-4.1c0-3.2,0-6.5,0-9.7c0-2.5,1.5-3.9,3.9-4C428.1,156.3,429.7,156.3,431.4,156.3z"
               />
               <path
                  d="M254,85.3c-1.6,0-3.3,0-4.9,0c-2.5,0-3.9-1.5-4-3.9c-0.1-3.3-0.1-6.6,0-9.9c0-2.2,1.4-3.8,3.7-3.9c3.4-0.1,6.9-0.1,10.3,0
                  c2.3,0.1,3.8,1.6,3.8,4c0.1,3.3,0.1,6.6,0,9.9c-0.1,2.4-1.6,3.8-3.9,3.8c-0.1,0-0.1,0-0.2,0C257.2,85.3,255.6,85.3,254,85.3z"
               />
               <path
                  d="M440.2,182.7c0-1.6,0-3.2,0-4.7c0.1-2.3,1.3-3.8,3.6-3.9c3.5-0.2,7-0.2,10.5,0c2.3,0.1,3.6,1.6,3.7,3.9
                  c0.1,3.3,0.1,6.6,0,9.9c-0.1,2.5-1.4,3.9-4,3.9c-3.3,0-6.6,0.1-9.9,0c-2.4,0-3.8-1.5-3.9-3.8C440.2,186.1,440.2,184.4,440.2,182.7z"
               />
               <path
                  d="M289.6,440.1c1.6,0,3.2,0,4.8,0c2.5,0.1,3.9,1.5,4,4c0.1,3.3,0.1,6.6,0,9.9c-0.1,2.4-1.5,3.8-3.9,3.9
                  c-3.4,0.1-6.7,0.1-10.1,0c-2.2-0.1-3.7-1.5-3.8-3.8c-0.1-3.4-0.1-6.9,0-10.3c0.1-2.2,1.6-3.6,3.9-3.7
                  C286.1,440,287.9,440.1,289.6,440.1z"
               />
               <path
                  d="M298.3,236.2c0,1.5,0,3,0,4.6c-0.1,2.6-1.4,4.1-4,4.2c-3.3,0.1-6.6,0.1-9.9,0c-2.5-0.1-3.9-1.5-3.9-4
                  c-0.1-3.2-0.1-6.5,0-9.7c0.1-2.5,1.4-3.9,4-4c3.3-0.1,6.6-0.1,9.9,0c2.5,0.1,3.8,1.5,3.9,4C298.4,232.9,298.3,234.6,298.3,236.2z"
               />
               <path
                  d="M422.5,413.6c0,1.6,0,3.2,0,4.8c-0.1,2.4-1.4,3.9-3.7,4c-3.4,0.1-6.9,0.1-10.3,0c-2.2-0.1-3.7-1.5-3.7-3.8
                  c-0.1-3.4-0.1-6.7,0-10.1c0.1-2.2,1.5-3.7,3.8-3.8c3.4-0.1,6.9-0.1,10.3,0c2.4,0.1,3.6,1.6,3.7,4c0,0.1,0,0.3,0,0.4
                  C422.5,410.5,422.5,412.1,422.5,413.6z"
               />
               <path
                  d="M67.7,236.2c0,1.6,0,3.3,0,4.9c-0.1,2.3-1.4,3.7-3.7,3.8c-3.4,0.1-6.9,0.1-10.3,0c-2.3-0.1-3.7-1.5-3.8-3.8
                  c-0.1-3.4-0.1-6.7,0-10.1c0.1-2.4,1.5-3.8,3.9-3.8c3.3-0.1,6.6-0.1,9.9,0c2.5,0.1,3.9,1.5,4,4C67.8,232.9,67.7,234.5,67.7,236.2
                  C67.7,236.2,67.7,236.2,67.7,236.2z"
               />
               <path
                  d="M395.9,156.3c1.6,0,3.2,0,4.8,0c2.6,0,4,1.4,4.1,4c0.1,3.2,0.1,6.5,0,9.7c0,2.4-1.4,3.9-3.8,4c-3.4,0.1-6.7,0.1-10.1,0
                  c-2.4-0.1-3.7-1.6-3.8-4c-0.1-3.3-0.1-6.6,0-9.9c0.1-2.4,1.5-3.8,3.9-3.8C392.6,156.3,394.2,156.3,395.9,156.3z"
               />
               <path
                  d="M342.8,333.7c1.7,0,3.3,0,5,0c2.3,0.1,3.7,1.4,3.8,3.7c0.1,3.4,0.1,6.7,0,10.1c0,2.3-1.4,3.8-3.7,3.9
                  c-3.4,0.1-6.9,0.1-10.3,0c-2.3-0.1-3.6-1.6-3.7-3.9c-0.1-3.3-0.1-6.6,0-9.9c0.1-2.5,1.5-3.9,4-3.9c0.1,0,0.1,0,0.2,0
                  C339.6,333.7,341.2,333.7,342.8,333.7z"
               />
               <path
                  d="M76.4,422.4c-7,0-14,0-21,0c-0.7,0-1.3,0-2-0.1c-1.8-0.2-3.1-1.3-3.4-3.1c-0.1-0.6-0.1-1.3-0.1-2c0-14.4,0-28.7,0-43.1
                  c0-0.5,0-1.1,0-1.6c0.3-2,1.7-3.3,3.7-3.5c0.4,0,0.8,0,1.2,0c14.5,0,29,0,43.4,0c0.5,0,0.9,0,1.4,0c1.8,0.2,3.2,1.4,3.5,3.3
                  c0.1,0.7,0.1,1.4,0.1,2.2c0,14.2,0,28.3,0,42.5c0,0.5,0,0.9,0,1.4c-0.2,2.5-1.5,3.9-4,4c-3.4,0.1-6.9,0-10.3,0
                  C84.7,422.4,80.6,422.4,76.4,422.4z"
               />
               <path
                  d="M76.6,49.8c7.1,0,14.2,0,21.2,0c0.6,0,1.2,0,1.8,0c1.9,0.2,3.2,1.3,3.5,3.2c0.1,0.6,0.1,1.3,0.1,2c0,14.3,0,28.6,0,42.9
                  c0,0.1,0,0.3,0,0.4c0.2,3.4-2.1,4.9-4.8,4.9c-14-0.1-28,0-42.1,0c-0.8,0-1.6,0-2.4,0c-2.5-0.1-3.9-1.5-4.1-3.9c0-0.5,0-0.9,0-1.4
                  c0-14.2,0-28.4,0-42.7c0-0.4,0-0.8,0-1.2c0.1-2.6,1.5-4,4.1-4.1c2.6-0.1,5.2,0,7.7,0C66.7,49.8,71.7,49.8,76.6,49.8z"
               />
               <path
                  d="M369.3,76.5c0-7.1,0-14.3,0-21.4c0-0.6,0-1.2,0-1.8c0.2-1.9,1.4-3.2,3.3-3.4c0.7-0.1,1.3-0.1,2-0.1c14.2,0,28.4,0,42.7,0
                  c0.3,0,0.7,0,1,0c2.9,0.1,4.3,1.4,4.3,4.2c0,14.9,0,29.8,0,44.6c0,3-1.4,4.4-4.4,4.4c-14.8,0-29.6,0-44.4,0c-3,0-4.4-1.4-4.4-4.4
                  C369.2,91.3,369.3,83.9,369.3,76.5z"
               />
            </g>
         </g>
         <rect fill="none" width="478.2" height="478.2" />
      </svg>
   )
}