import io from "socket.io-client";
import { eventChannel } from "redux-saga";
import { take, call, put } from "redux-saga/effects";
import {AuthTypeEnum} from '../../../../models/models';

// API
import { IEvent } from "./../../../API/model";
import { Config, SocketEventEnum } from "./../../../API/config";

// Actions
import {
  eventsSocketNewItemAction, eventsSocketUpdateItemAction
} from "./../actions";
import { setUserDataAction } from "./../../User/actions";

export function initWebSocket(token: string) {
  return eventChannel((emitter) => {
    const socket = io(Config.WS_ENDPOINT_EVENT, {
      query: { [AuthTypeEnum.access]: token },
      transports: ["websocket"],
      forceNew: true,
    });

    socket.on(SocketEventEnum.NewEvent, (payload: IEvent) => {
      emitter(eventsSocketNewItemAction({ event: payload }));
    });

    socket.on(SocketEventEnum.UpdateEvent, (payload: IEvent) => {
      emitter(eventsSocketUpdateItemAction({ event: payload }));
    });

    return () => {};
  });
}

export function* listenEventSocketSaga() {
  while (true) {
    const { payload }: ReturnType<typeof setUserDataAction> = yield take(
      setUserDataAction
    );
    // @ts-ignore
    const chanel = yield call(initWebSocket, payload.authData.accessToken);

    while (true) {
      // @ts-ignore
      const action = yield take(chanel);
      yield put(action);
    }
  }
}
