import React from 'react';
// Icons
import { UserDoctorIcon, UserSecondaryIcon } from '../assets/img/icons/User';

interface IUserImageProps {
  isPatient?: boolean;
  uri?: string;
  size?: number;
  className?: string;
}

export const UserImage: React.FC<IUserImageProps> = props => {
  const { isPatient, uri, className } = props;

  if (uri) {
    return <img src={ uri } className={className}/>;
  }

  if (isPatient) {
    return <UserSecondaryIcon className={className}/>;
  } else {
    return <UserDoctorIcon className={className}/>;
  }
};
