import { combineReducers } from "redux";
import { all } from "redux-saga/effects";

// Controllers
import { eventsReducer, eventsSaga } from "./Events";
import { userReducer, userSaga } from "./User";
import { chatReducer, chatSaga } from "./Chat";

// Export controllers outside
export * from "./Events";
export * from "./User";
export * from "./Chat";
export * from "./model";

export const rootSaga = function* () {
  yield all([userSaga(), eventsSaga(), chatSaga()]);
};

export const rootReducer = combineReducers({
  events: eventsReducer,
  user: userReducer,
  chat: chatReducer,
});
