import React from 'react';

export interface IMessagesIconProps {
  style?: React.CSSProperties;
  fillColor?: string;
  className?: string
}

export const MessagesIcon: React.FC<IMessagesIconProps> = props => {
  const { style, fillColor, className } = props;

  return (
    <svg style={style} className={className} viewBox="0 0 26 24">
      <path
        fill={fillColor || '#45A3DB'}
        d="M13 5.07812C12.7197 5.07812 12.4922 5.30565 12.4922 5.58594C12.4922 5.86623 12.7197 6.09375 13 6.09375C13.2803 6.09375 13.5078 5.86623 13.5078 5.58594C13.5078 5.30565 13.2803 5.07812 13 5.07812Z"
      />
      <path
        fill={fillColor || '#45A3DB'}
        d="M17.0625 0C12.4908 0 8.73854 3.15201 8.44833 7.12227C4.09444 7.34067 0 10.4802 0 14.7266C0 16.5011 0.702209 18.2206 1.98047 19.5829C2.23338 20.6255 1.92632 21.7306 1.1644 22.4925C1.0192 22.6377 0.975754 22.8561 1.05431 23.046C1.13286 23.2356 1.31793 23.3594 1.52344 23.3594C2.97169 23.3594 4.36739 22.7915 5.40443 21.7907C6.4147 22.1351 7.73283 22.3438 8.9375 22.3438C13.5086 22.3438 17.2605 19.1925 17.5515 15.2231C18.6147 15.1759 19.7206 14.9795 20.5956 14.6813C21.6326 15.6821 23.0283 16.25 24.4766 16.25C24.6821 16.25 24.8671 16.1262 24.9457 15.9366C25.0242 15.7467 24.9808 15.5284 24.8356 15.3831C24.0737 14.6212 23.7666 13.5161 24.0195 12.4735C25.2978 11.1112 26 9.39175 26 7.61719C26 3.20497 21.5902 0 17.0625 0ZM8.9375 21.3281C7.73898 21.3281 6.37325 21.0929 5.45839 20.7289C5.26241 20.6507 5.03845 20.7021 4.89583 20.8578C4.28268 21.5281 3.48546 21.9954 2.61682 22.2103C3.09547 21.2932 3.21767 20.2121 2.92647 19.1878C2.90346 19.107 2.86081 19.0332 2.80229 18.9731C1.65019 17.7865 1.01562 16.2784 1.01562 14.7266C1.01562 11.1481 4.64331 8.125 8.9375 8.125C12.9925 8.125 16.5547 10.9447 16.5547 14.7266C16.5547 18.3667 13.1377 21.3281 8.9375 21.3281V21.3281ZM23.1977 11.8638C23.1392 11.9241 23.0965 11.9979 23.0735 12.0784C22.7823 13.1028 22.9045 14.1838 23.3832 15.1009C22.5145 14.8862 21.7173 14.4187 21.1042 13.7484C20.9615 13.5929 20.7376 13.5413 20.5416 13.6195C19.7487 13.9349 18.6177 14.1533 17.5499 14.2061C17.4015 12.2857 16.4436 10.4808 14.8059 9.14062H21.4297C21.7102 9.14062 21.9375 8.9133 21.9375 8.63281C21.9375 8.35233 21.7102 8.125 21.4297 8.125H13.2458C12.0931 7.53824 10.7988 7.19606 9.46654 7.12425C9.75833 3.71358 13.0538 1.01562 17.0625 1.01562C21.3567 1.01562 24.9844 4.0387 24.9844 7.61719C24.9844 9.16899 24.3498 10.6772 23.1977 11.8638V11.8638Z"
      />
      <path
        fill={fillColor || '#45A3DB'}
        d="M21.4297 5.07812H15.0312C14.7508 5.07812 14.5234 5.30545 14.5234 5.58594C14.5234 5.86642 14.7508 6.09375 15.0312 6.09375H21.4297C21.7102 6.09375 21.9375 5.86642 21.9375 5.58594C21.9375 5.30545 21.7102 5.07812 21.4297 5.07812Z"
      />
    </svg>
  );
};
