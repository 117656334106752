import React, {useState} from 'react';
import { fetchEventsAction, getEvents } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';

// constants

// Views
import { ActiveEvents } from './ActiveEvents';
import { History } from './History';

type tab = "booked" | "history"

// Types
export const EventsList: React.FC = () => {
  const dispatch = useDispatch();
  const events = useSelector(getEvents);

  const [selectedTab, setSelectedTab] = useState<tab>('booked')

  React.useEffect(() => {
    if (events === null) {
      dispatch(fetchEventsAction.request());
    }
  }, []);

  return (
    <div className="tab-content-item tab-content-events">
      <div className="title-section">
        <div className="subtab-links">
          <div className="subtab-item" onClick={() => setSelectedTab('booked')}>
            <span className={"subtab-signature text-bold" + (selectedTab === "booked" ? " active" : "")}>Bokad</span>
          </div>
          <div className="subtab-item" onClick={() => setSelectedTab('history')}>
            <span className={"subtab-signature text-bold" + (selectedTab === "history" ? " active" : "")}>Historik</span>
          </div>
          <div className="active-line" style={{left: selectedTab === "history" ? "50%" : "0"}}/>
        </div>
      </div>
      <div className="subtab-content">
        {selectedTab === "booked" && <ActiveEvents/>}
        {selectedTab === "history" && <History/>}
      </div>
    </div>
  );
};
