import EventEmitter from 'event-emitter'

export enum NotificationsEnum {
   SUCCESS = 'notification-success',
   ERROR = 'notification-error'
}

class NotificationsService {
   emitter = new EventEmitter()

   success(message: string) {
      this.emitter.emit(NotificationsEnum.SUCCESS, message)
   }

   error(message: string) {
      this.emitter.emit(NotificationsEnum.ERROR, message)
   }
}

export const notificationsService = new NotificationsService()