import {useFormikContext} from 'formik';
import React from 'react';

// constants
interface ILoginInputProps {
  name: string;
}

export const LoginInput: React.FC<ILoginInputProps> = props => {
  const { name } = props;

  const formik = useFormikContext<any>();
  const meta = formik?.getFieldMeta(name);

  if (!formik) return null;

  const isInvalid = !!meta.error && !!meta.touched;

  return (
    <div className="form-group">
      <label htmlFor="link" className={"label title title-primary" + (isInvalid ? " wrong" : "")}>Email</label>
      <input
        type={"text"}
        placeholder={'login'}
        onBlur={formik.handleBlur(name)}
        name={name}
        onChange={(e: any) =>
          formik.handleChange(name)(e.target.value || '')
        }
        className={"input input-login_code validation-error"}
        id="link"
      />
      { isInvalid && <div>
          <span className="error-message">{isInvalid && meta.error}</span>
      </div>}
    </div>
  );
};
