import {AuthTypeEnum} from '../models/models';
import {IAuthData} from '../store';

export const saveAccess = ({ accessToken, refreshToken }: IAuthData) => {
  localStorage[AuthTypeEnum.access] = accessToken;
  localStorage[AuthTypeEnum.refresh] = refreshToken;
};

export const clearAccess = () => {
  localStorage.removeItem(AuthTypeEnum.access);
  localStorage.removeItem(AuthTypeEnum.refresh);
};

export const getSavedAccess = (): IAuthData | undefined => {
  return {
    accessToken: localStorage[AuthTypeEnum.access],
    refreshToken: localStorage[AuthTypeEnum.refresh]
  };
};

export const getSavedAccessToken = () => {
  return localStorage[AuthTypeEnum.access];
};

export const getSavedRefreshToken = () => {
  return localStorage[AuthTypeEnum.refresh];
};
