import { Config } from "./config";
import { handleErrors, authHeader } from "./utils";
import { IEvent, IFullEvent, IJoinToTheCallResponse } from "./model";

class API {
  public async fetchUserEvents(
    userId: number,
    authToken: string
  ): Promise<IEvent[] | null> {
    return handleErrors(
      fetch(`${Config.API_ENDPOINT_GET_USER_EVENTS}/${userId}`, {
        headers: authHeader(authToken),
      })
    );
  }

  public async fetchEventDetails(
    eventId: number,
    authToken: string
  ): Promise<IFullEvent | null> {
    return handleErrors(
      fetch(`${Config.API_ENDPOINT_GET_EVENT_DETAILS}/${eventId}`, {
        headers: authHeader(authToken),
      })
    );
  }

  public async joinToTheCall(
    eventId: number,
    authToken: string
  ): Promise<IJoinToTheCallResponse | null> {
    return handleErrors(
      fetch(`${Config.API_ENDPOINT_JOIN_TO_THE_CALL}/${eventId}`, {
        headers: authHeader(authToken),
      })
    );
  }

  public async readEvents(
    ids: number[],
    authToken: string
  ): Promise<{ updated: boolean }> {
    return handleErrors(
      fetch(Config.API_ENDPOINT_READ_EVENT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...authHeader(authToken),
        },
        body: JSON.stringify({
          eventIds: ids,
        }),
      })
    );
  }

  public async getNumberOfNew(authToken: string): Promise<{ count: number }> {
    return handleErrors(
      fetch(Config.API_ENDPOINT_GET_NEW_NUMBER, {
        method: "GET",
        headers: authHeader(authToken),
      })
    );
  }
}

export const EventsAPI = new API();
