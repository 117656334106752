import React from 'react';

// Utils
import { calculateTimeDifference } from '../utils/calculateTimeDifference';

interface IEventTimerProps {
  startDate: Date;
  endDate: Date;
}

let interval: null | number | NodeJS.Timeout = null;

export const EventTimer: React.FC<IEventTimerProps> = props => {
  const { startDate, endDate } = props;

  const duration = calculateTimeDifference(endDate, startDate);
  const [remaining, setRemainingTime] = React.useState<null | string>(null);

  const updateRemainingTime = () => {
    const currentDate = new Date();

    setRemainingTime(calculateTimeDifference(endDate, currentDate));
  };

  React.useEffect(() => {
    interval = setInterval(updateRemainingTime, 1000);

    return () => {
      if (typeof interval === "number") {
        clearInterval(interval);
      }
    };
  }, []);

  if (!remaining || !duration) {
    return null;
  }

  return (
    <span className="time">
      <span className="time-past">{duration}</span>
      <span className="time-remaining">({remaining})</span>
    </span>
  );
};