import React from 'react';
import { useSelector } from 'react-redux';
import {
  IMessage,
  IUserInfo,
  IDoctorAttributes,
  IUserAttributes,
  ICreationMessage,
  getUserId,
  UserShortDTO
} from '../store';
import { isValid } from 'date-fns';

// Components
import { UserImage } from './UserImage';
import { Attachment } from './Attachment';

// Utils
import formatWithLocale from '../utils/formatWithLocale';

// Icons
import { FiledIcon } from '../assets/img/icons/Filed';
import Loader from "../components/Loader";

interface IChatMessageProps {
  message: IMessage | ICreationMessage;
  participants: UserShortDTO[]
}

const getAttributes = (isIncoming: boolean, userAttributes?: IUserAttributes, doctor?: IUserInfo<IDoctorAttributes>) => {
  if(isIncoming) {
    if(!doctor?.attributes?.personalInformation) {
      return {};
    }

    const { personalInformation } = doctor.attributes;

    return {
      image: personalInformation?.profileImage,
      name: personalInformation?.firstName && personalInformation?.lastName ? `${personalInformation.firstName} ${personalInformation.lastName}` : "Unknown"
    }
  } else {
    if(!userAttributes) return {};

    return {
      image: userAttributes.profileImageUrl,
      name: userAttributes.name
    }
  }
}

const renderLabel = (message: any) => {
  const date = new Date(message.createdAt);
  const status = message.status;

  if (isValid(date)) {
    return <span>{formatWithLocale(date, 'HH:mm')}</span>;
  } else if (status) {
    if (status === 'sending') {
      return (<div className="load-more-loader changed-size"><Loader show={true} /></div>);
    } else if (status === 'filed') {
      return (<FiledIcon />);
    }
  }

  return null;
}

export const ChatMessage: React.FC<IChatMessageProps> = props => {
  const { content, attachments } = props.message;
  const authorId = (props.message as any).authorId;

  const userId = useSelector(getUserId);
  const isIncoming = authorId && authorId !== userId;
  const author = props.participants.find((doctor) => doctor.id === authorId);
  // const { name, image } = getAttributes(isIncoming, attributes, author);

  return (
    <div className={"dialog-item" + (isIncoming ? " income" : " outcome")}>
      <div className="photo-wrap">
        <UserImage className="photo"/>
      </div>
      <div className="dialog-message">
        <div className="dialog-topper">
          <span className="user-name text">{author?.name}</span>
          <span className="dialog-time text">{renderLabel(props.message)}</span>
        </div>
        <div className="dialog-message-content">
          {content}
          {(attachments as { name: string, uri: string; }[])?.map(({ name, uri }, index) => (
            <Attachment key={index} name={name} uri={uri} />
          ))}
        </div>
      </div>
    </div>
  );
};
