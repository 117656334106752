import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchUserChatsAction,
  getUserChats,
  getChatState,
} from '../../store';

// Components
import { ChatItem } from '../../components/ChatItem';
import { SearchBar } from '../../components/SearchBar';
import { TabMessage } from '../../components/TabMessage';

// Context
import Scrollbars from "react-custom-scrollbars";

export const ChatsList: React.FC = () => {
  const dispatch = useDispatch();
  const chatState = useSelector(getChatState);
  const chats = useSelector(getUserChats);
  const [filteredChats, setFilteredChats] = React.useState(chats);

  React.useEffect(() => {
    dispatch(fetchUserChatsAction.request());
  }, []);

  React.useEffect(() => {
    setFilteredChats(chats);
  }, [chats]);

  console.log('filteredChats: ', filteredChats);

  return (
    <>
      <div className={"tab-content-item tab-content-messages" + (!chatState || (chatState.isLoading && chatState.target === 'chat') ? " loading-page" : "")}>
        <SearchBar chats={chats!} setChats={setFilteredChats} />
        {filteredChats && filteredChats.length ? (
          <Scrollbars>
            {filteredChats.map(chatData => (
              <ChatItem key={chatData.id} {...chatData} />
            ))}
          </Scrollbars>
        ) : (
          <TabMessage title={'Du har inga konversationer än'} />
        )}
      </div>
    </>
  );
};
