export const appName = "Riddarens_doctor";

export const serverLink =
  "http://localhost:8080/api/v1/" ||
  process.env.REACT_APP_API_ENDPOINT ||
  "https://api.riddarens.ternala.com/api/v1/";
export const ws_endpoint =
  "http://localhost:8080/chat" ||
  process.env.REACT_APP_WS_ENDPOINT ||
  "https://api.riddarens.ternala.com:4040/chat";

export const homePage = "doctors";

export type typeOfId = number;

export const limitGetDoctors = 10;
export const limitGetPatients = 10;
export const limitGetEvents = 10;
export const limitGetUsersBySearchEvent = 5;
export const limitGetMessages = 10;
export const limitGetConversations = 10;

export const countDaysForExpandedTimeline = 5;
export const countDaysForChangeExpandedTimeline = 5;

export const startIntervalMinutes = 480;
export const endIntervalMinutes = 1200;
export const stepIntervalMinutes = 15;
export const stepShowedIntervalMinutes = stepIntervalMinutes * 2;

export const version = "0.0.6";

export const serviceWorkerVersion = "1.0.2"

export const enableSocketLog = process.env.REACT_APP_WS_ENABLE_LOG || false;
