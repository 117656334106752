export enum UserTypeEnum {
  Doctor = 'doctor',
  Patient = 'patient',
}

export enum EventTypeEnum {
  Video = 'video',
}

export enum EventStatusEnum {
  Canceled = 'canceled',
  Active = 'active',
}

export enum EventPaymentStatusEnum {
  Pending = 'pending',
  Confirmed = 'confirmed',
  Free = 'free',
}

export interface IFile {
  uri: string;
  type: string;
  name: string;
  size: string;
}

export const maxImageFileSize = 20000000;
export const APP_PREFIX = 'riddarens-patient-app'