import React from 'react';

interface IProps {}

export const TabsWrapper: React.FC<IProps> = ({ children }) => {
  return (
    <section className="tabs">
      <div className="container wrapper">
        <div className="tab-content">
          {children}
        </div>
      </div>
    </section>
  )
}
export default TabsWrapper;
