import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import { store, Config } from './store';

//ServiceWorkers
import * as serviceWorker from "./serviceWorker";

//Styles
import "./assets/scss/main.scss";

//Config
import {serviceWorkerVersion} from "./config";

//Components
import App from "./App";

//Firebase
import { initializeFirebase } from './push';


Config.init({
  API_HOST: process.env.REACT_APP_API_URI || "",
  EXTERNAL_API_HOST: process.env.REACT_APP_EXTERNAL_API_URI || "",
  WS_API_HOST: process.env.REACT_APP_WS_URI || "",
  OCP_APIM_SUBSCRIPTION_KEY: process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY || ""
})

const startApp = () => {
  ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("root")
  );
};

window.addEventListener("resize", () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
});
window.addEventListener("DOMContentLoaded", () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
});
startApp();

if(!navigator?.serviceWorker?.controller || localStorage['serviceWorkerVersion'] !== serviceWorkerVersion){
  localStorage['serviceWorkerVersion'] = serviceWorkerVersion
  serviceWorker.register();
}
initializeFirebase();

