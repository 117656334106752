import React from 'react'

type Props = {
   fill?: string
   style?: { [key: string]: number | string }
}

export const PopupAppLogo: React.FC<Props> = ({ fill, ...props }) => {
   return (
      <svg
         {...props}
         style={{ ...props.style }}
         width="180"
         height="53"
         viewBox="0 0 180 53"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M12.7432 10.5288C12.684 10.0377 12.753 9.65007 13.0556 9.29255C13.8186 8.39374 13.6936 7.06392 12.7991 6.26869C12.5919 6.08158 12.3584 5.93122 12.1249 5.77418C11.523 5.36989 10.9935 4.88875 10.589 4.27729C10.4377 4.05008 10.316 3.80951 10.2667 3.53553C10.1319 2.80045 10.3884 2.21238 10.9573 1.76799C11.7927 1.11978 13.0753 1.35701 13.6476 2.25582C13.7726 2.44961 13.8515 2.66011 13.8285 2.89734C13.8186 2.9909 13.7989 3.06107 13.6673 3.05772C13.4503 3.05104 13.4503 3.06441 13.3779 3.28493C13.3286 3.42861 13.2759 3.46536 13.1444 3.4119C12.8484 3.28827 12.5425 3.17467 12.3255 2.91739C12.2794 2.86393 12.2235 2.81381 12.1676 2.77037C11.9045 2.55987 11.671 2.53983 11.4309 2.70355C11.1711 2.88064 11.0429 3.19806 11.1218 3.48207C11.1909 3.72932 11.3158 3.95319 11.5066 4.12359C11.6743 4.27395 11.8486 4.41762 12.0229 4.55462C12.4735 4.90879 12.9438 5.24292 13.3779 5.62048C13.8449 6.02478 14.1902 6.51261 14.3053 7.13742C14.3678 7.48158 14.358 7.82239 14.2856 8.1632C14.085 9.14219 13.5193 9.87727 12.7432 10.5288Z"
            fill={fill}
         />
         <path
            d="M12.6121 1.20037C12.3391 1.1703 12.0399 1.1302 11.7373 1.10681C11.609 1.09679 11.5696 1.03665 11.563 0.919703C11.5498 0.67579 11.5268 0.431877 11.5104 0.187964C11.5005 -0.00248897 11.5334 -0.0292192 11.7044 0.0242412C11.8425 0.0676778 11.9642 0.137845 12.0728 0.234742C12.2734 0.41517 12.4937 0.485337 12.7634 0.378416C12.9607 0.298226 13.0134 0.351686 12.9739 0.552163C12.9443 0.70252 12.9147 0.852878 12.8785 1.00323C12.8358 1.21039 12.8358 1.21039 12.6121 1.20037Z"
            fill={fill}
         />
         <path
            d="M11.8209 2.89105C12.1432 3.14833 12.4162 3.36551 12.6892 3.57936C12.7484 3.62613 12.7944 3.67625 12.7878 3.75978C12.7615 4.06384 12.7385 4.3679 12.676 4.71205C12.4162 4.51491 12.1827 4.33782 11.9492 4.16074C11.8966 4.12064 11.8702 4.06384 11.8637 3.99701C11.8308 3.64952 11.811 3.30203 11.8209 2.89105Z"
            fill={fill}
         />
         <path
            d="M11.5221 12.774C11.5221 12.9578 11.5221 13.1416 11.5221 13.322C11.3617 13.1416 11.3817 12.9545 11.5221 12.774Z"
            fill={fill}
         />
         <path
            d="M34.6968 37.0788C34.5258 36.0062 34.1311 35.0206 33.4865 34.1451C33.0721 33.5838 32.5295 33.233 31.8487 33.096C31.3126 32.9891 30.7831 33.0191 30.2536 33.1327C29.8327 33.223 29.4282 33.3633 29.0368 33.5471C28.7211 33.6941 28.4218 33.6907 28.1291 33.4702C26.7511 32.4411 25.1856 32.0802 23.505 32.1471C22.8966 32.1705 22.2915 32.2239 21.6962 32.3509C21.2555 32.4444 20.8641 32.3776 20.4925 32.1404C20.4333 32.1003 20.3675 32.0702 20.305 32.0368C19.9531 31.843 19.6933 31.5557 19.5683 31.1714C19.3414 30.4731 19.1704 29.758 19.1112 29.023C19.052 28.2946 19.1836 27.5996 19.4763 26.9313C19.8775 26.0125 19.9663 25.0468 19.8216 24.0545C19.7492 23.55 19.6045 23.0655 19.4171 22.591C19.3513 22.4239 19.2855 22.2535 19.2296 22.0831C19.0356 21.465 19.0684 20.8669 19.3842 20.2956C19.5947 19.918 19.6604 19.507 19.5749 19.086C19.496 18.6917 19.3348 18.3275 19.075 18.0135C19.0421 17.9734 19.006 17.9299 18.9829 17.8832C18.8941 17.7027 18.9796 17.5758 19.177 17.5958C19.4138 17.6192 19.5914 17.7495 19.7295 17.9433C19.9498
            18.2473 20.088 18.5882 20.1833 18.9524C20.2853 19.3466 20.3313 19.7543 20.3971 20.1552C20.4432 20.4325 20.4859 20.7099 20.578 20.9772C20.6964 21.3146 20.9299 21.5018 21.2489 21.5285C21.2522 21.5118 21.2588 21.4917 21.2555 21.4784C21.0746 20.8936 21.0845 20.2989 21.1897 19.7008C21.3246 18.939 21.3213 18.1872 21.0943 17.4388C20.8411 16.6034 20.2327 16.119 19.3809 16.0889C18.881 16.0722 18.5028 16.2894 18.2726 16.7538C18.2134 16.8741 18.1673 17.0044 18.141 17.138C18.0884 17.3953 17.9437 17.5791 17.72 17.7061C17.6115 17.7662 17.4997 17.823 17.3879 17.8832C17.105 18.0302 16.8617 18.224 16.6545 18.4679C16.273 18.9189 16.0395 19.4402 15.9309 20.0249C15.8323 20.5629 15.9178 21.0707 16.148 21.5586C16.2795 21.8359 16.4506 21.9094 16.74 21.8292C16.8255 21.8058 16.9044 21.7624 16.9899 21.7356C17.2596 21.6555 17.3977 21.7657 17.401 22.0564C17.4043 22.2636 17.3221 22.444 17.2136 22.6144C17.0853 22.8149 16.9077 22.9686 16.7367 23.1256C15.6481 24.1347 14.9739 25.3509 14.9509 26.8812C14.9443 27.3055 14.7404 27.4927
            14.326 27.5495C13.7307 27.6297 13.2177 27.4225 12.7605 27.055C12.7112 27.0149 12.6717 26.9647 12.6356 26.9146C12.5073 26.7242 12.5303 26.5304 12.7046 26.3834C12.7605 26.3366 12.823 26.2998 12.8855 26.2664C13.2835 26.0559 13.3163 25.9958 13.2571 25.538C13.2506 25.4946 13.2407 25.4478 13.2341 25.4044C13.1552 24.8931 13.0697 24.3853 13.0039 23.8707C12.8033 22.2001 12.7441 20.5228 12.6717 18.8454C12.6652 18.6884 12.7178 18.5982 12.8428 18.5147C13.221 18.2607 13.5367 17.9466 13.7735 17.549C13.9905 17.1882 14.0333 16.8039 13.9248 16.3996C13.8162 15.9953 13.5992 15.6579 13.3163 15.3605C13.1157 15.1533 12.8888 14.9762 12.6586 14.8058C12.5665 14.739 12.5172 14.6622 12.5139 14.5385C12.4908 13.877 12.5632 13.2354 12.8362 12.6273C12.8592 12.5805 12.9217 12.5204 12.8493 12.4569C12.8066 12.4703 12.7638 12.4836 12.7211 12.4937C12.5139 12.5471 12.4448 12.5037 12.4251 12.2798C12.4152 12.1462 12.4185 12.0058 12.4317 11.8722C12.4711 11.5381 12.4777 11.2039 12.4613 10.8665C12.4382 10.4054 12.4481 9.94093 12.504
            9.47984C12.5599 8.98533 12.6586 8.49416 12.7474 8.00633C12.7638 7.92614 12.7934 7.84595 12.7276 7.77244C12.5632 7.58199 12.5599 7.36481 12.6158 7.13092C12.6421 7.01732 12.6553 6.89703 12.6684 6.78009C12.6783 6.67985 12.6684 6.57961 12.6125 6.49274C12.527 6.35908 12.037 6.01827 11.8594 5.9715C11.8857 6.58963 11.9054 7.20443 11.9383 7.81922C11.9482 8.03306 11.8989 8.21349 11.7344 8.36051C11.5996 8.31039 11.4845 8.22686 11.3825 8.12662C11.3266 8.06982 11.274 8.03641 11.1951 8.08318C11.1227 8.12996 11.1096 8.20013 11.126 8.27364C11.1424 8.35383 11.1655 8.43068 11.1918 8.50753C11.3167 8.89511 11.4351 9.28604 11.5667 9.67029C11.6621 9.94762 11.7015 10.2316 11.7081 10.5257C11.741 11.5414 11.7838 12.5572 11.8166 13.5729C11.8199 13.7066 11.8528 13.8135 11.9581 13.907C12.1291 14.0574 12.1258 14.0641 11.9975 14.2913C11.7772 14.1175 11.5601 13.9471 11.3792 13.7333C11.0832 13.3791 11.0306 12.9915 11.2049 12.5672C11.251 12.4603 11.3069 12.3567 11.3595 12.2498C11.547 11.8622 11.4845 11.4679 11.4055 11.0536C10.9155
            11.498 10.5044 12.2765 10.4321 12.8879C10.3433 13.623 10.5899 14.2077 11.2049 14.6187C11.3825 14.739 11.57 14.8459 11.7542 14.9595C11.8528 15.0197 11.9548 15.0798 12.0567 15.1366C12.1784 15.2034 12.1718 15.3237 12.1455 15.424C12.1126 15.5443 12.0074 15.3839 11.9581 15.4407C11.9581 15.8149 11.9548 16.1758 11.9581 16.5366C11.9712 17.5056 12.0337 18.4779 11.9877 19.4469C11.9383 20.5428 11.8923 21.6421 11.8068 22.7347C11.7344 23.6736 11.6884 24.6158 11.4055 25.528C11.2806 25.9323 11.4154 26.1261 11.8232 26.2597C12.1028 26.3499 12.185 26.517 12.0765 26.7977C11.9219 27.1953 11.8758 27.6029 11.9087 28.0239C11.9449 28.4583 12.0436 28.8826 12.1192 29.3103C12.1389 29.4273 12.1521 29.5442 12.1554 29.6611C12.1554 29.7681 12.0929 29.8483 11.9975 29.895C11.8956 29.9418 11.8298 29.8717 11.7607 29.8115C11.6785 29.738 11.616 29.6511 11.5667 29.5542C11.32 29.0764 10.9846 28.6621 10.6393 28.2578C9.59013 27.0215 8.32066 26.1127 6.77492 25.6249C5.94943 25.3643 5.11079 25.2807 4.26228 25.4344C3.01254 25.6616 2.04234 26.3032
            1.45365 27.476C1.31552 27.7499 1.18397 28.0273 1.04913 28.3046C0.795888 28.8225 0.473586 29.2869 0.00986599 29.6344C-4.01244e-07 29.6411 0.0032888 29.6645 0 29.6812C0.00986639 29.6946 0.019733 29.7113 0.036177 29.7213C0.749846 30.1456 1.39116 30.6569 1.95354 31.275C2.72641 32.127 3.4039 33.0459 3.90709 34.0883C4.03535 34.3523 4.23268 34.5394 4.4925 34.6597C4.77862 34.7934 5.08448 34.8635 5.39363 34.9136C5.59424 34.9471 5.74224 34.8669 5.84419 34.6931C5.90668 34.5829 5.95272 34.4626 6.02508 34.3623C6.19609 34.1284 6.19609 33.8778 6.13032 33.6105C6.0481 33.2864 5.97574 32.959 5.90339 32.6315C5.82775 32.2941 5.78828 31.9533 5.82117 31.6058C5.85077 31.3084 5.93628 31.0277 6.12374 30.7771C6.22898 30.8807 6.25858 31.0077 6.29805 31.1246C6.50853 31.6993 6.59404 32.3008 6.62035 32.9089C6.66639 33.8912 6.57431 34.8669 6.40329 35.8325C6.21912 36.875 5.65015 37.6167 4.67996 38.0277C4.43987 38.1279 4.1965 38.2148 3.94656 38.2816C3.32497 38.4454 2.70339 38.6058 2.08181 38.7728C1.88448 38.8263 1.69044 38.8931 1.50627
            38.99C1.2333 39.1303 1.08859 39.3475 1.09517 39.6649C1.09846 39.8287 1.11161 39.989 1.13463 40.1528C1.25303 40.9647 1.60822 41.6697 2.09168 42.3112C2.69024 43.1031 3.30524 43.8783 3.78541 44.757C3.91038 44.9876 4.09455 45.178 4.32148 45.3184C5.1667 45.8329 6.04152 46.2807 6.98211 46.5914C7.64645 46.8086 8.32395 46.9723 9.02446 47.0057C9.31716 47.0191 9.41254 46.9222 9.43227 46.6315C9.46516 46.0802 9.30401 45.5957 8.94553 45.1747C8.72518 44.9174 8.45879 44.7236 8.17595 44.5432C7.61685 44.189 7.10709 44.0888 6.53813 44.6067C6.3112 44.8105 6.03494 44.8272 5.75868 44.7136C5.66002 44.6735 5.56135 44.6267 5.46927 44.5699C3.92353 43.6444 3.29866 42.2076 3.24933 40.4535C3.23946 40.1628 3.36773 40.0425 3.65714 40.0325C4.03206 40.0191 4.40699 40.0325 4.78191 40.0525C5.61069 40.096 6.43946 40.1661 7.26495 40.2563C7.87667 40.3232 8.49496 40.3532 9.1001 40.4668C10.6129 40.7475 11.616 41.81 11.8397 43.347C11.8956 43.7346 11.8693 44.1255 11.912 44.5131C12.0107 45.4253 12.0765 46.3375 12.083 47.253C12.0863 47.8444 12.0699
            48.4324 11.9942 49.0172C11.843 50.1733 11.3891 51.1723 10.5077 51.9475C10.3531 52.0845 10.2578 52.2549 10.1953 52.4453C10.0933 52.7527 10.1986 52.9298 10.5209 52.9365C11.172 52.9499 11.8265 52.9465 12.4777 52.9365C12.7474 52.9331 12.8658 52.8095 12.8921 52.5355C12.925 52.1647 13.0105 51.8038 13.096 51.4429C13.2243 50.9117 13.3032 50.3771 13.2604 49.8258C13.2078 49.1375 13.2275 48.4492 13.2506 47.7575C13.2999 46.1069 13.438 44.4597 13.4347 42.8091C13.4347 42.6921 13.4479 42.5752 13.4742 42.4582C13.5433 42.1776 13.7209 42.0205 13.9971 41.9871C14.155 41.9671 14.3194 41.9671 14.4773 41.9704C15.7566 41.9804 17.0097 41.8167 18.2166 41.3757C18.8711 41.1351 19.5124 40.8544 20.157 40.5938C20.2458 40.5571 20.3281 40.5003 20.4695 40.5103C20.3017 41.0716 20.1768 41.6196 20.111 42.1776C19.9071 43.9284 20.3642 45.4453 21.6534 46.6683C21.8442 46.8487 22.0514 47.0057 22.2454 47.1761C22.4033 47.3131 22.446 47.4635 22.3737 47.664C22.3507 47.7308 22.3145 47.7943 22.275 47.8577C21.91 48.4291 21.5416 48.9971 21.1305 49.5351C20.5024
            50.3503 19.769 51.0453 18.8514 51.5231C18.5587 51.6768 18.289 51.8773 18.0259 52.0778C17.9239 52.1546 17.8384 52.2448 17.7858 52.3651C17.6345 52.6993 17.8023 52.9632 18.164 52.9699C18.2002 52.9699 18.2364 52.9666 18.2726 52.9666C18.7889 52.9565 19.3085 52.9231 19.8249 52.9933C19.9202 53.0067 20.0222 52.9966 20.1176 52.9933C20.4201 52.9766 20.6372 52.8262 20.7819 52.5589C20.8773 52.3818 20.9365 52.1914 20.9759 51.9976C21.1897 50.9017 21.7653 50.0262 22.5809 49.3012C23.0051 48.9236 23.4721 48.6062 23.9589 48.3155C24.1069 48.2286 24.2549 48.1451 24.393 48.0449C24.6758 47.841 24.7219 47.6272 24.5542 47.3165C24.4686 47.1594 24.3568 47.0124 24.2582 46.8654C23.6267 45.9231 23.5412 44.9375 24.0247 43.9017C24.2812 43.3504 24.6199 42.8492 24.9751 42.3613C25.0179 42.3045 25.0738 42.2544 25.1297 42.211C25.2317 42.1341 25.3336 42.1508 25.4191 42.2344C25.4619 42.2778 25.5013 42.3313 25.5342 42.3847C25.8368 42.8792 26.2446 43.2668 26.7083 43.601C27.0142 43.8215 27.3266 44.032 27.6423 44.2391C28.1981 44.61 28.6454 45.0811 28.9875
            45.6592C29.6419 46.7752 29.8952 47.978 29.7176 49.2644C29.5926 50.1632 29.2374 50.9484 28.5402 51.5499C28.3626 51.7036 28.2179 51.8907 28.1061 52.0978C28.014 52.2649 27.9449 52.442 27.9087 52.6291C27.8594 52.873 27.8956 52.9231 28.1357 52.9398C28.2442 52.9465 28.3494 52.9465 28.458 52.9499C29.0664 52.9666 29.6715 52.9365 30.2799 52.9265C30.6713 52.9198 30.7042 52.8864 30.7831 52.4888C30.793 52.4453 30.7996 52.3985 30.8062 52.3551C30.9246 51.5298 31.0035 50.7012 31.043 49.8659C31.1219 48.1986 30.9969 46.548 30.6976 44.9107C30.5628 44.179 30.2668 43.5341 29.6847 43.043C29.4051 42.8057 29.2374 42.4816 29.1453 42.1208C29.0795 41.8635 29.0499 41.6062 29.0434 41.3389C29.0335 40.6673 29.1059 39.9991 29.1782 39.3308C29.2637 38.5089 29.3492 37.6902 29.3163 36.8616C29.2933 36.2669 29.1979 35.6888 29.0039 35.1308C28.9776 35.054 28.948 34.9771 28.9381 34.8969C28.9118 34.7065 28.9973 34.5996 29.1848 34.5829C29.4512 34.5561 29.6518 34.6764 29.7932 34.9003C29.8853 35.0439 29.9511 35.2043 30.0103 35.3647C30.2471 36.0163 30.2997
            36.6912 30.2438 37.3762C30.155 38.442 30.0892 39.5079 30.1648 40.5838C30.2142 41.2754 30.3687 41.8969 30.8785 42.4048C31.2732 42.7991 31.51 43.3036 31.6711 43.8382C31.8421 44.4162 31.9178 45.0076 31.8717 45.6124C31.8586 45.8129 31.852 46.0134 31.8882 46.2105C31.9342 46.4711 32.0954 46.5747 32.3552 46.5313C32.5591 46.4945 32.717 46.3809 32.8584 46.2372C33.036 46.0568 33.1774 45.8463 33.3122 45.6325C34.4041 43.905 34.9007 42.0005 34.9303 39.9623C34.9303 38.9867 34.8481 38.0277 34.6968 37.0788ZM13.4084 16.2559C13.5433 16.5132 13.5762 16.7906 13.4906 17.0712C13.3295 17.5991 12.9875 18.0168 12.7013 18.518C12.6224 18.254 12.652 18.0736 12.8033 17.8932C13.0467 17.5958 13.2078 17.2516 13.2407 16.8574C13.2637 16.5633 13.1914 16.3061 12.9184 16.0956C12.8066 16.5166 12.7342 16.9142 12.7244 17.3151C12.7211 17.4488 12.698 17.5691 12.675 17.696C12.6684 17.7094 12.6619 17.7194 12.652 17.7328C12.6454 17.7194 12.6388 17.7061 12.6323 17.696C12.6191 17.5858 12.6191 17.4789 12.629 17.3686C12.606 16.7471 12.5796 16.129 12.5533
            15.4641C12.9315 15.6846 13.2275 15.9051 13.4084 16.2559ZM15.0068 29.4206C14.9903 29.5943 14.9377 29.7547 14.8522 29.9084C14.6615 30.2492 14.3951 30.5098 14.0662 30.707C13.7636 30.8907 13.4413 31.0144 13.1026 31.1079C12.7934 31.1948 12.6849 31.1213 12.6092 30.7972C12.5468 30.5299 12.5731 30.2559 12.5632 30.0721C12.5632 29.7915 12.5566 29.601 12.5632 29.4106C12.5731 29.1265 12.6586 29.0163 12.925 28.9495C13.4775 28.8091 14.0366 28.7791 14.599 28.8659C14.9246 28.9194 15.043 29.0898 15.0068 29.4206Z"
            fill={fill}
         />
         <path
            d="M13.2159 2.21351C13.2192 2.2937 13.1039 2.4474 13.0346 2.45742C12.9555 2.46744 12.8236 2.31374 12.8335 2.21351C12.8434 2.10324 12.9225 2.03976 13.0115 1.99966C13.0709 1.97627 13.2126 2.13332 13.2159 2.21351Z"
            fill={fill}
         />
         <path
            d="M159.959 28.5152C159.959 27.9387 159.959 27.4374 159.959 26.9298C159.959 23.6273 159.959 20.3249 159.959 17.0287C159.959 16.941 159.966 16.8595 159.959 16.7718C159.929 16.496 160.064 16.4208 160.311 16.4271C161.033 16.4396 161.754 16.4334 162.47 16.4271C162.679 16.4271 162.784 16.496 162.766 16.7216C162.76 16.8407 162.766 16.9598 162.766 17.0788C162.766 22.4743 162.766 27.8698 162.766 33.259C162.766 33.3279 162.766 33.3969 162.766 33.4658C162.76 33.9044 162.76 33.9045 162.322 33.9107C161.921 33.9107 161.52 33.9045 161.113 33.9107C160.663 33.917 160.305 33.7415 160.027 33.3781C159.793 33.071 159.546 32.7639 159.312 32.4569C156.684 29.0542 154.063 25.6577 151.429 22.255C151.318 22.1171 151.244 21.9354 151.077 21.8602C151.077 22.3991 151.077 22.9192 151.077 23.4456C151.077 26.7293 151.077 30.0129 151.077 33.2966C151.077 33.9859 151.151 33.9045 150.448 33.9107C149.825 33.917 149.208 33.9044 148.585 33.917C148.351 33.9232 148.234 33.8481 148.265 33.5974C148.277 33.4971 148.265 33.3906 148.265 33.2903C148.265 27.876 148.265 22.468 148.265 17.0538C148.265 16.3644 148.197 16.4334 148.888 16.4334C149.289 16.4334 149.689 16.4396 150.097 16.4334C150.417 16.4334 150.683 16.5524 150.886 16.8031C151.003 16.9472 151.12 17.0914 151.238 17.2355C154.007 20.8262 156.777 24.4169 159.54 28.0077C159.676 28.1643 159.799 28.321 159.959 28.5152Z"
            fill={fill}
         />
         <path
            d="M134.539 25.1562C134.539 22.4866 134.539 19.8108 134.539 17.1413C134.539 17.0034 134.546 16.8718 134.539 16.7339C134.533 16.5271 134.607 16.4081 134.829 16.4269C134.928 16.4331 135.033 16.4269 135.132 16.4269C138.364 16.4269 141.602 16.4269 144.834 16.4269C144.933 16.4269 145.038 16.4331 145.136 16.4269C145.327 16.4143 145.408 16.5083 145.408 16.6963C145.408 17.3794 145.401 18.0562 145.408 18.7392C145.408 18.9397 145.309 19.0087 145.13 19.0024C145.013 18.9961 144.896 19.0024 144.778 19.0024C142.669 19.0024 140.553 19.0024 138.444 19.0024C138.327 19.0024 138.209 19.0149 138.092 19.0024C137.809 18.9711 137.759 19.1277 137.759 19.3721C137.765 20.7508 137.765 22.1294 137.759 23.5081C137.759 23.7712 137.858 23.8652 138.117 23.8652C138.734 23.8527 139.357 23.8652 139.98 23.8652C141.121 23.8652 142.256 23.8652 143.397 23.8652C143.779 23.8652 143.785 23.8778 143.785 24.2663C143.792 24.8303 143.792 25.388 143.785 25.952C143.779 26.3531 143.779 26.3593 143.36 26.3656C141.701 26.3656 140.041 26.3656 138.382 26.3656C137.759 26.3656 137.765 26.2591 137.765 27.0048C137.765 28.2832 137.765 29.5553 137.765 30.8336C137.765 31.3099 137.772 31.3162 138.24 31.3162C140.387 31.3162 142.527 31.3162 144.674 31.3162C144.809 31.3162 144.945 31.3224 145.075 31.3162C145.297 31.3036 145.414 31.3788 145.414 31.6295C145.408 32.2937 145.408 32.958 145.414 33.6223C145.414 33.8353 145.334 33.9356 145.118 33.9168C145.019 33.9105 144.914 33.9168 144.815 33.9168C141.583 33.9168 138.345 33.9168 135.113 33.9168C134.521 33.9168 134.546 33.992 134.546 33.3403C134.539 30.6018 134.539 27.8821 134.539 25.1562Z"
            fill={fill}
         />
         <path
            d="M170.91 34.0919C168.905 34.048 167.092 33.459 165.562 32.0991C165.205 31.7795 165.229 31.8422 165.47 31.4411C165.68 31.0902 165.883 30.7393 166.099 30.4009C166.481 29.7993 166.963 29.699 167.548 30.1001C168.06 30.451 168.579 30.8019 169.146 31.0651C170.102 31.5038 171.095 31.5727 172.094 31.3095C173.353 30.9774 173.895 30.1126 173.889 28.8217C173.883 28.2201 173.606 27.7502 173.149 27.3804C172.779 27.0796 172.347 26.8854 171.903 26.735C171.206 26.4969 170.503 26.2713 169.8 26.0457C169.288 25.8827 168.794 25.6759 168.313 25.4253C166.956 24.7234 166.173 23.608 166 22.0664C165.661 19.14 167.518 16.7336 170.404 16.3325C172.168 16.0882 173.84 16.3388 175.413 17.2036C175.764 17.3979 176.091 17.6422 176.394 17.9054C176.535 18.0245 176.554 18.1373 176.461 18.3002C176.221 18.7452 176.005 19.2089 175.746 19.6475C175.493 20.0799 175.123 20.1865 174.673 19.9797C174.432 19.8669 174.21 19.7039 173.982 19.5661C172.927 18.9332 171.804 18.7013 170.602 19.0021C169.454 19.2966 168.807 20.2241 168.955 21.3332C169.004 21.7218 169.171 22.0414 169.454 22.3045C169.905 22.7369 170.466 22.9625 171.033 23.1756C172.07 23.5578 173.137 23.8649 174.148 24.3224C174.315 24.3976 174.488 24.4728 174.648 24.5668C175.98 25.3313 176.795 26.4404 176.869 28.0321C176.955 29.8808 176.437 31.4975 175.018 32.732C173.982 33.6344 172.748 34.0041 171.41 34.0919C171.243 34.0981 171.077 34.0919 170.91 34.0919Z"
            fill={fill}
         />
         <path
            d="M127.709 48.1732C127.709 45.3344 127.709 42.527 127.709 39.7196C127.709 38.811 127.617 38.9488 128.486 38.9363C128.819 38.93 129.159 38.93 129.492 38.9363C129.856 38.9426 129.856 38.9488 129.874 39.2998C129.88 39.3875 129.874 39.469 129.874 39.5567C129.874 43.6237 129.874 47.6907 129.874 51.7576C129.874 51.8767 129.868 51.9958 129.874 52.1148C129.887 52.3091 129.806 52.4031 129.609 52.3843C129.578 52.3843 129.541 52.3843 129.51 52.3843C127.956 52.3718 128.184 52.5284 127.247 51.3064C125.254 48.7246 123.256 46.1366 121.257 43.5547C121.152 43.4231 121.078 43.2665 120.93 43.1787C120.807 43.2101 120.844 43.3103 120.844 43.3855C120.924 46.2431 120.863 49.1006 120.881 51.9582C120.881 52.3718 120.875 52.378 120.462 52.378C119.974 52.3843 119.487 52.3718 119.006 52.3843C118.784 52.3906 118.704 52.2966 118.716 52.0835C118.722 51.9644 118.716 51.8454 118.716 51.7263C118.716 47.6781 118.716 43.6299 118.716 39.5755C118.716 39.4878 118.716 39.4063 118.716 39.3186C118.728 38.9363 118.728 38.93 119.123 38.93C120.727 38.93 120.418 38.811 121.338 39.9953C123.336 42.5772 125.322 45.1652 127.321 47.7471C127.432 47.8975 127.512 48.0729 127.709 48.1732Z"
            fill={fill}
         />
         <path
            d="M108.159 45.6916C108.159 43.6487 108.159 41.6058 108.159 39.5692C108.159 39.4501 108.165 39.3311 108.159 39.212C108.147 39.0177 108.239 38.93 108.431 38.9363C108.529 38.9363 108.634 38.9363 108.733 38.9363C111.126 38.9363 113.526 38.9363 115.919 38.9363C115.968 38.9363 116.017 38.9363 116.067 38.9363C116.505 38.9426 116.505 38.9426 116.511 39.3937C116.517 39.8011 116.505 40.2084 116.517 40.6157C116.523 40.8601 116.425 40.9541 116.19 40.9353C116.073 40.9228 115.956 40.9353 115.839 40.9353C114.247 40.9353 112.656 40.9353 111.064 40.9353C110.651 40.9353 110.645 40.9416 110.645 41.3614C110.639 42.3327 110.639 43.2978 110.645 44.2691C110.645 44.6326 110.657 44.6388 111.009 44.6388C112.218 44.6451 113.421 44.6388 114.63 44.6388C114.765 44.6388 114.895 44.6451 115.031 44.6388C115.197 44.6388 115.277 44.714 115.277 44.8832C115.277 45.3595 115.277 45.8357 115.277 46.312C115.277 46.4812 115.209 46.5689 115.043 46.5627C114.926 46.5627 114.809 46.5627 114.691 46.5627C113.482 46.5627 112.28 46.5627 111.071 46.5627C110.651 46.5627 110.645 46.5689 110.645 46.9825C110.639 47.9664 110.639 48.9565 110.645 49.9403C110.645 50.3477 110.657 50.3539 111.077 50.3539C112.687 50.3602 114.29 50.3539 115.9 50.3539C116.61 50.3539 116.505 50.3288 116.511 50.9931C116.517 51.3691 116.505 51.7388 116.511 52.1148C116.511 52.2903 116.437 52.3655 116.27 52.3592C116.172 52.3529 116.067 52.3592 115.968 52.3592C113.538 52.3592 111.108 52.3592 108.684 52.3592C108.597 52.3592 108.517 52.3529 108.431 52.3592C108.246 52.3717 108.147 52.2965 108.159 52.096C108.165 51.977 108.159 51.8579 108.159 51.7388C108.159 49.7398 108.159 47.7157 108.159 45.6916Z"
            fill={fill}
         />
         <path
            d="M50.7894 49.3946C50.8511 49.3131 50.9004 49.2755 50.9128 49.2379C51.4617 47.1261 52.3746 45.1521 53.1086 43.1092C53.5095 41.9938 53.9413 40.8846 54.3669 39.7754C54.6321 39.0736 54.8233 38.9357 55.5635 38.9357C56.0323 38.9357 56.5011 38.9357 56.9698 38.9357C57.1796 38.9357 57.2412 39.0297 57.1611 39.224C57.124 39.318 57.087 39.412 57.05 39.506C55.4093 43.6356 53.7686 47.7653 52.1217 51.8887C51.8935 52.4527 52.0168 52.3775 51.4124 52.3837C50.9929 52.39 50.5735 52.39 50.1541 52.3837C49.6298 52.3775 49.7161 52.4151 49.5434 51.9701C48.7601 50.0024 47.9767 48.0285 47.1934 46.0608C46.3298 43.8863 45.4663 41.7118 44.6028 39.5373C44.5781 39.4746 44.5534 39.412 44.5288 39.3493C44.3746 38.942 44.3807 38.9357 44.8248 38.9357C45.2628 38.9295 45.6945 38.9295 46.1325 38.9357C46.8048 38.942 47.0083 39.0861 47.2551 39.7378C48.1618 42.1129 49.0562 44.4941 49.969 46.8692C50.2651 47.6838 50.555 48.511 50.7894 49.3946Z"
            fill={fill}
         />
         <path
            d="M101.676 52.5412C99.6527 52.5099 97.9565 51.8456 96.692 50.2414C95.8162 49.1259 95.4152 47.8225 95.3042 46.4251C95.1809 44.8334 95.4029 43.2981 96.2171 41.9006C97.204 40.1961 98.6905 39.2185 100.603 38.9052C102.058 38.6671 103.471 38.8112 104.809 39.4943C105.278 39.7324 105.704 40.0332 106.092 40.3778C106.234 40.5032 106.246 40.6097 106.129 40.7664C105.895 41.0734 105.679 41.4056 105.445 41.7126C105.21 42.0134 104.994 42.0573 104.649 41.8943C104.47 41.8066 104.297 41.6938 104.131 41.581C103.162 40.9544 102.095 40.804 100.991 41.0108C99.2765 41.3304 98.3328 42.5022 97.9442 44.1503C97.6543 45.3597 97.6974 46.588 98.123 47.7724C98.7954 49.6273 100.405 50.6174 102.354 50.4168C103.206 50.3291 103.989 50.0847 104.643 49.4894C105.062 49.1071 105.241 49.1385 105.623 49.5333C105.846 49.7651 106.049 50.0158 106.277 50.2414C106.432 50.3918 106.425 50.5046 106.283 50.6675C105.58 51.4634 104.723 51.9897 103.718 52.2843C103.039 52.4723 102.336 52.5161 101.676 52.5412Z"
            fill={fill}
         />
         <path
            d="M59.9349 25.1686C59.9349 22.4802 59.9349 19.7919 59.9349 17.1035C59.9349 16.9657 59.941 16.8341 59.9349 16.6962C59.9287 16.502 60.0274 16.4268 60.2124 16.4268C61.1007 16.433 61.9889 16.433 62.8771 16.4268C63.0683 16.4268 63.1546 16.502 63.1485 16.6962C63.1423 16.8153 63.1485 16.9343 63.1485 17.0534C63.1485 22.4614 63.1485 27.8757 63.1485 33.2837C63.1485 33.9981 63.1978 33.8978 62.5193 33.9041C61.8347 33.9103 61.1438 33.9041 60.4592 33.9041C59.941 33.9041 59.9287 33.9667 59.9287 33.384C59.9287 31.3599 59.9287 29.3358 59.9287 27.3117C59.9349 26.5973 59.9349 25.8829 59.9349 25.1686Z"
            fill={fill}
         />
         <path
            d="M135.589 46.6624C135.589 44.9955 135.589 43.3286 135.589 41.6617C135.589 41.5427 135.583 41.4236 135.589 41.3045C135.607 41.0539 135.484 40.9724 135.256 40.9849C135.12 40.9912 134.991 40.9849 134.855 40.9849C133.917 40.9849 132.98 40.9849 132.042 40.9849C131.592 40.9849 131.592 40.9787 131.586 40.5463C131.58 40.1014 131.592 39.6627 131.586 39.2178C131.586 39.0235 131.66 38.9295 131.851 38.9358C131.95 38.9421 132.055 38.9358 132.153 38.9358C135.268 38.9358 138.383 38.9358 141.498 38.9358C141.584 38.9358 141.664 38.9421 141.751 38.9358C142.004 38.917 142.059 39.0611 142.053 39.2804C142.047 39.7254 142.053 40.164 142.047 40.6089C142.041 40.9661 142.035 40.9787 141.677 40.9849C140.758 40.9912 139.833 40.9849 138.913 40.9849C138.044 40.9849 138.044 40.9849 138.044 41.8811C138.044 45.196 138.044 48.511 138.044 51.826C138.044 52.4401 138.081 52.3837 137.513 52.3837C137.014 52.3837 136.508 52.39 136.008 52.3837C135.601 52.3775 135.595 52.3775 135.595 51.9639C135.589 50.1967 135.589 48.4296 135.589 46.6624Z"
            fill={fill}
         />
         <path
            d="M168.337 45.6351C168.337 43.6486 168.337 41.6558 168.337 39.6693C168.337 39.5315 168.343 39.3999 168.337 39.262C168.325 39.0239 168.429 38.9299 168.664 38.9299C169.268 38.9361 169.867 38.9361 170.471 38.9299C170.705 38.9236 170.81 39.0113 170.792 39.2557C170.779 39.3936 170.792 39.5252 170.792 39.6631C170.792 42.9781 170.792 46.2931 170.792 49.6081C170.792 49.7271 170.786 49.8462 170.792 49.9653C170.804 50.3162 170.804 50.3225 171.174 50.3287C172.025 50.335 172.883 50.3287 173.734 50.3287C174.406 50.3287 175.072 50.335 175.745 50.3225C176.004 50.3162 176.103 50.4102 176.096 50.6734C176.084 51.1308 176.078 51.5946 176.096 52.052C176.109 52.3403 175.973 52.3904 175.732 52.3842C174.795 52.3716 173.857 52.3779 172.92 52.3779C171.532 52.3779 170.144 52.3779 168.75 52.3779C168.343 52.3779 168.337 52.3716 168.337 51.9518C168.337 49.8525 168.337 47.7406 168.337 45.6351Z"
            fill={fill}
         />
         <path
            d="M81.8849 22.405C81.2804 20.218 79.979 18.5323 77.962 17.4733C76.5988 16.7589 75.1431 16.4268 73.6073 16.4268C71.5471 16.4268 69.487 16.4268 67.4268 16.4268C66.9827 16.4268 66.9827 16.433 66.9766 16.8779C66.9766 16.997 66.9766 17.1161 66.9766 17.2351C66.9766 19.8859 66.9766 22.5429 66.9766 25.1936C66.9766 27.9509 66.9766 30.7019 66.9766 33.4592C66.9766 33.9041 66.9827 33.9104 67.4207 33.9104C69.561 33.9104 71.7075 33.9104 73.8478 33.9041C74.9273 33.8979 75.9758 33.6911 76.9874 33.3025C78.7268 32.6446 80.1085 31.5479 81.0399 29.8998C82.3845 27.5185 82.6066 24.9994 81.8849 22.405ZM78.5294 27.9196C77.7214 30.1567 76.0375 31.2095 73.7553 31.316C72.7191 31.3662 71.6767 31.3349 70.6404 31.3286C70.2087 31.3286 70.2025 31.3223 70.2025 30.8711C70.2025 29.0664 70.2025 27.2679 70.2025 25.4631C70.2025 25.3628 70.2025 25.2563 70.2025 25.156C70.2025 23.3199 70.2025 21.4839 70.2025 19.6478C70.2025 19.5099 70.2025 19.3783 70.2087 19.2404C70.2087 19.0712 70.3012 19.0023 70.4615 19.0023C71.7507 19.0336 73.0398 18.9271 74.3228 19.065C76.4076 19.2968 77.8263 20.4373 78.5356 22.4238C79.1833 24.2411 79.1833 26.1023 78.5294 27.9196Z"
            fill={fill}
         />
         <path
            d="M100.38 24.2352C100.251 22.6999 99.7883 21.2712 98.9186 19.9991C97.3396 17.6867 95.0697 16.6026 92.3804 16.471C90.092 16.3582 87.7913 16.4334 85.503 16.4271C85.4721 16.4271 85.4351 16.4271 85.4043 16.4271C85.2192 16.4146 85.1205 16.4898 85.1329 16.6903C85.139 16.8282 85.1329 16.9598 85.1329 17.0976C85.1329 19.786 85.1329 22.4681 85.1329 25.1564C85.1329 27.8448 85.1329 30.5269 85.1329 33.2152C85.1329 33.3531 85.139 33.4847 85.1329 33.6225C85.1267 33.8105 85.1884 33.9108 85.3919 33.8983C85.4783 33.892 85.5585 33.8983 85.6448 33.8983C87.6186 33.8983 89.5986 33.892 91.5724 33.8983C92.4791 33.8983 93.3796 33.8168 94.2555 33.5787C96.4329 32.9771 98.1661 31.7739 99.2825 29.7373C100.22 28.0265 100.547 26.1779 100.38 24.2352ZM96.6981 27.8573C95.9456 30.0318 94.3419 31.1472 92.1152 31.2976C90.9679 31.3791 89.8083 31.3227 88.6487 31.329C88.439 31.329 88.3341 31.26 88.3465 31.0344C88.3526 30.9154 88.3465 30.7963 88.3465 30.6772C88.3465 28.8412 88.3465 27.0051 88.3465 25.169C88.3465 23.383 88.3465 21.597 88.3465 19.8111C88.3465 19.7108 88.3465 19.6043 88.3465 19.504C88.3526 18.9964 88.3526 18.9964 88.8337 18.9964C89.7404 18.9964 90.641 18.9901 91.5477 18.9964C91.8623 18.9964 92.183 19.0277 92.4976 19.0653C94.5824 19.3097 95.9887 20.4628 96.6919 22.4493C97.3211 24.2352 97.3149 26.0713 96.6981 27.8573Z"
            fill={fill}
         />
         <path
            d="M132.358 33.3964C131.439 32.0178 130.532 30.6266 129.594 29.2605C128.928 28.2955 128.41 27.2113 127.423 26.4343C127.676 26.3278 127.873 26.24 128.065 26.1586C129.187 25.6572 130.075 24.8927 130.637 23.7585C132.006 20.9887 130.791 17.9369 127.917 16.9217C127.022 16.6083 126.097 16.4517 125.153 16.4454C123.395 16.4204 121.637 16.4329 119.88 16.4329C119.232 16.4329 119.269 16.3452 119.269 17.0721C119.269 22.4801 119.269 27.8881 119.269 33.3024C119.269 33.3714 119.269 33.4403 119.269 33.5092C119.281 33.904 119.281 33.9103 119.658 33.9165C120.361 33.9228 121.064 33.9165 121.767 33.9165C121.915 33.9165 122.069 33.9103 122.217 33.9165C122.39 33.9228 122.464 33.8476 122.458 33.6721C122.452 33.5531 122.458 33.434 122.458 33.315C122.458 31.4413 122.458 29.5738 122.458 27.7001C122.458 27.5811 122.464 27.462 122.458 27.3429C122.452 27.1487 122.532 27.0672 122.723 27.0672C123.044 27.0735 123.358 27.086 123.679 27.0609C124.358 27.0108 124.814 27.3116 125.172 27.8881C125.924 29.0976 126.708 30.2819 127.479 31.4789C127.867 32.0742 128.256 32.6695 128.638 33.2711C128.885 33.6534 129.218 33.8915 129.668 33.8977C130.637 33.9103 131.611 33.904 132.611 33.904C132.574 33.6534 132.444 33.5343 132.358 33.3964ZM126.399 24.3538C125.924 24.5669 125.425 24.711 124.9 24.7173C124.197 24.7298 123.494 24.7235 122.797 24.7361C122.532 24.7423 122.452 24.6358 122.452 24.3789C122.464 23.5329 122.458 22.6806 122.458 21.8346C122.458 20.9887 122.458 20.1364 122.458 19.2904C122.458 19.1087 122.458 18.9395 122.705 18.9458C123.772 18.9646 124.845 18.858 125.906 19.0899C127.146 19.3594 127.867 20.1176 127.984 21.2519C128.126 22.7308 127.571 23.8337 126.399 24.3538Z"
            fill={fill}
         />
         <path
            d="M57.6774 33.3582C56.6165 31.7665 55.5556 30.1748 54.4885 28.5893C53.9827 27.8373 53.5571 27.0227 52.7491 26.4462C53.0205 26.3271 53.2302 26.2394 53.4399 26.1454C54.8956 25.4749 55.901 24.4096 56.3204 22.8179C57.0236 20.1295 55.8146 17.8171 53.224 16.9085C52.3296 16.5952 51.4044 16.451 50.4607 16.4385C48.7213 16.4197 46.9757 16.426 45.2363 16.426C44.5455 16.426 44.6195 16.3633 44.6195 17.0526C44.6195 22.4607 44.6195 27.8749 44.6195 33.283C44.6195 33.402 44.6257 33.5211 44.6195 33.6402C44.6072 33.8407 44.7058 33.9034 44.8909 33.9034C45.7791 33.8971 46.6673 33.8971 47.5555 33.9034C47.7529 33.9034 47.8269 33.8156 47.8207 33.6276C47.8146 33.5086 47.8207 33.3895 47.8207 33.2704C47.8207 31.4155 47.8207 29.5606 47.8207 27.7058C47.8207 27.5679 47.8269 27.4363 47.8207 27.2984C47.8207 27.123 47.9009 27.054 48.0675 27.0603C48.3512 27.0666 48.6411 27.0854 48.9187 27.054C49.6773 26.9788 50.1708 27.3235 50.5717 27.9627C51.6511 29.686 52.7799 31.3717 53.8593 33.095C54.2232 33.6715 54.692 33.9347 55.3582 33.9096C56.0922 33.8846 56.8324 33.9034 57.5664 33.9034C57.6897 33.9034 57.8254 33.9347 57.9735 33.8407C57.8748 33.6652 57.7822 33.5086 57.6774 33.3582ZM51.7005 24.3782C51.2379 24.5787 50.7506 24.7103 50.2386 24.7166C49.554 24.7229 48.8632 24.7354 48.1785 24.7291C47.8269 24.7291 47.8207 24.7166 47.8146 24.3532C47.8084 23.5009 47.8146 22.6549 47.8146 21.8027C47.8146 20.988 47.8084 20.1734 47.8146 19.3525C47.8146 18.9451 47.8207 18.9326 48.1908 18.9326C49.2086 18.9451 50.2325 18.8511 51.244 19.0767C52.5332 19.365 53.2363 20.142 53.3474 21.3953C53.4707 22.7614 52.8478 23.8894 51.7005 24.3782Z"
            fill={fill}
         />
         <path
            d="M117.24 33.334C116.493 31.3851 115.741 29.4425 114.988 27.4936C113.625 23.9655 112.262 20.4374 110.893 16.9094C110.689 16.383 110.782 16.4331 110.22 16.4269C109.449 16.4206 108.678 16.4269 107.907 16.4269C107.432 16.4269 107.432 16.4269 107.26 16.8718C105.126 22.3926 102.991 27.9134 100.857 33.4342C100.802 33.5721 100.703 33.7037 100.765 33.898C101.591 33.898 102.43 33.898 103.269 33.898C103.479 33.898 103.651 33.804 103.824 33.6786C104.083 33.4906 104.207 33.2149 104.311 32.9267C104.669 31.9365 105.027 30.9464 105.378 29.9563C105.459 29.7245 105.557 29.5929 105.835 29.5991C107.994 29.6117 110.159 29.6117 112.318 29.5991C112.558 29.5991 112.675 29.6806 112.756 29.9124C113.027 30.6958 113.317 31.4791 113.594 32.2624C113.705 32.5632 113.804 32.8765 113.934 33.1648C114.1 33.5408 114.359 33.8604 114.779 33.8792C115.642 33.9105 116.512 33.8917 117.382 33.8917C117.419 33.6598 117.308 33.5032 117.24 33.334ZM111.756 27.2742C109.98 27.2742 108.216 27.2742 106.458 27.2742C106.384 27.1113 106.47 26.9922 106.513 26.8669C107.192 24.9681 107.87 23.0694 108.549 21.1769C108.722 20.7006 108.87 20.2118 108.987 19.7168C109.042 19.6416 109.098 19.6416 109.147 19.7168C109.647 21.4464 110.344 23.1007 110.924 24.7989C111.146 25.4569 111.392 26.1087 111.627 26.7604C111.682 26.917 111.769 27.0674 111.756 27.2742Z"
            fill={fill}
         />
         <path
            d="M109.15 19.7169C109.093 19.7169 109.037 19.7169 108.987 19.7169C108.974 19.6475 108.987 19.5781 109.068 19.5844C109.143 19.5844 109.162 19.6475 109.15 19.7169Z"
            fill={fill}
         />
         <path
            d="M93.8428 44.8832C93.7133 43.4983 93.2383 42.2325 92.3378 41.1734C91.1843 39.8198 89.6793 39.1368 87.9399 38.9989C86.1696 38.8548 84.3932 38.9613 82.6168 38.9425C82.1234 38.9363 82.1234 38.9488 82.1172 39.4439C82.1172 39.5441 82.1172 39.6506 82.1172 39.7509C82.1172 41.7249 82.1172 43.6988 82.1172 45.6665C82.1172 47.7596 82.1172 49.8526 82.1172 51.9393C82.1172 52.3843 82.1234 52.3905 82.5551 52.3905C84.1095 52.3905 85.67 52.3905 87.2244 52.3905C87.6438 52.3905 88.0633 52.3592 88.4765 52.284C90.2468 51.9644 91.7333 51.1623 92.751 49.6082C93.6886 48.1732 94.0032 46.5752 93.8428 44.8832ZM91.0548 47.6342C90.5305 49.2385 89.4141 50.1534 87.7487 50.3539C86.8296 50.4667 85.9106 50.3915 84.9854 50.404C84.6091 50.4103 84.6029 50.3915 84.5968 49.9905C84.5906 49.1883 84.5968 48.3925 84.5968 47.5904C84.5968 46.9449 84.5968 46.2995 84.5968 45.654C84.5968 44.3443 84.5968 43.0346 84.5968 41.7249C84.5968 41.6246 84.5968 41.5181 84.5968 41.4178C84.6029 40.9353 84.6029 40.9353 85.0594 40.929C85.7625 40.929 86.4657 40.929 87.1689 40.929C87.4526 40.929 87.7363 40.9478 88.0201 40.998C89.5744 41.28 90.5737 42.2137 91.061 43.7051C91.4804 45.0148 91.4804 46.3371 91.0548 47.6342Z"
            fill={fill}
         />
         <path
            d="M80.3546 51.8889C79.6391 50.8173 78.9359 49.7458 78.2081 48.6804C77.7331 47.9849 77.3445 47.2266 76.629 46.6376C76.7771 46.5749 76.8388 46.5498 76.9004 46.5248C76.9806 46.4934 77.0546 46.4621 77.1348 46.4245C78.4548 45.8166 79.2752 44.8077 79.4602 43.3351C79.6452 41.875 79.1703 40.277 77.4371 39.5C76.8017 39.2117 76.1418 39.055 75.4509 38.9986C74.0816 38.8796 72.7061 38.9548 71.3306 38.936C71.0962 38.9297 70.8618 38.9422 70.6275 38.936C70.4424 38.936 70.3437 39.0112 70.3561 39.2117C70.3622 39.3308 70.3561 39.4498 70.3561 39.5689C70.3561 43.6359 70.3561 47.7029 70.3561 51.7636C70.3561 51.8639 70.3622 51.9704 70.3561 52.0706C70.3314 52.315 70.4362 52.3965 70.6768 52.3965C71.1764 52.384 71.6822 52.3965 72.1818 52.3902C72.8541 52.384 72.8048 52.4842 72.8048 51.7698C72.8048 50.3912 72.8048 49.0126 72.8048 47.6339C72.8048 47.1451 72.811 47.1451 73.2612 47.1326C74.4702 47.1075 74.4702 47.1075 75.1302 48.1353C75.2104 48.2606 75.2967 48.3922 75.3769 48.5175C76.0739 49.5954 76.7771 50.6795 77.4679 51.7636C77.6591 52.0581 77.8688 52.3464 78.2327 52.3589C79.0099 52.384 79.7871 52.3652 80.6321 52.3652C80.5211 52.1709 80.4471 52.0268 80.3546 51.8889ZM75.821 45.0521C75.4139 45.2213 74.9945 45.3278 74.5504 45.3341C74.1124 45.3341 73.6807 45.3404 73.2427 45.3404C72.8171 45.3404 72.811 45.3341 72.811 44.8767C72.8048 44.2813 72.811 43.686 72.811 43.0907C72.811 42.4766 72.8048 41.8687 72.811 41.2546C72.8171 40.8911 72.8295 40.8723 73.1811 40.8723C73.8534 40.8723 74.5195 40.841 75.1857 40.935C75.4879 40.9789 75.7778 41.0603 76.0492 41.1857C76.3453 41.3235 76.5859 41.5303 76.7647 41.8061C77.326 42.6646 77.2335 44.4693 75.821 45.0521Z"
            fill={fill}
         />
         <path
            d="M68.7135 51.8326C67.7142 49.2382 66.7088 46.6439 65.7034 44.0495C65.1051 42.5017 64.5068 40.9476 63.9023 39.3997C63.705 38.8921 63.7851 38.936 63.2177 38.936C62.7181 38.936 62.2123 38.936 61.7127 38.936C61.1575 38.936 61.2377 38.8984 61.0342 39.4123C59.4305 43.5545 57.8267 47.7029 56.2292 51.8514C56.1675 52.0018 56.0627 52.1522 56.1058 52.359C56.6301 52.4216 57.1606 52.384 57.6911 52.384C58.3757 52.3903 58.5793 52.2462 58.8136 51.6007C59.0665 50.9176 59.3194 50.2346 59.56 49.5453C59.7512 49.0189 59.7204 49.0878 60.1891 49.0878C61.6942 49.0815 63.2053 49.0878 64.7104 49.0878C65.2717 49.0878 65.1853 49.0502 65.3765 49.5703C65.6356 50.2722 65.8823 50.974 66.1352 51.6759C66.2586 52.008 66.4436 52.3339 66.8137 52.359C67.486 52.4028 68.1645 52.3715 68.8862 52.3715C68.8245 52.1459 68.7751 51.9892 68.7135 51.8326ZM60.4605 47.2768C60.4482 47.2204 60.4297 47.1891 60.442 47.1577C61.0835 45.2464 61.8237 43.3727 62.459 41.38C63.1683 43.3665 63.8592 45.2966 64.5685 47.2768C63.1498 47.2768 61.8052 47.2768 60.4605 47.2768Z"
            fill={fill}
         />
         <path
            d="M63.8916 35.3079C63.3118 34.7063 62.2941 34.5684 61.5169 34.982C60.9556 35.2828 60.6349 35.7591 60.5917 36.3982C60.5239 37.4385 61.3689 38.2657 62.5408 38.2531C62.6765 38.2343 62.8801 38.2155 63.0713 38.1654C64.3727 37.827 64.8169 36.2666 63.8916 35.3079ZM62.4791 37.3445C61.9734 37.3382 61.6835 37.0249 61.6896 36.4985C61.6958 35.9847 62.0104 35.6588 62.4976 35.6651C62.9664 35.6713 63.3118 36.0348 63.3057 36.5173C63.3057 37.0061 62.9603 37.3508 62.4791 37.3445Z"
            fill={fill}
         />
         <path
            d="M154.006 52.2273C154.006 52.1897 153.993 52.1521 153.962 52.1207C153.864 52.0142 153.802 51.8951 153.734 51.7886C152.84 50.4538 151.945 49.1128 151.057 47.778C150.823 47.4271 150.595 47.0824 150.255 46.8067C150.169 46.7378 150.181 46.5936 150.286 46.5498C150.342 46.5247 150.403 46.5122 150.453 46.4871C152.217 45.7226 153.08 44.2938 152.914 42.3637C152.815 41.1793 152.26 40.2769 151.23 39.6879C150.514 39.2806 149.743 39.0738 148.935 39.0048C147.363 38.8732 145.79 38.9547 144.211 38.9422C143.828 38.9359 143.822 38.961 143.804 39.3432C143.797 39.431 143.804 39.5124 143.804 39.6002C143.804 43.6484 143.804 47.6965 143.804 51.751C143.804 51.8701 143.81 51.9891 143.804 52.1082C143.791 52.3338 143.896 52.4027 144.106 52.3965C144.692 52.3902 145.278 52.3965 145.864 52.3902C146.246 52.3839 146.258 52.3839 146.258 51.9954C146.265 50.6168 146.252 49.2381 146.265 47.8595C146.271 47.0574 146.141 47.1764 146.992 47.1263C147.683 47.0824 148.115 47.3644 148.467 47.9472C149.231 49.1943 150.058 50.41 150.835 51.6507C151.131 52.127 151.649 52.409 152.205 52.4027C152.753 52.3902 153.302 52.3902 153.851 52.3902C153.938 52.3839 154.006 52.315 154.006 52.2273ZM149.38 45.0019C148.972 45.2087 148.534 45.3215 148.072 45.3341C147.585 45.3466 147.103 45.3466 146.616 45.3403C146.271 45.3341 146.265 45.3278 146.265 44.9581C146.258 44.3439 146.265 43.7361 146.265 43.122C146.265 42.4953 146.271 41.8624 146.258 41.2357C146.252 40.9788 146.326 40.866 146.598 40.8723C147.282 40.8911 147.973 40.8409 148.652 40.9475C149.244 41.0415 149.793 41.2357 150.163 41.7496C150.903 42.7773 150.502 44.4379 149.38 45.0019Z"
            fill={fill}
         />
         <path
            d="M167.36 51.871C166.213 48.8943 165.06 45.9177 163.912 42.9474C163.444 41.7317 162.969 40.5097 162.5 39.294C162.42 39.0809 162.346 38.9243 162.05 38.9431C161.421 38.9869 160.785 38.9807 160.156 38.9431C159.934 38.9305 159.817 39.012 159.755 39.2063C159.724 39.3003 159.681 39.3943 159.644 39.4945C158.065 43.5803 156.486 47.6598 154.907 51.7394C154.87 51.8396 154.845 51.9524 154.796 52.0527C154.747 52.1592 154.716 52.272 154.716 52.3911C155.259 52.3911 155.808 52.3973 156.35 52.3973C157.017 52.3973 157.22 52.2532 157.461 51.5952C157.701 50.9435 157.929 50.2855 158.176 49.6338C158.398 49.0322 158.281 49.1011 158.904 49.1011C160.378 49.0949 161.852 49.1011 163.327 49.1011C163.943 49.1011 163.832 49.0635 164.03 49.6025C164.295 50.298 164.529 51.0062 164.795 51.708C164.924 52.059 165.134 52.366 165.535 52.3848C166.182 52.4099 166.836 52.3911 167.49 52.3911C167.521 52.1718 167.422 52.0214 167.36 51.871ZM159.046 47.2462C159.73 45.3099 160.415 43.386 161.106 41.4246C161.809 43.386 162.494 45.2973 163.178 47.2086C162.808 47.334 159.644 47.3653 159.046 47.2462Z"
            fill={fill}
         />
         <path
            d="M177.828 13.118H178.408C178.494 13.118 178.568 13.1305 178.636 13.1556C178.704 13.1807 178.753 13.2182 178.797 13.2558C178.84 13.2997 178.871 13.3498 178.895 13.4062C178.92 13.4626 178.926 13.5253 178.926 13.5942C178.926 13.6569 178.92 13.7133 178.901 13.7572C178.883 13.8073 178.858 13.8449 178.834 13.8825C178.803 13.9201 178.772 13.9452 178.741 13.9702C178.704 13.9953 178.673 14.0141 178.642 14.0266L179.012 14.6533H178.636L178.346 14.083H178.167V14.6533H177.84V13.118H177.828ZM178.155 13.8386H178.285C178.371 13.8386 178.445 13.8198 178.5 13.7822C178.556 13.7446 178.587 13.6882 178.587 13.6005C178.587 13.5253 178.562 13.4689 178.519 13.4313C178.476 13.3937 178.408 13.3686 178.322 13.3686H178.155V13.8386Z"
            fill={fill}
         />
         <path
            d="M178.365 15.5492C177.464 15.5492 176.73 14.8035 176.73 13.8885C176.73 12.9736 177.464 12.2279 178.365 12.2279C179.266 12.2279 180 12.9736 180 13.8885C180 14.8035 179.272 15.5492 178.365 15.5492ZM178.365 12.5914C177.656 12.5914 177.082 13.1742 177.082 13.8948C177.082 14.6155 177.656 15.1982 178.365 15.1982C179.074 15.1982 179.648 14.6155 179.648 13.8948C179.648 13.1742 179.074 12.5914 178.365 12.5914Z"
            fill={fill}
         />
      </svg>
   )
}

PopupAppLogo.defaultProps = {
   fill: "#059483"
}
