import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";

import { Routing } from "../routing/Routing";
import Menu from "../components/Menu";
import PopupDownloadApp from '../components/PopupDownloadApp';

export const MainView: React.FC = () => {
  return (
    <Router>
      <Menu />
      <Routing/>
      <PopupDownloadApp />
    </Router>
  );
};
