import { format } from 'date-fns';
import { sv } from "date-fns/locale";

export default function(
  date: Date | number,
  dateFormat: string,
  options?: {
    locale?: Locale
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
    firstWeekContainsDate?: number
    useAdditionalWeekYearTokens?: boolean
    useAdditionalDayOfYearTokens?: boolean
  }
) {
  return format(date, dateFormat, { locale: sv, ...options });
}