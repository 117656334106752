import { createAsyncAction, createAction } from "typesafe-actions";

// Types
import { StateTargetsState } from "./model";
import { IStateWithTarget } from "./../model";
import { IChat, IMessage, ICreationMessage } from "./../../API/model";

/* Actions */
const widgetName = "chat";

export const fetchUserChatsAction = createAsyncAction(
  `${widgetName}/FETCH_CHATS_REQUEST`,
  `${widgetName}/FETCH_CHATS_SUCCESS`,
  `${widgetName}/FETCH_CHATS_FILED`
)<undefined, { chats: IChat[] }, undefined>();

export const fetchChatMessagesAction = createAsyncAction(
  `${widgetName}/FETCH_MESSAGES_REQUEST`,
  `${widgetName}/FETCH_MESSAGES_SUCCESS`,
  `${widgetName}/FETCH_MESSAGES_FILED`
)<{ chatId: number }, { chatId: number; messages: IMessage[] }, undefined>();

export const fetchAdditionalMessagesAction = createAsyncAction(
  `${widgetName}/FETCH_ADDITIONAL_MESSAGE_REQUEST`,
  `${widgetName}/FETCH_ADDITIONAL_MESSAGE_SUCCESS`,
  `${widgetName}/FETCH_ADDITIONAL_MESSAGE_FILED`
)<
  { chatId: number },
  { chatId: number; messages: IMessage[]; isAllUploaded: boolean },
  undefined
>();

export const setChatStateAction = createAction(`${widgetName}/SET_CHAT_STATE`)<
  IStateWithTarget<StateTargetsState>
>();

export const chatSocketConnectedAction = createAction(
  `${widgetName}/CHAT_SOCKET_CONNECTED`
)();
export const chatSocketDisconnectedAction = createAction(
  `${widgetName}/CHAT_SOCKET_DISCONNECTED`
)();

export const chatSocketNewMessageAction = createAction(
  `${widgetName}/CHAT_SOCKET_NEW_MESSAGE`
)<{ message: IMessage }>();

export const chatSocketNewChatAction = createAction(
  `${widgetName}/CHAT_SOCKET_NEW_CHAT`
)<{ chat: IChat }>();

export const chatSocketDeleteChatAction = createAction(
  `${widgetName}/CHAT_SOCKET_DELETE_CHAT`
)<{ chatId: number }>();

export const readMessagesInChatAction = createAsyncAction(
  `${widgetName}/READ_MESSAGES_REQUEST`,
  `${widgetName}/READ_MESSAGES_SUCCESS`,
  `${widgetName}/READ_MESSAGES_FILED`
)<{ chatId: number }, { chatId: number }, undefined>();

export const addMessageAction = createAsyncAction(
  `${widgetName}/ADD_MESSAGE_REQUEST`,
  `${widgetName}/ADD_MESSAGE_SUCCESS`,
  `${widgetName}/ADD_MESSAGE_FILED`
)<
  { chatId: number; message: ICreationMessage, filedCB?: (message: string) => void },
  { chatId: number; message: IMessage; tempMessageId: number | string },
  { chatId: number; message: ICreationMessage }
>();
