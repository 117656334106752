import React from 'react';
import { EventTimer } from './EventTimer';

// constants
interface IVideoCallHeaderProps {
  name: string;
  startDate: Date;
  endDate: Date;
}

export const VideoCallHeader: React.FC<IVideoCallHeaderProps> = props => {
  const { name } = props;
  return (
    <div className="videocall-topper">
      <span className="doctor-name text-bold">{name}</span>
      <EventTimer startDate={props.startDate} endDate={props.endDate} />
    </div>
  );
};