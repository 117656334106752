import React from "react";
import { useLocation } from 'react-router-dom'
import {getTotalUnreadedChatsNumber, getTotalUnreadedEventsNumber} from "../store";

import schema from "../routing";
import Link from "../routing/Link"
import {useSelector} from "react-redux";
export const Menu: React.FC <{}> = () =>  {
  const location = useLocation();
  const unreadMessages = useSelector(getTotalUnreadedChatsNumber)
  const unreadEvents = useSelector(getTotalUnreadedEventsNumber)
  let nameRoute = schema.getName(location.pathname);
  let activeLinePos: number | null = null;
  const { menu } = schema;
  if (nameRoute) {
    menu.forEach((item, index)=> {
      if(item.name === nameRoute){
        activeLinePos = index
      }
    });
  }
  let width: number = 100 / menu.length;
  return activeLinePos !== null ? (
      <>
      <div className={"tab-links tab-links-main"}>
        {menu.map((menuItem, index) => {
          const badgeNumber = menuItem.name === "conversations" ? unreadMessages : (menuItem.name === "events" ? unreadEvents : 0)
          return(
          <Link to={menuItem.name} className={"tab-item" + (index === activeLinePos ? " active" : "")} key={menuItem.name}>
            <div >
              {menuItem.icon && menuItem.icon({
                className: "tab-icon",
                fillColor: index === activeLinePos ? "#059483" : "#889199"
              })}
              {badgeNumber ? <div className="tab-indicator">
                <span className="text-bold">{badgeNumber}</span>
              </div> : ""}
            </div>
            <div className="tab-signature">
              {menuItem.title}
            </div>
          </Link>
        )})}
        <div className="wrapper-active-line" style={{left: activeLinePos * width + "%", width: width + "%"}}>
          <div className="active-line"/>
        </div>
      </div>
    </>) : null;
}
export default Menu
