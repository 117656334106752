import firebase from 'firebase';

export const initializeFirebase = () => {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  }



  firebase.initializeApp(firebaseConfig);
  //@ts-ignore
  window.firebaseConfig = firebaseConfig;

  try{
    navigator.serviceWorker
      .register('/firebase-messaging-sw.js?messagingSenderId='
        + encodeURIComponent(process.env.REACT_APP_MESSAGING_SENDER_ID || "")
        + '&apiKey=' + encodeURIComponent(process.env.REACT_APP_API_KEY || "")
        + '&projectId=' + encodeURIComponent(process.env.REACT_APP_PROJECT_ID || "")
        + '&appId=' + encodeURIComponent(process.env.REACT_APP_APP_ID || ""))
      .then((registration) => {
        firebase.messaging().useServiceWorker(registration);
      });
  } catch (e) {
    console.log('error: ', e);
  }
}