import React from 'react';

// Icons
import { UserSecondaryIcon } from '../assets/img/icons/User';
import { PencilIcon } from '../assets/img/icons/Pencil';

// constants
import {maxImageFileSize} from "../constants";

interface IAvatarFieldProps {
  selectedAvatar: string | null;
  setAvatarFile: React.Dispatch<
    React.SetStateAction< File | null>
  >;
  setSelectedAvatar: React.Dispatch<
    React.SetStateAction< string | null>
    >;
  setError: React.Dispatch<
    React.SetStateAction< string >
    >;
}

export const AvatarField: React.FC<IAvatarFieldProps> = props => {
  const { setAvatarFile, selectedAvatar, setSelectedAvatar, setError } = props;

  const pickUserImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    let array: FileList | undefined;
    if (e.target.files) {
      array = e.target.files;
    }

    try {
      if (array) {
        const res = array[0];
        const reader  = new FileReader();
        reader.onloadend = function () {
          let file: any = res;
          setSelectedAvatar(String(reader.result));
          if (res.size > maxImageFileSize) {
            setError('Filen är för stor')
            return;
          }
          setAvatarFile(file);
        }
        reader.readAsDataURL(res);
      }
    } catch (error) {
      process.env.REACT_APP_SHOW_LOGS === "true" && console.log(error);
    }
  };

  return (
    <>
      {selectedAvatar ? (
        <img alt={"user-avatar"} className="photo" src={selectedAvatar}/>
      ) : (
        <UserSecondaryIcon withoutBorder={true} className="photo"/>
      )}

      <label className="btn-user-change">
        <input type="file" onChange={pickUserImage}/>
        <PencilIcon className="pencil"/>
      </label>

    </>
  );
};
