import { all } from "redux-saga/effects";
import { createReducer, ActionType } from "typesafe-actions";

// Types
import { IStore, IState, IAuthData } from "./../model";
import { IUserInfo } from "../../API/model";

// Handlers
import { authSaga } from "./handlers/auth";
import { updateSaga } from "./handlers/update";

// API
import { DevicePlatformEnum } from "../../API/config";

// Actions
import * as actions from "./actions";

export * from "./actions";

export type UserActionType = ActionType<typeof actions>;

export const userSaga = function* () {
  yield all([authSaga(), updateSaga()]);
};

export interface IUserState {
  isAuthenticated: boolean;
  authenticationToken: string | null;
  FCMToken?: string;
  platform: DevicePlatformEnum | null;
  userData: IUserInfo | null;
  authData: IAuthData | null;
  isAuthPollingPaused: boolean;
  state: IState | null;
}

/* Reducer */
const initialState: IUserState = {
  isAuthenticated: false,
  isAuthPollingPaused: false,
  authenticationToken: null,
  platform: null,
  userData: null,
  authData: null,
  state: null,
};

export const userReducer = createReducer<IUserState, UserActionType>(
  initialState
)
  .handleAction(actions.setUserStateAction, (store, { payload }) => ({
    ...store,
    state: payload,
  }))
  .handleAction(actions.setUserDataAction, (store, { payload }) => ({
    ...store,
    isAuthenticated: true,
    userData: payload.userData,
    authData: payload.authData,
    platform: payload.platform,
    FCMToken: payload.FCMToken,
  }))
  .handleAction(actions.updateProfileAction.success, (store, { payload }) => ({
    ...store,
    userData: payload.updatedUserData,
  }))
  .handleAction(actions.logoutAction.success, () => ({ ...initialState }))
  .handleAction(actions.resumePolling as any, (state) => ({
    ...state,
    isAuthPollingPaused: false,
  }))
  .handleAction(actions.pausePolling as any, (state) => ({
    ...state,
    isAuthPollingPaused: true,
  }));

/* Selectors */
export const getUserState = (state: IStore) => state.user.state;
export const getAuthStatus = (state: IStore) => state.user.isAuthenticated;
export const getUserAttributes = (state: IStore) =>
  state.user.userData?.attributes;
export const getAuthData = (state: IStore) => state.user.authData;
export const getAuthToken = (state: IStore) => state.user.authData?.accessToken;
export const getUserId = (state: IStore) => state.user.userData?.id;
export const getPollingState = (state: IStore) =>
  state.user.isAuthPollingPaused;
export const getDeviceCreds = (state: IStore) => ({
  platform: state.user.platform,
  FCMToken: state.user.FCMToken,
});
