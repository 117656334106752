import {
  differenceInMinutes,
  differenceInHours,
  differenceInSeconds,
} from 'date-fns';

const formatWithZero = (value: number) => (value < 10 ? '0' + value : value);
export function calculateTimeDifference(startDate: Date, endDate: Date) {
  const hoursDifference = differenceInHours(startDate, endDate);
  const minutesDifference = differenceInMinutes(startDate, endDate);

  if (hoursDifference < 0 || minutesDifference < 0) {
    return null;
  }

  const secondsDifference = differenceInSeconds(startDate, endDate);

  const hours = formatWithZero(hoursDifference);
  const minutes = formatWithZero(minutesDifference - hoursDifference * 60);
  const seconds = formatWithZero(secondsDifference - minutesDifference * 60);

  return `${hours}:${minutes}:${seconds}`;
}
