import React from 'react';
import { useFormikContext } from 'formik';
import MaskedInput from "react-text-mask";

// constants
type FieldType = 'email' | 'phone' | 'text';
interface ITextFieldProps {
  labelText: string;
  required?: boolean;
  type?: FieldType;
  name: string;
}

type ValueOf<T> = T[keyof T];
const FieldTypes: Record<
  FieldType,
  string
> = {
  text: 'default',
  phone: 'phone-pad',
  email: 'email-address',
};

export const TextField: React.FC<ITextFieldProps> = props => {
  const { labelText, required, type, name } = props;

  const [isFocused, setFocusedStatus] = React.useState(false);
  const formik = useFormikContext<any>();
  const value = formik?.values[name];
  const meta = formik?.getFieldMeta(name);

  if (!formik) return null;

  const isInvalid = !!meta.error && !!meta.touched;

  const inputProps = {
    onFocus() {
      setFocusedStatus(true);
    },
    onBlur(event:any) {
      setFocusedStatus(false);
      formik.handleBlur(name)(event);
    },
  };
  process.env.REACT_APP_SHOW_LOGS === "true" && console.log("error: ", meta.error);
  return (
    <div className={"form-group" + (isInvalid ? " error" : '')}>
      <label htmlFor="email" className="label text text-bold">{labelText} {required && <span>*</span>}</label>

      {type === 'phone' ? (
        <>
          <MaskedInput
            mask={['+', /[1-9]/, /\d/, " ", /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/]}
            {...inputProps}
            type={"tel"}
            placeholder={'+46 000 000 000'}
            onBlur={formik.handleBlur(name)}
            onChange={(event) => formik.handleChange(name)(event.target.value || "")}
            name={name}
            value={value}
            className="input input-phone"
            id="phone"
          />
        </>
      ) : (
        <input
          {...inputProps}
          type="email"
          className={"input" + (isInvalid ? " error" : '')}
          id="email"
          value={value}
          onChange={(event) => formik.handleChange(name)(event.target.value || "")}
        />
      )}
      {isInvalid && <span className={"error-message"}>{meta.error}</span>}
    </div>
  );
};
