import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";
import { rootReducer, rootSaga } from "./Controllers";

const sagaMiddleware = createSagaMiddleware();
let middlewares = composeWithDevTools(applyMiddleware(logger, sagaMiddleware));

export const store = createStore(rootReducer, middlewares);

sagaMiddleware.run(rootSaga);
