import React from 'react';

interface IClockIconProps {
  style?: React.CSSProperties;
  fillColor?: string;
}

export const ClockIcon: React.FC<IClockIconProps> = props => {
  const { style, fillColor } = props;

  return (
    <svg style={style} viewBox="0 0 12 12">
      <path
        d="M11.6494 5.64843C11.4552 5.64843 11.2978 5.80583 11.2978 5.99999C11.2978 8.92068 8.92163 11.2968 6.00096 11.2968C3.08027 11.2968 0.704121 8.92068 0.704121 5.99999C0.704121 3.07929 3.08027 0.703143 6.00096 0.703143C6.89156 0.703143 7.75788 0.924275 8.53124 1.34563L8.09172 1.78515C7.99117 1.8857 7.9611 2.03689 8.0155 2.16828C8.06992 2.29965 8.1981 2.38531 8.34029 2.38531H10.0225C10.2166 2.38531 10.374 2.22791 10.374 2.03375V0.351582C10.374 0.209388 10.2884 0.081209 10.157 0.0267875C10.0256 -0.0276575 9.87439 0.00243605 9.77386 0.103006L9.04691 0.829963C8.12608 0.286286 7.07913 4.54302e-05 6.00094 4.54302e-05C4.39829 4.54302e-05 2.89155 0.624159 1.75831 1.75738C0.625067 2.89065 0.000976562 4.39736 0.000976562 6.00001C0.000976562 7.60266 0.625067 9.10938 1.75831 10.2426C2.89158 11.3759 4.39829 12 6.00094 12C7.60359 12 9.1103 11.3759 10.2435 10.2426C11.3768 9.10938 12.0009 7.60266 12.0009 6.00001C12.0009 5.80583 11.8435 5.64843 11.6494 5.64843Z"
        fill={fillColor || "#F5C004"}
      />
      
      <path
        d="M8.25934 5.6484H6.35156V3.7406C6.35156 3.54644 6.19415 3.38904 6 3.38904C5.80584 3.38904 5.64844 3.54644 5.64844 3.7406V5.99996C5.64844 6.19412 5.80584 6.35152 6 6.35152H8.25934C8.45349 6.35152 8.6109 6.19412 8.6109 5.99996C8.6109 5.80581 8.45349 5.6484 8.25934 5.6484Z"
        fill={fillColor || "#F5C004"}
      />
    </svg>
  );
}