import React, { ComponentType, ComponentProps } from "react";
import { RouteComponentProps } from "react-router-dom";

// Components
import { ChatsList } from "../views/Messages/ChatsList";
import { Chat } from "../views/Messages/Chat";
import { CreateMessage } from "../views/Messages/CreateMessage";
import { InfoAccount } from "../views/Account/InfoAccount";
import { EditAccount } from "../views/Account/EditAccount";
import { EventsList } from "../views/Events/EventsList";
import { EventInfo } from "../views/Events/EventInfo";
import { Event } from "../views/Events/Event";
import { TabsWrapper } from "../components/Tabs";

// Icons
import { IMessagesIconProps, MessagesIcon } from "../assets/img/icons/Messages";
import { CalendarIcon, ICalendarIconProps } from "../assets/img/icons/Calendar";
import { IUserPrimaryIconProps, UserPrimaryIcon } from "../assets/img/icons/User";

interface IRoute {
  readonly name: Pages;
  readonly path: string;
  readonly component: ComponentType<
    RouteComponentProps<any> & ComponentProps<"div">>;
  readonly wrapper?: ComponentType;
}

type IconType = React.FC<IMessagesIconProps | ICalendarIconProps | IUserPrimaryIconProps>;

interface IMenuItem {
  title: string,
  name: Pages,
  icon?: IconType
}

export type Pages =
  | "conversations"
  | "conversation"
  | "newMessage"

  | "account"
  | "accountEdit"

  | "events"
  | "event"
  | "event-call"

  | "notFound";
class RoutingSchema {
  private schema: IRoute[] = [
    {
      name: "conversations",
      path: "/conversations",
      component: ChatsList,
      wrapper: TabsWrapper
    },
    {
      name: "conversation",
      path: "/conversation/:id",
      component: Chat
    },
    {
      name: "newMessage",
      path: "/conversation/:id/new",
      component: CreateMessage
    },
    {
      name: "account",
      path: "/account",
      component: InfoAccount,
      wrapper: TabsWrapper
    },
    {
      name: "accountEdit",
      path: "/account/edit",
      component: EditAccount
    },
    {
      name: "events",
      path: "/events",
      component: EventsList,
      wrapper: TabsWrapper
    },
    {
      name: "event",
      path: "/event/:id",
      component: EventInfo
    },
    {
      name: "event-call",
      path: "/event/:id/call",
      component: Event
    },
    // {
    //   name: "survey",
    //   path: "/survey",
    //   component: SurveyView,
    //   wrapper: TabsWrapper
    // },
    // {
    //   name: "quiz",
    //   path: "/survey/:id",
    //   component: QuizView,
    //   wrapper: TabsWrapper
    // },
  ];
  public menu: IMenuItem[] = [
    {
      title: "Meddelanden",
      name: "conversations",
      icon: MessagesIcon,
    },
    {
      title: "Händelser",
      name: "events",
      icon: CalendarIcon,
    },
    {
      title: "Konto",
      name: "account",
      icon: UserPrimaryIcon
    }
  ]
  private findRouteByPath(path: string): IRoute | undefined {
    return this.schema.find(({ path: routePath }) => routePath === path);
  }
  private findRouteByName(name: Pages): IRoute | undefined {
    return this.schema.find(({ name: routeName }) => routeName === name);
  }

  public get getSchema() {
    return this.schema;
  }

  public getLink(name: Pages): string {
    const route = this.findRouteByName(name);

    if (route && route.path) {
      return route.path;
    } else {
      return "/error";
    }
  }
  public getName(path: string): string | boolean {
    const route = this.findRouteByPath(path);

    if (route && route.path) {
      return route.name;
    } else {
      return false;
    }
  }
}

export default new RoutingSchema();
