//Node_modules_components
import React, {Component} from "react";

interface IProps {
  className?: string;
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

interface IState {
}

class AutoResizeTextarea extends Component <IProps, IState> {
  textareaRef = React.createRef<HTMLTextAreaElement>();

  componentDidMount(): void {
    setTimeout(() => {
      this.forceUpdate()
    }, 100)
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    if (this.textareaRef.current) {
      this.resizeTextarea({currentTarget: this.textareaRef.current});
    }
  }

  resizeTextarea = function (
    e: React.KeyboardEvent<HTMLTextAreaElement>
      | React.ClipboardEvent<HTMLTextAreaElement>
      | React.FormEvent<HTMLTextAreaElement>
      | { currentTarget: HTMLTextAreaElement }
  ) {
    if (e && e.currentTarget && e.currentTarget) {
      let offset = e.currentTarget.offsetHeight - e.currentTarget.clientHeight;

      e.currentTarget.style.height = "auto";
      e.currentTarget.style.height = e.currentTarget.scrollHeight + offset + "px";
    }
  };

  render() {
    return (
      <textarea
        {...this.props}
        ref={this.textareaRef}
        rows={2}
        onKeyUp={this.resizeTextarea}
        onKeyDown={this.resizeTextarea}
        onInput={this.resizeTextarea}
        onPaste={this.resizeTextarea}
        onCut={this.resizeTextarea}
      />
    );
  }
}


export default AutoResizeTextarea;