import io from "socket.io-client";
import { eventChannel } from "redux-saga";
import { take, call, put } from "redux-saga/effects";
import {AuthTypeEnum} from '../../../../models/models';

// API
import { IMessage, IChat } from "./../../../API/model";
import { Config, SocketEventEnum } from "./../../../API/config";

// Actions
import {
  chatSocketConnectedAction,
  chatSocketDisconnectedAction,
  chatSocketNewMessageAction,
  chatSocketNewChatAction,
  chatSocketDeleteChatAction,
} from "./../actions";
import { setUserDataAction } from "./../../User/actions";

export function initWebSocket(token: string) {
  return eventChannel((emitter) => {
    const socket = io(Config.WS_ENDPOINT_CHAT, {
      query: { [AuthTypeEnum.access]: token },
      transports: ["websocket"],
      forceNew: true,
    });

    socket.on("connect", () => {
      emitter(chatSocketConnectedAction());
    });

    socket.on("disconnect", () => {
      emitter(chatSocketDisconnectedAction());
    });

    socket.on(SocketEventEnum.NewMessage, (payload: IMessage) => {
      emitter(chatSocketNewMessageAction({ message: payload }));
    });

    socket.on(SocketEventEnum.NewChat, (payload: IChat) => {
      emitter(chatSocketNewChatAction({ chat: payload }));
    });

    socket.on(SocketEventEnum.DisableChat, (payload: IChat) => {
      emitter(chatSocketDeleteChatAction({ chatId: payload.id }));
    });

    return () => {
      emitter(chatSocketDisconnectedAction());
    };
  });
}

export function* listenChatSocketSaga() {
  while (true) {
    const { payload }: ReturnType<typeof setUserDataAction> = yield take(
      setUserDataAction
    );
    // @ts-ignore
    const chanel = yield call(initWebSocket, payload.authData.accessToken);

    while (true) {
      // @ts-ignore
      const action = yield take(chanel);
      yield put(action);
    }
  }
}
