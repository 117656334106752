import { createAsyncAction, createAction } from "typesafe-actions";

// Types
import { IEvent } from "./../../API/model";

/* Actions */
const widgetName = "events";

export const fetchEventsAction = createAsyncAction(
  `${widgetName}/FETCH_EVENTS_REQUEST`,
  `${widgetName}/FETCH_EVENTS_SUCCESS`,
  `${widgetName}/FETCH_EVENTS_FILED`
)<undefined, { events: IEvent[] }, undefined>();

export const setUnreadedNumber = createAction(
  `${widgetName}/SET_EVENTS_UNREADED_NUMBER`
)<number>();

export const eventsSocketConnectedAction = createAction(
  `${widgetName}/EVENTS_SOCKET_CONNECTED`
)();

export const eventsSocketDisconnectedAction = createAction(
  `${widgetName}/EVENTS_SOCKET_DISCONNECTED`
)();

export const eventsSocketNewItemAction = createAction(
  `${widgetName}/EVENTS_SOCKET_NEW_ITEM`
)<{ event: IEvent }>();

export const eventsSocketUpdateItemAction = createAction(
  `${widgetName}/EVENTS_SOCKET_UPDATE_ITEM`
)<{ event: IEvent }>();

export const readEventAction = createAsyncAction(
  `${widgetName}/READ_EVENT_REQUEST`,
  `${widgetName}/READ_EVENT_SUCCESS`,
  `${widgetName}/READ_EVENT_FILED`
)<{ eventId: number }, { eventId: number }, undefined>();