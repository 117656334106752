import React from 'react';
import { getUserAttributes, logoutAction, DevicePlatformEnum } from '../../store';
import { useSelector, useDispatch } from 'react-redux';
import Scrollbars from "react-custom-scrollbars";
import InternalLink from "../../routing/Link";
import {Link} from "react-router-dom";

// Components
import { UserImage } from "../../components/UserImage";

//Utils
import { personalNumberFormatting } from '../../utils/personalNumberFormatting';
import {getFCMToken} from "../../utils/FCMToken";

// constants
import { personalNumberKey, authenticationTokenKey, deviceTokenKey } from '../../constants/Keys';
import {CheckIcon} from "../../assets/img/icons/Check";
import {CloseIcon} from "../../assets/img/icons/Close";

export const InfoAccount: React.FC = () => {
  const attributes = useSelector(getUserAttributes);
  const dispatch = useDispatch();

  const logOutClick = async () => {
    localStorage.removeItem(personalNumberKey)
    localStorage.removeItem(authenticationTokenKey)
    localStorage.removeItem(deviceTokenKey)

    const FCMToken = await getFCMToken();

    dispatch(logoutAction.request({FCMToken: FCMToken, platform: DevicePlatformEnum.Web}));
  };

  return (
    <div className="tab-content-item tab-content-account">
      <Scrollbars>
        <div className="user-info">
          <div className="user-photo">
            <UserImage
              isPatient
              className="photo"
              uri={attributes?.profileImageUrl}
            />
          </div>

          <div className="user-name text">{attributes?.name}</div>
          <div className="user-code text">{personalNumberFormatting(attributes?.personalNumber!)}</div>
        </div>
        <div className="user-settings">
          <ul className="settings-links">
            <li className="settings-item">
              <InternalLink to="accountEdit" className="settings-link text">Redigera kontot</InternalLink>
            </li>
            <li className="settings-item">
              <a href="https://listning.riddarens.se/Home/Step1" target={"_blank"} rel="noopener noreferrer" className="settings-link text">Lista dig till Riddarens</a>
            </li>
            {/*<li className="settings-item">*/}
            {/*  <a href="https://riddaren.pvonline.se/vhcu/" target={"_blank"} rel="noopener noreferrer" className="settings-link text">Sök vård online</a>*/}
            {/*</li>*/}
            <li className="settings-item">
              <a href="https://riddarens.se/privacy-policy/" target={"_blank"} rel="noopener noreferrer" className="settings-link text">Integritetspolicy</a>
            </li>
            {/*<li className="settings-item">*/}
            {/*  <Link to="https://riddarens.se/privacy-policy/" className="settings-link text">Villkor</Link>*/}
            {/*</li>*/}
            <li className="settings-item">
              <span onClick={logOutClick} className="settings-link text settings-link-logout">Logga ut</span>
            </li>
          </ul>
        </div>
        {/*<div className="notification-wrap">*/}
        {/*  <div className="notification notification-succesful">*/}
        {/*    <CheckIcon className="notification-icon"/>*/}
        {/*    <span className="notification-text">Uppdateringen lyckades</span>*/}
        {/*    <button className="btn-close">*/}
        {/*      <CloseIcon className="notification-icon-close" fillColor={"#fff"}/>*/}
        {/*    </button>*/}
        {/*    </div>*/}

        {/*  <div className="notification notification-fail">*/}
        {/*    <img src="img/notification-fail.svg" alt="fail icon" className="notification-icon"/>*/}
        {/*    <span className="notification-text">Uppdatering misslyckades</span>*/}
        {/*    <button className="btn-close">*/}
        {/*      <img src="img/close-icon-white.svg" alt="close icon" className="notification-icon-close"/>*/}
        {/*    </button>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </Scrollbars>
    </div>
  );
};
