import React from 'react';

interface IPhoneIconProps {
  style?: React.CSSProperties;
  fillColor?: string;
  className?: string;
}

export const PhoneIcon: React.FC<IPhoneIconProps> = props => {
  const { style, fillColor, className } = props;

  return (
    <svg style={style} viewBox="0 0 49 49" className={className}>
      <path
        fill={fillColor || "white"}
        d="M6.16433 29.0918L14.3281 27.4604C15.3607 27.25 16.0586 26.2816 15.9313 25.2353L15.5237 21.9744C15.4653 21.5494 15.7284 21.146 16.141 21.0281L16.562 20.9063C17.9519 20.497 19.6817 19.9882 24.193 19.989C28.7043 19.9898 30.4362 20.4983 31.8245 20.9059L32.2447 21.0284C32.6592 21.1443 32.9251 21.5478 32.8681 21.9744L32.4612 25.2336C32.3343 26.2797 33.0317 27.248 34.0641 27.4591L42.2278 29.0905C43.2818 29.3028 44.3162 28.6484 44.5761 27.6051L45.3939 24.3269C45.6591 23.3051 45.5185 22.2203 45.0016 21.2999C43.0519 17.8753 37.8596 13.7926 24.1959 13.7918C13.3277 13.7922 7.82078 16.3765 5.04013 19.1572C4.39744 19.7944 3.84325 20.5151 3.3925 21.2999C2.87518 22.2202 2.73455 23.3051 3.00016 24.3269L3.81547 27.6051C4.07494 28.6491 5.10982 29.3041 6.16433 29.0918ZM4.92402 27.3241L4.10465 24.0474C3.91153 23.3114 4.01067 22.5291 4.38128 21.8643C6.18086 18.7026 11.0788 14.9329 24.1958 14.9329C37.3129 14.9329 42.2104 18.7023 44.0104 21.8644C44.3814 22.5299 44.4807 23.3132 44.2874 24.0503L43.4681 27.3269C43.3555 27.7797 42.9065 28.0638 42.4491 27.9717L38.0112 27.0822L34.2886 26.3379C33.8406 26.2465 33.5378 25.8265 33.5926 25.3725L33.9999 22.1129C34.1312 21.1282 33.5186 20.1969 32.5624 19.9278L32.1522 19.8088C30.6917 19.379 28.8752 18.8439 24.1974 18.8443C19.5195 18.8447 17.7034 19.3794 16.2433 19.8088L15.8325 19.9278C14.8763 20.1969 14.2636 21.1282 14.3949 22.1129L14.8022 25.3725C14.8569 25.8264 14.5541 26.2462 14.1062 26.3379L5.9429 27.9689C5.48549 28.0613 5.03634 27.7771 4.92402 27.3241Z"
      />
    </svg>
  );
}