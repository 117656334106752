import React from 'react';

interface IPencilIconProps {
  style?: React.CSSProperties;
  fillColor?: string;
  className?: string;
}

export const PencilIcon: React.FC<IPencilIconProps> = props => {
  const { style, fillColor, className } = props;

  return (
    <svg style={style} viewBox="0 0 21 20" className={className}>
      <path fill={fillColor || "#059483"} d="M19.2178 0.898601C17.9596 -0.299534 15.9198 -0.299534 14.6617 0.898601L1.88944 13.0616C1.80191 13.1449 1.73871 13.2483 1.70571 13.3617L0.0261242 19.1361C-0.0429498 19.3729 0.0272427 19.6264 0.209576 19.8003C0.392188 19.9739 0.658417 20.0408 0.907027 19.9753L6.97071 18.3755C7.08985 18.3441 7.19835 18.2839 7.28588 18.2006L20.0579 6.03734C21.314 4.83841 21.314 2.89753 20.0579 1.6986L19.2178 0.898601ZM3.44934 13.5047L13.9024 3.55L17.2736 6.76038L6.82026 16.7151L3.44934 13.5047ZM2.77594 14.7915L5.46926 17.3566L1.74374 18.3396L2.77594 14.7915ZM19.0455 5.0733L18.2863 5.79633L14.9148 2.58568L15.6743 1.86265C16.3732 1.19714 17.5063 1.19714 18.2052 1.86265L19.0455 2.66265C19.7432 3.32896 19.7432 4.40725 19.0455 5.0733Z" />
    </svg>
  );
}