import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { fetchChatMessagesAction, fetchUserChatsAction, addMessageAction, getAllMessagesUploadedStatus, getChatState, getUserId, getChatMessages, getChat } from '../../store';

// Constants
import { IFile, maxImageFileSize } from '../../constants';

//Routing
import schema from "../../routing/";

//Components
import AutoResizeTextarea from "../../components/AutoresizeTextarea";

// Icon
import { AttachmentIcon } from '../../assets/img/icons/Attachmen';
import { CloseIcon } from '../../assets/img/icons/Close';
import {BackArrowIcon} from "../../assets/img/icons/Arrow";

// Utils
import { uuid } from '../../utils/uuid';
import {generatePath, RouteComponentProps} from "react-router";
import Scrollbars from "react-custom-scrollbars";
import Link from "../../routing/Link";

interface ICreateMessageProps extends RouteComponentProps<{
  id: string;
}>  {}

export const CreateMessage: React.FC<ICreateMessageProps> = props => {
  const chatId = Number(props.match.params.id);
  const [messageText, setMessageText] = React.useState('');
  const [files, setFiles] = React.useState<IFile[]>([]);
  const [error, setError] = React.useState<string>('');
  const dispatch = useDispatch();
  // const userId = useSelector(getUserId);
  // const chat = useSelector(getChat(chatId));
  const messages = useSelector(getChatMessages(chatId));
  // const chatState = useSelector(getChatState);
  // const isAllUploaded = useSelector(getAllMessagesUploadedStatus(chatId));
  const detachFile = (index: number) => () => {
    setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  React.useEffect(() => {
    dispatch(fetchUserChatsAction.request());
  }, []);

  React.useEffect(() => {
    if (!messages) {
      dispatch(
        fetchChatMessagesAction.request({
          chatId: chatId,
        }),
      );
    }
  }, []);

  const pickUserImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    let array: FileList | undefined;
    if (e.target.files) {
      array = e.target.files;
    }
    try {
      if (array) {
        for(let i = 0; i < array.length; i++){
          const res = array[i];
          const reader  = new FileReader();
          reader.onloadend = function () {
            let file: any = res;
            file.uri = String(reader.result);
            if (res.size > maxImageFileSize) {
              setError('Filen är för stor')
              return;
            }
            setFiles(prevFiles => [...prevFiles, file]);
          }
          reader.readAsDataURL(res);
        }
      }
    } catch (error) {
      process.env.REACT_APP_SHOW_LOGS === "true" && console.log(error);
    }
  };

  const sendMessage = () => {
    let text = messageText.trim()
    if (text) {
      setMessageText('');
      setFiles([]);
      dispatch(
        addMessageAction.request({
          chatId: chatId,
          message: {
            id: uuid(),
            chatId: chatId,
            content: messageText,
            status: 'sending',
            attachments: files,
          },
        }),
      );
      props.history.push(`${generatePath(schema.getLink('conversation'), { id: chatId })}`)
    }
  };

  return (
    <section className="create-message">
      <div className="container">
        <div className="title-section">
          <Link to="conversation" id={String(chatId)} className="btn-close">
            <BackArrowIcon className="arrow-back"/>
          </Link>
          Nytt meddelande
        </div>

        <form className="dialog-content">
          <Scrollbars>
            <AutoResizeTextarea
              className={"input text"}
              onChange={e => setMessageText(e.target.value)}
              value={messageText}
              placeholder={"Skriv ditt meddelande här"}
            />
            {files.map((file, index) => (
              <div className="added-file">
                <AttachmentIcon
                  fillColor={'#505051'}
                  className="clip"
                />
                <span className="file">{file.name}</span>
                <button className="btn-close" onClick={detachFile(index)}>
                  <CloseIcon
                    fillColor={'#059483'}
                    className="close-icon"
                  />
                </button>
              </div>
            ))}
          </Scrollbars>
        </form>

        <form className="fixed-btn">
          <input
            type="file"
            className="add-file"
            id="add-file"
            multiple
            onChange={(e) => {
              pickUserImage(e)
            }}
          />
          <label htmlFor="add-file" className="label btn btn-darker">
            <AttachmentIcon
              fillColor={'#059483'}
              className="clip"
            />
            Bifoga fil
          </label>
          <button disabled={!messageText} className={"btn btn-darker" + (!messageText ? " disabled" : "")} type="submit" onClick={sendMessage}>Skicka ett meddelande</button>
        </form>
      </div>
    </section>
  );
};
