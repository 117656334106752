import React from 'react';
import { useSelector } from 'react-redux';
import { getEvents, getEventsState } from '../../../store';
import Scrollbars from "react-custom-scrollbars";

// Components
import { EventItem } from '../../../components/EventItem';
import { TabMessage } from '../../../components/TabMessage';

// constants
import { EventStatusEnum } from '../../../constants';

export const History: React.FC = () => {
  const events = useSelector(getEvents);
  const eventsState = useSelector(getEventsState);
  const archivedEvents = events?.filter(event => {
    const endDate = new Date(event.endDate);
    return event.status.slug === EventStatusEnum.Canceled || endDate.getTime() < new Date().getTime();
  })


  return (
    <div className={"subtab-content-item subtab-content-history loading-element" + (!!eventsState?.isLoading ? " loading-page" : "")} >
      {!archivedEvents || !archivedEvents.length
      ? <TabMessage
        title={"Ingen händelsehistorik"}
        subtitle={"Det verkar som att du inte har bokade händelser. Dina aktuella och passerade händelser visas här."}
      />
      : <Scrollbars>
        {archivedEvents.map(event => {
          return <EventItem key={event.id} isInHistory {...event} />;
        })}
      </Scrollbars>}
    </div>
  );
};
