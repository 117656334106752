import { isSameDay, isThisWeek, isThisYear } from 'date-fns';
import formatWithLocale from './formatWithLocale';

export function formatMessageTimeLabel(date: Date) {
  if (isSameDay(new Date(), date)) {
    return formatWithLocale(date, 'HH:mm');
  } else if (isThisWeek(date)) {
    return formatWithLocale(date, 'EEE');
  } else if (isThisYear(date)) {
    return formatWithLocale(date, 'd.LL');
  } else {
    return formatWithLocale(date, 'dd.LL.yy');
  }
}
