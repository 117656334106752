import React from 'react';

interface ISearchIconProps {
  style?: React.CSSProperties;
  fillColor?: string;
  className?: string;
}

export const SearchIcon: React.FC<ISearchIconProps> = props => {
  const { style, fillColor, className } = props;

  return (
    <svg style={style} className={className} viewBox="0 0 13 13">
      <path
        fill={fillColor || '#B3B3B3'}
        d="M12.6737 11.9372L9.04255 8.30607C9.73127 7.4558 10.1458 6.37484 10.1458 5.19793C10.1458 2.46966 7.92619 0.25 5.19791 0.25C2.46963 0.25 0.25 2.46966 0.25 5.19793C0.25 7.92621 2.46966 10.1459 5.19793 10.1459C6.37484 10.1459 7.4558 9.73127 8.30607 9.04257L11.9372 12.6737C12.039 12.7754 12.2038 12.7754 12.3055 12.6737L12.6737 12.3055C12.7754 12.2038 12.7754 12.0389 12.6737 11.9372ZM5.19793 9.10419C3.0439 9.10419 1.29168 7.35197 1.29168 5.19793C1.29168 3.0439 3.0439 1.29168 5.19793 1.29168C7.35197 1.29168 9.10419 3.0439 9.10419 5.19793C9.10419 7.35197 7.35197 9.10419 5.19793 9.10419Z"
      />
    </svg>
  );
};
