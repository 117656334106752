import { IMessage } from '../../../API';
import { getChatMessages } from '../index';
import { take, select, put } from "redux-saga/effects";

// Actions
import {
  addMessageAction,
  setChatStateAction,
  readMessagesInChatAction,
} from '../actions';
import { getAuthToken } from "../../User";

// API
import { ChatAPI } from '../../../API';

// Exceptions
import { Unauthorized } from "../../../Exceptions";
import { forceLogout } from "../../User/handlers/auth";

export function* addMessageSaga() {
  while (true) {
    const { payload }: ReturnType<typeof addMessageAction.request> = yield take(
      addMessageAction.request
    );

    const authToken: string = yield select(getAuthToken);
    yield put(
      setChatStateAction({
        isLoading: true,
        message: "Message is sending!",
        target: "message",
      })
    );

    try {
      if (!authToken) {
        throw new Error("Credentials wasn`t provided!");
      }

      // @ts-ignore
      const message = yield ChatAPI.addMessage(
        authToken,
        payload.chatId,
        payload.message
      );

      yield put(
        addMessageAction.success({
          chatId: payload.chatId,
          tempMessageId: payload.message.id,
          message,
        })
      );
      yield put(
        setChatStateAction({
          isLoading: false,
          message: "Message was sent!",
          target: "message",
        })
      );
    } catch (error) {
      yield put(
        addMessageAction.failure({
          chatId: payload.chatId,
          message: {
            ...payload.message,
            status: "filed",
          },
        })
      );
      if (payload.filedCB) {
        payload.filedCB("Filed to send the message!");
      }
      yield put(
        setChatStateAction({
          isLoading: false,
          message: "Filed to send the message!",
          target: "message",
        })
      );

      if (error instanceof Unauthorized) {
        yield forceLogout();
      }
    }
  }
}

export function* readMessagesSaga() {
  while (true) {
    const { payload }: ReturnType<typeof readMessagesInChatAction.request> =
      yield take(readMessagesInChatAction.request);

    const authToken: string = yield select(getAuthToken);
    const messages: IMessage[] = yield select(getChatMessages(payload.chatId));

    try {
      if (!messages) {
        throw new Error("Invalid chat id!");
      }

      if (!authToken) {
        throw new Error("Credentials wasn`t provided!");
      }

      const ids = messages
        .filter((message) => !message.isReaded)
        .map((message) => message.id);
      yield ChatAPI.readMessages(ids, authToken);

      yield put(
        readMessagesInChatAction.success({
          chatId: payload.chatId,
        })
      );
    } catch (error) {
      yield put(readMessagesInChatAction.failure());

      if (error instanceof Unauthorized) {
        yield forceLogout();
      }
    }
  }
}
