import React from 'react';
import {RouteComponentProps} from "react-router";
import { useSelector } from 'react-redux';
import { isWithinInterval } from 'date-fns';
import Scrollbars from "react-custom-scrollbars";

import { IFullEvent, EventsAPI, getAuthToken } from '../../store';

// Components
import { EventDescription } from '../../components/EventDescription';
import { Loader } from '../../components/Loader';
import { TabMessage } from '../../components/TabMessage';
import Link from '../../routing/Link';

//icons
import {BackArrowIcon} from "../../assets/img/icons/Arrow";

interface IEventInfoProps extends RouteComponentProps<{
  id: string;
}>{}

let interval: null | number | NodeJS.Timeout = null;
export const EventInfo: React.FC<IEventInfoProps> = ( { match} ) => {
  const authToken = useSelector(getAuthToken);

  const [isLoading, setLoadingStatus] = React.useState(true);
  const [event, setEvent] = React.useState<IFullEvent | null>(null);
  const [isEventInProgress, setEventProgressStatus] = React.useState(false);

  const updateEventProgressStatus = () => {
    setEventProgressStatus(
      !!(
        event &&
        isWithinInterval(new Date(), {
          start: new Date(event.startDate),
          end: new Date(event.endDate),
        })
      ),
    );
  };

  React.useEffect(() => {
    if (authToken) {
      setLoadingStatus(true);

      EventsAPI.fetchEventDetails(Number(match.params.id), authToken)
        .then(event => {
          if (event) setEvent(event);
          setLoadingStatus(false);
        })
        .catch((e) => process.env.REACT_APP_SHOW_LOGS === "true" && console.error(e));
    }
  }, [authToken]);

  React.useEffect(() => {
    updateEventProgressStatus();
    interval = setInterval(updateEventProgressStatus, 1000);

    return () => {
      if (typeof interval === "number") {
        clearInterval(interval);
      }
    };
  }, [event]);

  if (isLoading) {
    return <Loader />;
  }

  if (!event) {
    return <TabMessage title={'Händelse hittades inte'} />;
  }

  return (
    <section className="videocall-info">
      <div className="container">
        <div className="title-section">
          <Link to="events" className="btn-close">
            <BackArrowIcon className="arrow-back"/>
          </Link>
        </div>

        <span className="text chat-empty">
          <span>Denna händelse är avbokad</span>
          <span className="chat-empty-small">
            Om du vill boka en annan händelse/event, vänligen kontakta oss.
          </span>
        </span>

        <Scrollbars style={{flex:"1 1"}}>
          <EventDescription {...event} />
        </Scrollbars>
        <div className="fixed-btn">
          <Link
            to={isEventInProgress ? 'event-call' : 'event'}
            id={String(event.id)}
            className={"btn btn-lighter" + (!isEventInProgress ? " disable" : "")}
          >
            Anslut till videosamtalsrum
          </Link>
        </div>
      </div>
    </section>
  );
};
