import React from 'react';
import { IFullEvent, readEventAction } from '../store';
import { useDispatch } from 'react-redux';

import {
  isWithinInterval,
  differenceInHours,
  formatDistanceToNow,
} from 'date-fns';

// Components
import { UserImage } from '../components/UserImage';
import { EventTimer } from '../components/EventTimer';
import { Attachment } from '../components/Attachment';

// constants
import { UserTypeEnum } from '../constants';

// Utils
import { calculateTimeDifference } from '../utils/calculateTimeDifference';
import formatWithLocale from '../utils/formatWithLocale';

interface IEventDescriptionProps extends IFullEvent {}


let interval: null | number | NodeJS.Timeout = null;
export const EventDescription: React.FC<IEventDescriptionProps> = props => {
  const { participants, description, attachments } = props;

  const startDate = new Date(props.startDate);
  const endDate = new Date(props.endDate);
  const dispatch = useDispatch();
  const [remainingTime, setRemainingTime] = React.useState<null | string>(null);
  const doctor = participants.find(
    user => user.type === UserTypeEnum.Doctor,
  );

  const isEventInProgress = isWithinInterval(new Date(), {
    start: startDate,
    end: endDate,
  });

  const calculateRemainingTime = () => {
    const currentDate = new Date();
    const timeDifference = calculateTimeDifference(startDate, currentDate);

    if (timeDifference) {
      setRemainingTime(timeDifference);
    } else {
      setRemainingTime(null);
      if (differenceInHours(endDate, currentDate) < 0 && typeof interval === "number") {
        clearInterval(interval);
      }
    }
  };

  const checkRemainingComponents = () => {
    if (differenceInHours(startDate, new Date()) > 24) {
      setRemainingTime(formatDistanceToNow(startDate));
    } else {
      calculateRemainingTime();
      if (typeof interval === "number") clearInterval(interval);
      interval = setInterval(calculateRemainingTime, 1000);
    }
  };

  React.useEffect(() => {
    const currentDate = new Date();

    if (differenceInHours(endDate, currentDate) < 0) {
      setRemainingTime(null);
    } else if (differenceInHours(startDate, currentDate) > 24) {
      checkRemainingComponents();
      interval = setInterval(checkRemainingComponents, 5 * 60 * 1000);
    } else {
      calculateRemainingTime();
      interval = setInterval(calculateRemainingTime, 1000);
    }

    if (!props.isReaded) {
      dispatch(readEventAction.request({ eventId: props.id }));
    }

    return () => {
      if (typeof interval === "number") {
        clearInterval(interval);
      }
    };
  }, []);

  if (!doctor) {
    return null;
  }

  return (
    <div className="event-wrap">
      <div className="event-time">
        <span className="duration">
          {formatWithLocale(startDate, 'EEE d MMM')} {formatWithLocale(startDate, 'h:mm')}-
          {formatWithLocale(endDate, 'h:mm')}
        </span>
        {remainingTime && (
          <div className="remaining">
            <span>Återstående tid:</span>
            <span className="remaining-time">{remainingTime}</span>
          </div>
        )}
      </div>
      {isEventInProgress && (
        <EventTimer startDate={startDate} endDate={endDate} />
      )}

      <div className="event-info">
        <div className="user-photo">
          <UserImage
            className="photo"
          />
        </div>

        <span className="doctor-name text">{doctor.name}</span>
        {/*<span className="doctor-specialization text">Therapist</span>*/}
      </div>
      {(description || attachments?.length > 0) && (
        <div className="event-description">
          <span className="text description">Beskrivning</span>

          {description && (
            <p className={"text"}>{description}</p>
          )}

          {attachments && (
            <div>
              {attachments.map(attachment => (
                <Attachment key={attachment.id} name={attachment.name} uri={attachment.uri} />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
