import React from 'react';
import { IChat } from '../store';

// Components
import { UserImage } from '../components/UserImage';
import Link from '../routing/Link'
// Constants
import { UserTypeEnum } from '../constants';

// Navigation
import { formatMessageTimeLabel } from '../utils/formatMessageTimeLabel';

interface IChatItemProps extends IChat {}

export const ChatItem: React.FC<IChatItemProps> = props => {
  const { id, unreadNumber, participants, createdAt, lastMessage } = props;
  const doctor = participants.find(user => user.type === UserTypeEnum.Doctor)
  const time = lastMessage ? new Date(lastMessage.createdAt) : new Date(createdAt);

  console.log('doctor: ', doctor);

  if(!doctor) {
    return null;
  }

  return (
    <Link className={"event-feature message-feature" + (unreadNumber > 0 ? " unread" : "")} to={"conversation"} id={String(id)}>
      <div className="photo-wrap">
        <UserImage className={"photo"}/>
      </div>

      <div className="event-info event-info-tab">
        <span className="text text-bold">{doctor.name}</span>

        <div className="event-subinfo">
          <span className="text message-text">
            {lastMessage?.content ? lastMessage.content : "Du har inga meddelanden än..."}
          </span>
        </div>
      </div>

      <div className="event-date">
        <span className="date text">{formatMessageTimeLabel(time)}</span>
        {unreadNumber > 0 && (<div className="message-unread">{unreadNumber}</div>)}
      </div>
    </Link>
  );
};
