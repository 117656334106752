import React from 'react';

interface IBackArrowIconProps {
  style?: React.CSSProperties;
  fillColor?: string;
  className?: string;
}

export const BackArrowIcon: React.FC<IBackArrowIconProps> = props => {
  const { style, fillColor, className } = props;

  return (
    <svg style={style} viewBox="0 0 19 19" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fillColor || '#059483'}
        d="M0.000926791 9.12206L9.1226 0.000388435L11.5605 2.43834L2.43887 11.56L0.000926791 9.12206Z"
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fillColor || '#059483'}
        d="M9.12212 18.2436L0.000445308 9.12193L2.45605 6.66632L11.5777 15.788L9.12212 18.2436Z"
      />
    </svg>
  );
};
