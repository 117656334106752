import React from 'react';
import { IFullEvent, readEventAction } from '../store';
import { useDispatch } from 'react-redux';

import {
  isWithinInterval,
  differenceInHours,
  formatDistanceToNow,
} from 'date-fns';

// Components
import { UserImage } from '../components/UserImage';
import { EventTimer } from '../components/EventTimer';
import { Attachment } from '../components/Attachment';

// constants
import { UserTypeEnum } from '../constants';

// Utils
import { calculateTimeDifference } from '../utils/calculateTimeDifference';
import formatWithLocale from '../utils/formatWithLocale';

interface IEventDescriptionProps extends IFullEvent {}


let interval: null | number | NodeJS.Timeout = null;
export const EventShortDescription: React.FC<IEventDescriptionProps> = props => {
  const { participants } = props;

  const startDate = new Date(props.startDate);
  const endDate = new Date(props.endDate);
  const dispatch = useDispatch();
  const doctor = participants.find(
    user => user.type === UserTypeEnum.Doctor,
  );

  const isEventInProgress = isWithinInterval(new Date(), {
    start: startDate,
    end: endDate,
  });

  React.useEffect(() => {
    if (!props.isReaded) {
      dispatch(readEventAction.request({ eventId: props.id }));
    }
  }, []);

  if (!doctor) {
    return null;
  }

  return (
    <>
      <div className="event-time">
        <span className="duration">
          {formatWithLocale(startDate, 'EEE d MMM')} {formatWithLocale(startDate, 'h:mm')}-{formatWithLocale(endDate, 'h:mm')}
        </span>
        <span className="waiting text">Väntar på att användaren ska ansluta sig</span>
      </div>
      <div className="event-info">
        <div className="user-photo">
          <UserImage
            className="photo"
          />
        </div>

        <span className="doctor-name text">{doctor.name}</span>
        {/*<span className="doctor-specialization text">Therapist</span>*/}
      </div>
    </>
  );
};
