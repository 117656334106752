import React from 'react';

interface IAttachmentIconProps {
  style?: React.CSSProperties;
  fillColor?: string;
  className?: string;
}

export const AttachmentIcon: React.FC<IAttachmentIconProps> = props => {
  const { style, fillColor, className } = props;

  return (
    <svg style={style} viewBox="0 0 7 14" className={className}>
      <path
        fill={fillColor || '#47494B'}
        d="M2.46094 14C1.10398 14 0 12.896 0 11.5391V3.28125C0 1.47197 1.47197 0 3.28125 0C5.09053 0 6.5625 1.47197 6.5625 3.28125V11.5391H5.74219V3.28125C5.74219 1.92429 4.63821 0.820312 3.28125 0.820312C1.92429 0.820312 0.820312 1.92429 0.820312 3.28125V11.5391C0.820312 12.4437 1.5563 13.1797 2.46094 13.1797C3.36558 13.1797 4.10156 12.4437 4.10156 11.5391V4.92188C4.10156 4.46955 3.73357 4.10156 3.28125 4.10156C2.82893 4.10156 2.46094 4.46955 2.46094 4.92188V11.5391H1.64062V4.92188C1.64062 4.01723 2.37661 3.28125 3.28125 3.28125C4.18589 3.28125 4.92188 4.01723 4.92188 4.92188V11.5391C4.92188 12.896 3.8179 14 2.46094 14Z"
      />
    </svg>
  );
};
