import React from 'react';
import * as OpenTok from "opentok-react";
import "@opentok/client";

// Icons
import {PhoneIcon} from '../assets/img/icons/Phone';

interface IVideoCallControlsProps {
  isMinimizedMode: boolean;
  cancelBtnClick: () => void;
  onViewPress?: () => void;
  isMobile: boolean
}

export const VideoCallControls: React.FC<IVideoCallControlsProps> = props => {
  const { cancelBtnClick, isMobile} = props;
  return (
    <div>
      <div className={"user-frame-wrap"} onClick={props.onViewPress}>
        <OpenTok.OTPublisher
          properties={{
            width: isMobile ? 110 : undefined,
            height: isMobile ? 160 : undefined
          }}
        />
      </div>
      <div className="btn-call-wrap">
        <button className={"btn-call btn-call-end"} onClick={cancelBtnClick}>
          <PhoneIcon className={"btn-call-icon"}/>
        </button>
      </div>
    </div>
  );
};