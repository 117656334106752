import React from 'react';
import { IChat } from '../store';

// Icons
import { SearchIcon } from '../assets/img/icons/Search';
import { CloseIcon } from '../assets/img/icons/Close';

interface ISearchBarProps {
  chats: IChat[] | null;
  setChats: React.Dispatch<React.SetStateAction<IChat[] | null>>;
}

export const SearchBar: React.FC<ISearchBarProps> = props => {
  const { chats, setChats } = props;

  const [isInFocus, setFocusStatus] = React.useState(false);
  const [inputVal, setInputVal] = React.useState('');

  React.useEffect(() => {
    const filteredChats = chats?.filter(chat => {
      if (!chat.participants?.length) return null;

      return chat.participants.find(user => {
        return `${user.name}`.toLowerCase().includes(inputVal.toLowerCase());
      })
    });

    setChats(filteredChats || null);
  }, [inputVal]);

  return (
    <div className="title-section">
      <form action="" className={"search" + (isInFocus || inputVal ? " active" : "")}>
        <input
          value={inputVal}
          onChange={(e) => setInputVal(e.target.value)}
          onFocus={() => setFocusStatus(true)}
          onBlur={() => setFocusStatus(false)}
          className="input"
          id="search"
        />
        <label htmlFor="search" className={"label" + (inputVal ? " visible" : "")}>
          <SearchIcon
            className="search-icon"
            fillColor={isInFocus ? "#059483" : '#B2B2B2'}
          />
          <span className="text search-text">Sök med hjälp av läkarens namn</span>
          <button className="btn-close" type="button" onClick={() => setInputVal('')}>
            <CloseIcon className="close-icon" fillColor={isInFocus ? "#059483" : '#B2B2B2'}
            />
          </button>
        </label>
      </form>
    </div>
  );
};
