import React, { useState, useEffect, useRef, RefObject } from 'react'
import { isMobileOnly, isIOS, isAndroid, isWinPhone } from 'react-device-detect'
import { timeStorageService } from '../services/timeStorageService'

import { CloseIcon } from '../assets/img/icons/Close'
import { PopupAppLogo } from '../assets/img/icons/PopupAppLogo'
import { AppStoreButton } from '../assets/img/buttons/AppStore'
import { GooglePlayButton } from '../assets/img/buttons/GooglePlay'
import { DownloadAppQR } from '../assets/img/buttons/DownloadAppQR'

const PopupDownloadApp: React.FC = () => {
   const [isShowed, setIsShowed] = useState<boolean>(false)
   const popupRef = useRef() as RefObject<HTMLDivElement>
   const POPUP_FIT_SCREEN_WIDTH = 1300

   useEffect(() => {
      if (!timeStorageService.popupWasClosedRecently) {
         setIsShowed(true)
      }

      showPopupIfFit()
      window.addEventListener('resize', showPopupIfFit)
      return () => {
         window.removeEventListener('resize', showPopupIfFit)
      }
   }, [])

   const closePopup = () => {
      setIsShowed(false)
      timeStorageService.saveClosedTime()
   }

   const showPopupIfFit = () => {
      if (document.body.clientWidth >= POPUP_FIT_SCREEN_WIDTH) {
         setIsShowed(true)
      } else if (
         document.body.clientWidth < POPUP_FIT_SCREEN_WIDTH &&
         timeStorageService.popupWasClosedRecently
         ) {
         setIsShowed(false)
      }
   }

   return isShowed && !isWinPhone ? (
      <div className="popup-download-app" ref={popupRef}>
         <div className="close-icon" onClick={closePopup}>
            <CloseIcon style={{ width: 12, height: 12 }} fillColor="#059483" />
         </div>
         
         <PopupAppLogo />
         
         <div className="text">
            Stay in control of your health
         </div>
         
         <div className="download-buttons">
            {!isMobileOnly && (
               <div>
                  <DownloadAppQR />
               </div>
            )}

            <div className="store-buttons">
               {(isIOS || !isMobileOnly) && (
                  <a href="https://apps.apple.com/ua/app/riddarens/id1533239408" target="_blank">
                     <AppStoreButton />
                  </a>
               )}
               {(isAndroid || !isMobileOnly) && (
                  <a href="https://play.google.com/store/apps/details?id=se.riddarens.videovisit" target="_blank">
                     <GooglePlayButton />
                  </a>
               )}
            </div>
         </div>
      </div>
   ) : null
}

export default PopupDownloadApp
