import React from 'react';

export interface ICalendarIconProps {
  style?: React.CSSProperties;
  fillColor?: string;
  className?: string
}

export const CalendarIcon: React.FC<ICalendarIconProps> = props => {
  const { style, fillColor, className } = props;

  return (
    <svg style={style} className={className} viewBox="0 0 24 24">
      <path
        fill={fillColor || "#45A3DB"}
        d="M22 14.2609V3.99998C22 2.89697 21.1025 1.99997 20 1.99997H17V0.499969C17 0.223641 16.7764 0 16.5 0C16.2236 0 16 0.223641 16 0.500016V2.00002H6.99998V0.500016C6.99998 0.223641 6.77639 0 6.50002 0C6.22364 0 6 0.223641 6 0.500016V2.00002H2.00002C0.897469 2.00002 0 2.89697 0 3.99998V20C0 21.103 0.897469 22 2.00002 22H14.2608C15.2706 23.2208 16.7961 24 18.5 24C21.5322 24 24 21.5327 24 18.5C24 16.7959 23.2207 15.2705 22 14.2609ZM0.999984 3.99998C0.999984 3.44873 1.44825 3 2.00002 3H6V3.99998C5.44823 3.99998 5.00002 4.44872 5.00002 4.99997C5.00002 5.55122 5.44828 5.99995 6 5.99995C6.55172 5.99995 6.99998 5.55122 6.99998 4.99997V3H16V3.99998C15.4482 3.99998 15 4.44872 15 4.99997C15 5.55122 15.4483 5.99995 16 5.99995C16.5518 5.99995 17 5.55122 17 4.99997V3H20C20.5518 3 21 3.44873 21 3.99998V6.99998H0.999984V3.99998ZM2.00002 21C1.44825 21 1.00003 20.5513 1.00003 20V8.00002H21V13.6072C20.2489 13.2218 19.4006 13 18.5 13C15.4678 13 13 15.4673 13 18.5C13 19.4006 13.2219 20.2489 13.6072 21H2.00002ZM18.5 23C16.0185 23 14 20.9814 14 18.5C14 16.0186 16.0186 14 18.5 14C20.9814 14 23 16.0186 23 18.5C23 20.9814 20.9814 23 18.5 23Z" 
      />
      <path
        fill={fillColor || "#45A3DB"}
        d="M19 18.293V15.5C19 15.2236 18.7763 15 18.5 15C18.2236 15 18 15.2236 18 15.5V18.5C18 18.6328 18.0527 18.7598 18.1465 18.8535L19.6465 20.3535C19.7441 20.4512 19.8721 20.5 20 20.5C20.1279 20.5 20.2559 20.4512 20.3536 20.3535C20.5489 20.1582 20.5489 19.8418 20.3536 19.6465L19 18.293Z"
      />
    </svg>
  );
}

export const CalendarStatusIcon: React.FC<ICalendarIconProps> = props => {
  const { style, fillColor, className } = props;

  return (
    <svg style={style} className={className} viewBox="0 0 12 12">
      <path
        d="M10.625 12H1.37503C0.616516 12 0 11.3835 0 10.6249V2.37494C0 1.61642 0.616516 1 1.37503 1H10.625C11.3835 1 12 1.61642 12 2.37494V10.6249C12 11.3835 11.3835 12 10.625 12ZM1.37503 1.75C1.03052 1.75 0.75 2.03043 0.75 2.37494V10.6249C0.75 10.9695 1.03052 11.25 1.37503 11.25H10.625C10.9695 11.25 11.25 10.9695 11.25 10.6249V2.37494C11.25 2.03043 10.9695 1.75 10.625 1.75H1.37503Z"
        fill={fillColor || "#8662B2"}
      />
      
      <path
        d="M11.625 4.75H0.375C0.167999 4.75 0 4.582 0 4.375C0 4.168 0.167999 4 0.375 4H11.625C11.832 4 12 4.168 12 4.375C12 4.582 11.832 4.75 11.625 4.75Z"
        fill={fillColor || "#8662B2"}
      />
      
      <path
        d="M2.875 3C2.668 3 2.5 2.832 2.5 2.625V0.375C2.5 0.167999 2.668 0 2.875 0C3.082 0 3.25 0.167999 3.25 0.375V2.625C3.25 2.832 3.082 3 2.875 3Z"
        fill={fillColor || "#8662B2"}
      />
      
      <path
        d="M9.125 3C8.918 3 8.75 2.832 8.75 2.625V0.375C8.75 0.167999 8.918 0 9.125 0C9.332 0 9.5 0.167999 9.5 0.375V2.625C9.5 2.832 9.332 3 9.125 3Z"
        fill={fillColor || "#8662B2"}
      />
      
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.28248 7.28285L7.28248 9.28285L6.7168 8.71716L8.7168 6.71716L9.28248 7.28285Z"
        fill={fillColor || "#8662B2"}
      />
      
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.28248 6.71715L9.28248 8.71715L8.7168 9.28284L6.7168 7.28284L7.28248 6.71715Z"
        fill={fillColor || "#8662B2"}
      />
    </svg>
  );
}