import { takeEvery, put, select } from 'redux-saga/effects';
import { all } from 'redux-saga/effects';

// Actions
import { readEventAction } from '../actions';

// API
import { EventsAPI } from '../../../API/EventsAPI';

// Selectors
import { getAuthToken } from '../../User';

export function* readEventSaga({ payload }: ReturnType<typeof readEventAction.request>) {
  try {
    const authToken: string = yield select(getAuthToken);

    yield EventsAPI.readEvents([payload.eventId], authToken);

    yield put(readEventAction.success({ eventId: payload.eventId }));
  } catch (error) {
    yield put(readEventAction.failure());
  }
}

export function* eventsGeneralSaga() {
  yield all([takeEvery(readEventAction.request, readEventSaga)]);
}