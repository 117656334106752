interface IHostEnv {
  API_HOST: string;
  EXTERNAL_API_HOST: string;
  WS_API_HOST: string;
  OCP_APIM_SUBSCRIPTION_KEY: string;
}

export class Config {
  public static init(env: IHostEnv) {
    this.API_HOST = env.API_HOST;
    this.EXTERNAL_API_HOST = env.EXTERNAL_API_HOST;
    this.WS_API_HOST = env.WS_API_HOST;
    this.OCP_APIM_SUBSCRIPTION_KEY = env.OCP_APIM_SUBSCRIPTION_KEY;
  }

  // General
  public static API_HOST: string;
  public static EXTERNAL_API_HOST: string;
  public static WS_API_HOST: string;
  public static OCP_APIM_SUBSCRIPTION_KEY: string;

  public static API_URL = "api/v1";
  public static get API_URI() {
    return `${this.API_HOST}/${this.API_URL}`;
  };

  public static EXTERNAL_API_URL = "authentication/v1/User";
  public static get EXTERNAL_API_URI() {
    return `${this.EXTERNAL_API_HOST}/${this.EXTERNAL_API_URL}`;
  };

  public static get WS_ENDPOINT_CHAT() { return `${Config.WS_API_HOST}/chat`; };
  public static get WS_ENDPOINT_EVENT() { return `${Config.WS_API_HOST}/event`; };

  // Survey
  public static get SURVEY_API_URI() { return `${Config.EXTERNAL_API_HOST}/v1/Content`; };
  public static get SURVEY_API_LIST_SURVEY() { return `${Config.SURVEY_API_URI}/list`; };
  public static get SURVEY_API_SURVEY_DETAILS() { return `${Config.SURVEY_API_URI}/details`; };
  public static get SURVEY_API_SEND_RESULT() { return `${Config.API_URI}/survey/add-result`; };

  // User
  public static get USER_API_URI() { return `${Config.API_URI}/user`; };
  public static get USER_API_INIT_BANK_ID() { return `${Config.EXTERNAL_API_URI}/initiateRequest`; };
  public static get USER_API_VALIDATE_BANK_ID() { return `${Config.EXTERNAL_API_URI}/validateRequest`; };
  public static get USER_API_LOGOUT() { return `${Config.USER_API_URI}/logout`; };
  public static get USER_API_INIT_USER_SESSION() { return `${Config.USER_API_URI}/init`; };
  public static get USER_API_LOGIN() { return `${Config.API_URI}/auth/login`; };
  public static get USER_API_UPDATE_PROFILE() { return `${Config.USER_API_URI}/update`; };

  // Events
  public static get API_ENDPOINT_EVENTS() { return `${Config.API_URI}/event`; };
  public static get API_ENDPOINT_GET_USER_EVENTS() { return `${Config.API_ENDPOINT_EVENTS}/user`; };
  public static get API_ENDPOINT_GET_EVENT_DETAILS() { return `${Config.API_ENDPOINT_EVENTS}/details`; };
  public static get API_ENDPOINT_JOIN_TO_THE_CALL() { return `${Config.API_ENDPOINT_EVENTS}/join`; };
  public static get API_ENDPOINT_READ_EVENT() { return `${Config.API_ENDPOINT_EVENTS}/read`; };
  public static get API_ENDPOINT_GET_NEW_NUMBER() { return `${Config.API_ENDPOINT_EVENTS}/unread`; };

  // Chat
  public static get API_ENDPOINT_CHAT() { return `${Config.API_URI}/chat`; };
  public static get API_ENDPOINT_CREATE_MESSAGE() { return `${Config.API_ENDPOINT_CHAT}/message`; };
  public static get API_ENDPOINT_READ_MESSAGE() { return `${Config.API_ENDPOINT_CHAT}/message/read`; };
  public static get API_ENDPOINT_GET_USER_CHATS() { return `${Config.API_ENDPOINT_CHAT}/user`; };
  public static API_ENDPOINT_GET_CHAT_MESSAGES = (chatId: number) =>
    `${Config.API_ENDPOINT_CHAT}/${chatId}/messages`;
}

export enum SocketEventEnum {
  NewMessage = 'NewMessage',
  NewChat = 'NewChat',
  DisableChat = 'DisableChat',

  NewEvent = 'NewEvent',
  UpdateEvent = 'UpdateEvent',
}

export enum DevicePlatformEnum {
  IOS = 'ios',
  Android = 'android',
  Web = 'web',
}



export const testQuestionsBody = {
  query: `query ($ids: [String], $preview: Boolean, $locale: String) {
        questions: questionCollection(preview: $preview, locale: $locale, where: {sys: {id_in: $ids}}) {
          items {
            ...questionFields
          }
        }
      }
      
      fragment questionFields on Question {
        title
        points
        questionType
        sys {
          id
        }
        condition {
          ...conditionFields
        }
        ratingDetails {
          ...ratingDetailsFields
        }
        metaData {
          ...metaDataFields
        }
        answers: possibleAnswersCollection(limit: 15, preview: $preview, locale: $locale) {
          items {
            ...answersFields
          }
        }
      }
      
      fragment conditionFields on QuestionCondition {
        visibleIf
        requiredIf
        enableIf
        expression
        relatedQuestionValue
        relatedQuestion {
          sys {
            id
          }
        }
        supportConditions: supportConditionsCollection(limit: 5) {
          items {
            type
            expression
            relatedQuestionValue
            relatedQuestion {
              sys {
                id
              }
            }
          }
        }
      }
      
        fragment ratingDetailsFields on RatingDetails {
            maxRateDescription
            minRateDescription
            rateMax
            rateMin
            rateStep
            rateValues
          }
      
        fragment metaDataFields on MetaData {
            isRequired
            startWithNewLine
            inputTextMask
            titleLocation
            placeHolder
            colCount
            hasOther
            hasOtherText
            width
            imageWidth
            imageHeight
            asset {
              url
              contentType
            }
            html {
              json
            }}
      
      fragment answersFields on Answer {
        sys {
          id
        }
        title
        value
        points
        connectedAsset {
          url
          contentType
        }
      }`,
  variables: `{"ids": ["40PbNFvd0S3PVlOJOYPzdx","6wqOaWfWW9sdpIW88b5Agm","2MRt5VDWQvCs1rtZPC8lF4","4Pccb5c88hvKrYwHxOdUNF","6f1JcqwPPQjE5zfqHfHu2e","5xtPyMAqiv9EHcLD3PUFCA","6rVhfZvEYz6TGCgGFEO9Ry","392zWo777n3PfBcDwYYiLd","7FP1VTyCFD2PBQUgIIN6cC","14OljO1OLgB93wG3p287SM","51JPPGNWWg50NU0emwTI6S"],
      "preview":false,
     "locale": "sv"}`
}

export const testJsonnBody = {
  query: `query ($id: String!,$preview: Boolean, $locale: String) {
        test(id:$id, preview: $preview, locale: $locale){
              ...testFields
          }
      }
      
      fragment testFields on Test {
        title
        description
        completeText
        completedHtml {
          json
        }
        firstPageIsStarted
        testTimeInMinutes
        startButtonText
        showPrevButton
        pageNextText
        pagePrevText
        showQuestionNumbers
        showProgressBar
        pages: pagesCollection(limit: 15, preview: $preview, locale: $locale) {
          items {
            ...pageFields
          }
        }
      }
      
          fragment pageFields on Page {
            sys {
              id
            }
            condition {
              ...conditionFields
            }
            title
            areQuestionsRandomized
            maxTimeToFinish
            panels: panelsCollection(limit: 15, preview: $preview, locale: $locale) {
              items {
                ...panelFields
              }
            }
          }
          
      fragment conditionFields on QuestionCondition {
        visibleIf
        requiredIf
        enableIf
        expression
        relatedQuestionValue
        relatedQuestion {
          sys {
            id
          }
        }
        supportConditions: supportConditionsCollection(limit: 5) {
          items {
            type
            expression
            relatedQuestionValue
            relatedQuestion {
              sys {
                id
              }
            }
          }
        }
      }
          
      fragment panelFields on Panel {
          sys {
              id
              }
        title
        description
        areQuestionsRandomized
        startWithNewLine
        showNumber
        showQuestionNumbers
        width
        condition {
          ...conditionFields
        }
        questions: questionsCollection(limit: 15, preview: $preview, locale: $locale) {
          items {
            sys{
              id
          }
          }
        }
      }`,

  variables: `{"id": "2C9etnUQjc9zUo2BC8I4dM", "preview":false, "locale": "sv"}`
}
