import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  getChatMessages,
  fetchChatMessagesAction,
  getChatState,
  getChat,
  getUserId,
  readMessagesInChatAction,
  MessageType,
  fetchUserChatsAction,
  fetchAdditionalMessagesAction,
  getAllMessagesUploadedStatus,
} from '../../store';
import ScrollView from 'react-inverted-scrollview';

// Components
import Link from '../../routing/Link';
import {ChatMessage} from '../../components/ChatMessage';
import Loader from "../../components/Loader";

//Icons
import {BackArrowIcon} from "../../assets/img/icons/Arrow";

// Utils
import formatWithLocale from '../../utils/formatWithLocale';
import {RouteComponentProps} from "react-router";

interface IChatProps extends RouteComponentProps<{
  id: string;
}> {
}

const dateFormat = 'dd.MM.yy';
const newMessagesLabel = 'Nytt meddelande';

function composeSections(messages?: Array<MessageType>, userId?: number) {
  const sections = new Array<{
    title: string;
    data: Array<MessageType>;
    isNewMessages: boolean;
  }>();
  if (!messages) return sections;
  messages.sort((messageA: any, messageB: any) => {
    if(!messageA.createdAt) return 1;
    if(!messageB.createdAt) return -1;

    if(new Date(messageA.createdAt) < new Date(messageB.createdAt)){
      return -1
    }
    if(new Date(messageA.createdAt) > new Date(messageB.createdAt)){
      return 1
    }
    return 0;
  })
  const messagesByDate = new Map<string, MessageType[]>();

  for (let message of messages) {
    const anyMessage = message as any;

    let key: string;
    if (typeof anyMessage?.isReaded === 'boolean' && !anyMessage?.isReaded && anyMessage.authorId !== userId) {
      key = newMessagesLabel;
    } else {
      const date = anyMessage.createdAt ? new Date(anyMessage.createdAt) : new Date();

      key = formatWithLocale(date, dateFormat)
    }

    if (!messagesByDate.has(key)) {
      messagesByDate.set(key, [message]);
    } else {
      messagesByDate.get(key)?.push(message);
    }
  }

  // @ts-ignore
  for (let [label, messages] of messagesByDate.entries()) {
    sections.push({
      title: label,
      isNewMessages: label === newMessagesLabel,
      data: [...messages]
    });
  }

  return sections;
}

export const Chat: React.FC<IChatProps> = props => {
  const {match} = props;
  const chatId = Number(match.params.id)
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);
  const chat = useSelector(getChat(chatId));
  const messages = useSelector(getChatMessages(chatId));
  const [sortedMessages, setSortedMessages] = React.useState(() => composeSections(messages, userId));
  let scrollViewRef = React.createRef<any>();
  const chatState = useSelector(getChatState);
  const isAllUploaded = useSelector(getAllMessagesUploadedStatus(chatId));

  const uploadMoreMessages = () => {
    if (!isAllUploaded) {
      dispatch(fetchAdditionalMessagesAction.request({
        chatId: chatId
      }));
    }
  }

  const scrollToBottom = () => {
    if (!scrollViewRef) return;
    if("scrollToBottom" in scrollViewRef){
      // @ts-ignore
      scrollViewRef.scrollToBottom();
    }
  }

  const handleScroll = ({scrollTop, scrollBottom}: { scrollTop: number, scrollBottom: number }) => {
    if (chatState?.isLoading || scrollTop > 100) return;

    uploadMoreMessages()
  };

  const excludeUpdateReading = (messages: MessageType[] | undefined) => {
    if (!messages) {
      return null;
    }

    const unreadedMessages = sortedMessages.find(
      section => section.title === newMessagesLabel
    )?.data.map(message => message.id);

    return messages?.map(message => {
      if (unreadedMessages?.includes(message.id)) {
        return {
          ...message,
          isReaded: false
        }
      }

      return message;
    });
  }

  React.useEffect(() => {
    dispatch(fetchUserChatsAction.request());
  }, []);

  React.useEffect(() => {
    const newMessages = sortedMessages ? excludeUpdateReading(messages) : messages;

    setSortedMessages(composeSections(newMessages || [], userId));
    if (chat && chat.unreadNumber > 0) {
      dispatch(
        readMessagesInChatAction.request({chatId: chatId}),
      );
    }
  }, [messages]);

  React.useEffect(() => {
    if (!messages) {
      dispatch(
        fetchChatMessagesAction.request({
          chatId: chatId,
        }),
      );
    } else if (chat && chat.unreadNumber > 0) {
      dispatch(
        readMessagesInChatAction.request({chatId: chatId}),
      );
    }
  }, []);

  let emptyBody = <span className="text chat-empty">Du har inga meddelanden än</span>;

  if (!chat) {
    return null;
  }
  return (
    <section className="dialog">
      <div className="container">
        <div className="title-section">
          <Link to="conversations" className="btn-close">
            <BackArrowIcon className="arrow-back"/>
          </Link>
          {chat.participants[0].name}
        </div>
        <div
          className={"dialog-content loading-element" + ((!chatState || (chatState.isLoading && chatState.target === 'messages')) ? " loading-page" : "")}>
          {!messages?.length ? emptyBody : (
            <ScrollView
              onScroll={handleScroll}
              ref={(ref:any) => (scrollViewRef = ref)}
              className={"scroll-view scrollbar"}
            >
              {chatState?.isLoading ? (
                <div className="load-more-loader">
                  <Loader show={true}/>
                </div>
              ) : null }
              {sortedMessages.map((messages) => {
                let res = [
                  <div
                    className={"dialog-info " + (messages.isNewMessages ? " dialog-info-unread" : " dialog-info-date")}>
                    <span className="content text"> {messages.title} </span>
                  </div>
                ];
                res.push(
                  <>
                    {messages.data.map((message) => (
                      <ChatMessage
                        message={message}
                        participants={chat?.participants!}
                      />
                    ))}
                  </>
                )
                return res;
              })}
            </ScrollView>
          )}
          <div className="fixed-btn">
            <Link to={"newMessage"} id={match.params.id} className={"btn btn-darker"}>
              Skicka ett meddelande
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};
