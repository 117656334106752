import React from 'react'

type Props = {
   color?: string
   style?: { [key: string]: number | string }
}

export const AppStoreButton: React.FC<Props> = ({ color, style, ...props }) => {
   return (
      <svg
         {...props}
         style={{ ...style }}
         width="120"
         height="40"
         viewBox="0 0 120 40"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <rect
            x="0.5"
            y="0.5"
            width="119"
            height="39"
            rx="2.5"
            fill="none"
            stroke={color}
         />
         <path
            d="M25.4309 19.0424C25.4421 18.1744 25.6733 17.3234 26.1029 16.5685C26.5326 15.8136 27.1468 15.1793 27.8883 14.7247C27.4172 14.0538 26.7958 13.5017 26.0733 13.1122C25.3508 12.7227 24.5472 12.5065 23.7263 12.4809C21.9751 12.2976 20.2775 13.5258 19.3851 13.5258C18.4755 13.5258 17.1016 12.4991 15.6221 12.5295C14.6652 12.5603 13.7325 12.8378 12.9151 13.3349C12.0976 13.8321 11.4233 14.5319 10.9577 15.3662C8.94093 18.8482 10.4453 23.9655 12.3772 26.7801C13.3438 28.1584 14.4734 29.6979 15.9516 29.6433C17.398 29.5834 17.9382 28.7235 19.6842 28.7235C21.414 28.7235 21.9208 29.6433 23.429 29.6086C24.9811 29.5834 25.959 28.2242 26.8917 26.8329C27.5861 25.8509 28.1206 24.7655 28.4751 23.6171C27.5734 23.2367 26.8038 22.6001 26.2625 21.7866C25.7211 20.9731 25.4319 20.0187 25.4309 19.0424Z"
            fill="black"
         />
         <path
            d="M22.5818 10.6299C23.4281 9.61681 23.845 8.31467 23.7441 7C22.4512 7.13541 21.2569 7.75161 20.3992 8.72581C19.9798 9.20174 19.6586 9.75542 19.454 10.3552C19.2493 10.955 19.1652 11.5891 19.2065 12.2213C19.8532 12.2279 20.493 12.0882 21.0776 11.8125C21.6623 11.5368 22.1766 11.1325 22.5818 10.6299Z"
            fill={color}
         />
         <path
            d="M43.7838 26.2926H39.0371L37.8972 29.649H35.8867L40.3827 17.2311H42.4716L46.9675 29.649H44.9228L43.7838 26.2926ZM39.5288 24.7438H43.2913L41.4365 19.2965H41.3845L39.5288 24.7438Z"
            fill={color}
         />
         <path
            d="M56.6773 25.1228C56.6773 27.9362 55.1672 29.7438 52.8884 29.7438C52.3111 29.7739 51.737 29.6413 51.2318 29.3612C50.7266 29.0811 50.3107 28.6647 50.0317 28.1598H49.9886V32.6442H48.125V20.5954H49.9289V22.1013H49.9632C50.2549 21.5988 50.6778 21.1846 51.1869 20.9028C51.6959 20.6209 52.2721 20.482 52.8541 20.5007C55.1584 20.5007 56.6773 22.3171 56.6773 25.1228ZM54.7618 25.1228C54.7618 23.2898 53.8118 22.0847 52.3625 22.0847C50.9386 22.0847 49.9808 23.3151 49.9808 25.1228C49.9808 26.947 50.9386 28.1686 52.3625 28.1686C53.8118 28.1686 54.7617 26.9724 54.7617 25.1228H54.7618Z"
            fill={color}
         />
         <path
            d="M66.6709 25.1228C66.6709 27.9362 65.1604 29.7438 62.8815 29.7438C62.3043 29.7739 61.7301 29.6413 61.2249 29.3612C60.7197 29.0811 60.3038 28.6647 60.0249 28.1598H59.9818V32.6442H58.1182V20.5954H59.922V22.1013H59.9563C60.2481 21.5988 60.671 21.1846 61.1801 20.9028C61.6891 20.6209 62.2653 20.482 62.8472 20.5007C65.1516 20.5007 66.6709 22.3171 66.6709 25.1228ZM64.7549 25.1228C64.7549 23.2898 63.805 22.0847 62.3556 22.0847C60.9317 22.0847 59.974 23.3151 59.974 25.1228C59.974 26.947 60.9317 28.1686 62.3556 28.1686C63.805 28.1686 64.7549 26.9724 64.7549 25.1228Z"
            fill={color}
         />
         <path
            d="M73.2739 26.1891C73.412 27.4206 74.6117 28.2291 76.251 28.2291C77.8218 28.2291 78.9519 27.4205 78.9519 26.3102C78.9519 25.3463 78.2704 24.7692 76.6565 24.3736L75.0426 23.9859C72.7559 23.4352 71.6943 22.3688 71.6943 20.6383C71.6943 18.4957 73.5668 17.024 76.2246 17.024C78.857 17.024 80.6608 18.4957 80.7216 20.6383H78.8403C78.7277 19.399 77.7004 18.651 76.1991 18.651C74.6978 18.651 73.6706 19.4078 73.6706 20.5094C73.6706 21.3873 74.3267 21.9039 75.9318 22.2994L77.3038 22.6353C79.8588 23.2379 80.9194 24.2613 80.9194 26.0777C80.9194 28.4009 79.0645 29.856 76.1129 29.856C73.3513 29.856 71.4867 28.4351 71.3662 26.189L73.2739 26.1891Z"
            fill={color}
         />
         <path
            d="M84.9425 18.4529V20.5955H86.669V22.0671H84.9425V27.0583C84.9425 27.8337 85.2882 28.195 86.0472 28.195C86.2521 28.1915 86.4568 28.1771 86.6602 28.152V29.6149C86.319 29.6785 85.9722 29.7073 85.6251 29.7009C83.787 29.7009 83.0701 29.0124 83.0701 27.2565V22.0671H81.75V20.5955H83.0701V18.4529H84.9425Z"
            fill={color}
         />
         <path
            d="M87.6689 25.1228C87.6689 22.2742 89.3514 20.4841 91.9749 20.4841C94.6073 20.4841 96.2819 22.2741 96.2819 25.1228C96.2819 27.9792 94.6161 29.7614 91.9749 29.7614C89.3347 29.7614 87.6689 27.9792 87.6689 25.1228ZM94.383 25.1228C94.383 23.1687 93.485 22.0154 91.9749 22.0154C90.4649 22.0154 89.5668 23.1775 89.5668 25.1228C89.5668 27.0847 90.4649 28.2292 91.9749 28.2292C93.485 28.2292 94.383 27.0847 94.383 25.1228H94.383Z"
            fill={color}
         />
         <path
            d="M97.8174 20.5953H99.5948V22.1363H99.638C99.7582 21.655 100.041 21.2297 100.439 20.9318C100.836 20.6339 101.325 20.4817 101.822 20.5006C102.037 20.4998 102.251 20.5231 102.46 20.5699V22.3082C102.189 22.2256 101.906 22.1877 101.623 22.1959C101.352 22.185 101.082 22.2326 100.832 22.3354C100.581 22.4383 100.356 22.5941 100.171 22.792C99.987 22.9898 99.8476 23.2252 99.763 23.4819C99.6783 23.7385 99.6503 24.0104 99.681 24.2789V29.649H97.8174L97.8174 20.5953Z"
            fill={color}
         />
         <path
            d="M111.054 26.99C110.803 28.6335 109.198 29.7614 107.145 29.7614C104.504 29.7614 102.864 27.9968 102.864 25.1658C102.864 22.3259 104.512 20.4841 107.066 20.4841C109.578 20.4841 111.158 22.2048 111.158 24.9499V25.5867H104.746V25.699C104.716 26.0322 104.758 26.3678 104.868 26.6837C104.979 26.9995 105.155 27.2883 105.386 27.5308C105.617 27.7734 105.897 27.9641 106.208 28.0904C106.519 28.2167 106.853 28.2757 107.188 28.2634C107.628 28.3045 108.07 28.2028 108.448 27.9734C108.826 27.744 109.119 27.399 109.285 26.99L111.054 26.99ZM104.754 24.2878H109.293C109.31 23.9882 109.265 23.6884 109.16 23.4072C109.055 23.126 108.892 22.8695 108.683 22.6537C108.474 22.4379 108.223 22.2676 107.944 22.1534C107.666 22.0392 107.367 21.9835 107.066 21.99C106.763 21.9882 106.462 22.0463 106.181 22.1611C105.9 22.2758 105.645 22.4449 105.43 22.6585C105.215 22.8721 105.045 23.1261 104.929 23.4058C104.813 23.6854 104.754 23.9852 104.754 24.2878V24.2878Z"
            fill={color}
         />
         <path
            d="M39.2961 7.88409C39.6868 7.85613 40.0789 7.91499 40.444 8.05642C40.8091 8.19786 41.1382 8.41834 41.4076 8.70196C41.6769 8.98558 41.8798 9.32528 42.0015 9.69655C42.1233 10.0678 42.1608 10.4614 42.1116 10.8489C42.1116 12.7552 41.0784 13.8509 39.2961 13.8509H37.1348V7.88409H39.2961ZM38.0641 13.0071H39.1923C39.4715 13.0237 39.7509 12.9781 40.0101 12.8735C40.2694 12.7688 40.502 12.6079 40.691 12.4023C40.88 12.1968 41.0207 11.9518 41.1028 11.6851C41.1849 11.4185 41.2063 11.137 41.1656 10.8611C41.2034 10.5862 41.1798 10.3065 41.0964 10.0418C41.0131 9.77715 40.872 9.53415 40.6834 9.33022C40.4949 9.12629 40.2634 8.96648 40.0056 8.86225C39.7478 8.75801 39.47 8.71193 39.1923 8.7273H38.0641V13.0071Z"
            fill={color}
         />
         <path
            d="M43.1614 11.5974C43.1331 11.3014 43.167 11.0029 43.2611 10.7208C43.3552 10.4387 43.5074 10.1794 43.7079 9.95941C43.9084 9.73943 44.1528 9.56367 44.4254 9.44339C44.698 9.32312 44.9928 9.26099 45.2909 9.26099C45.589 9.26099 45.8839 9.32312 46.1565 9.44339C46.4291 9.56367 46.6735 9.73943 46.874 9.95941C47.0745 10.1794 47.2267 10.4387 47.3208 10.7208C47.4149 11.0029 47.4488 11.3014 47.4204 11.5974C47.4494 11.8936 47.4158 12.1926 47.322 12.4751C47.2281 12.7576 47.076 13.0174 46.8755 13.2378C46.6749 13.4583 46.4303 13.6344 46.1574 13.7549C45.8846 13.8755 45.5894 13.9377 45.2909 13.9377C44.9925 13.9377 44.6973 13.8755 44.4244 13.7549C44.1515 13.6344 43.907 13.4583 43.7064 13.2378C43.5059 13.0174 43.3538 12.7576 43.2599 12.4751C43.1661 12.1926 43.1325 11.8936 43.1614 11.5974ZM46.5038 11.5974C46.5038 10.6213 46.0641 10.0505 45.2924 10.0505C44.5178 10.0505 44.082 10.6213 44.082 11.5974C44.082 12.5813 44.5178 13.1477 45.2924 13.1477C46.0641 13.1476 46.5038 12.5773 46.5038 11.5974H46.5038Z"
            fill={color}
         />
         <path
            d="M53.0814 13.8508H52.157L51.2237 10.5344H51.1532L50.2238 13.8508H49.3082L48.0635 9.3479H48.9674L49.7763 12.7839H49.8429L50.7713 9.3479H51.6262L52.5546 12.7839H52.6251L53.4301 9.3479H54.3212L53.0814 13.8508Z"
            fill={color}
         />
         <path
            d="M55.3682 9.34794H56.226V10.0633H56.2926C56.4056 9.80635 56.5961 9.59098 56.8377 9.44718C57.0792 9.30337 57.3598 9.2383 57.6402 9.26102C57.8599 9.24455 58.0805 9.27759 58.2857 9.35768C58.4908 9.43778 58.6753 9.56288 58.8254 9.72373C58.9755 9.88457 59.0874 10.077 59.1528 10.2868C59.2182 10.4967 59.2354 10.7185 59.2031 10.9358V13.8508H58.312V11.159C58.312 10.4354 57.9966 10.0755 57.3375 10.0755C57.1884 10.0686 57.0395 10.0939 56.901 10.1497C56.7626 10.2055 56.6379 10.2906 56.5355 10.3989C56.433 10.5073 56.3553 10.6365 56.3077 10.7776C56.26 10.9188 56.2435 11.0685 56.2593 11.2166V13.8509H55.3682L55.3682 9.34794Z"
            fill={color}
         />
         <path
            d="M60.623 7.59009H61.5142V13.8508H60.623V7.59009Z"
            fill={color}
         />
         <path
            d="M62.7532 11.5973C62.7248 11.3014 62.7588 11.0028 62.8529 10.7207C62.9471 10.4386 63.0993 10.1793 63.2998 9.9593C63.5003 9.73932 63.7447 9.56355 64.0174 9.44327C64.29 9.323 64.5848 9.26086 64.883 9.26086C65.1811 9.26086 65.4759 9.323 65.7485 9.44327C66.0212 9.56355 66.2656 9.73932 66.4661 9.9593C66.6666 10.1793 66.8188 10.4386 66.913 10.7207C67.0071 11.0028 67.0411 11.3014 67.0127 11.5973C67.0416 11.8936 67.008 12.1925 66.9141 12.4751C66.8202 12.7576 66.6681 13.0174 66.4676 13.2378C66.267 13.4582 66.0224 13.6343 65.7495 13.7549C65.4766 13.8754 65.1814 13.9377 64.883 13.9377C64.5845 13.9377 64.2893 13.8754 64.0164 13.7549C63.7435 13.6343 63.4989 13.4582 63.2984 13.2378C63.0978 13.0174 62.9457 12.7576 62.8518 12.4751C62.7579 12.1925 62.7243 11.8936 62.7532 11.5973ZM66.0956 11.5973C66.0956 10.6213 65.6559 10.0505 64.8842 10.0505C64.1095 10.0505 63.6738 10.6213 63.6738 11.5973C63.6738 12.5812 64.1096 13.1476 64.8842 13.1476C65.6559 13.1476 66.0956 12.5773 66.0956 11.5973H66.0956Z"
            fill={color}
         />
         <path
            d="M67.9502 12.5774C67.9502 11.7668 68.5554 11.2996 69.6297 11.2331L70.8529 11.1628V10.7742C70.8529 10.2986 70.5375 10.03 69.9284 10.03C69.4309 10.03 69.0862 10.2122 68.9873 10.5305H68.1245C68.2156 9.75707 68.9452 9.26099 69.9695 9.26099C71.1016 9.26099 71.7401 9.82299 71.7401 10.7742V13.8508H70.8822V13.218H70.8117C70.6686 13.445 70.4676 13.63 70.2293 13.7543C69.9911 13.8785 69.724 13.9374 69.4554 13.925C69.2658 13.9447 69.0742 13.9245 68.893 13.8658C68.7117 13.8071 68.5448 13.7112 68.403 13.5842C68.2611 13.4572 68.1476 13.302 68.0696 13.1286C67.9916 12.9552 67.9509 12.7674 67.9502 12.5774ZM70.8529 12.1926V11.8161L69.7502 11.8865C69.1283 11.928 68.8463 12.1389 68.8463 12.5359C68.8463 12.9411 69.1988 13.177 69.6836 13.177C69.8256 13.1913 69.9691 13.177 70.1055 13.1349C70.2419 13.0928 70.3684 13.0238 70.4775 12.932C70.5866 12.8401 70.676 12.7273 70.7405 12.6003C70.805 12.4733 70.8432 12.3347 70.8529 12.1926Z"
            fill={color}
         />
         <path
            d="M72.9111 11.5974C72.9111 10.1746 73.6446 9.2732 74.7855 9.2732C75.0677 9.26024 75.3478 9.32764 75.593 9.46753C75.8382 9.60743 76.0384 9.81403 76.1703 10.0632H76.2369V7.59009H77.128V13.8508H76.2741V13.1394H76.2036C76.0616 13.3869 75.8544 13.5909 75.6044 13.7294C75.3545 13.8679 75.0713 13.9355 74.7855 13.925C73.6368 13.9251 72.9111 13.0237 72.9111 11.5974ZM73.8317 11.5974C73.8317 12.5525 74.2832 13.1272 75.0382 13.1272C75.7893 13.1272 76.2535 12.5442 76.2535 11.6013C76.2535 10.6628 75.7844 10.0715 75.0382 10.0715C74.288 10.0715 73.8317 10.6501 73.8317 11.5974H73.8317Z"
            fill={color}
         />
         <path
            d="M80.8157 11.5974C80.7873 11.3014 80.8213 11.0029 80.9154 10.7208C81.0095 10.4387 81.1617 10.1794 81.3622 9.95941C81.5627 9.73943 81.8071 9.56367 82.0797 9.44339C82.3523 9.32312 82.6471 9.26099 82.9452 9.26099C83.2433 9.26099 83.5381 9.32312 83.8108 9.44339C84.0834 9.56367 84.3278 9.73943 84.5283 9.95941C84.7288 10.1794 84.881 10.4387 84.9751 10.7208C85.0692 11.0029 85.1031 11.3014 85.0747 11.5974C85.1037 11.8936 85.0701 12.1926 84.9763 12.4751C84.8824 12.7576 84.7303 13.0174 84.5298 13.2378C84.3292 13.4583 84.0846 13.6344 83.8117 13.7549C83.5388 13.8755 83.2437 13.9377 82.9452 13.9377C82.6468 13.9377 82.3516 13.8755 82.0787 13.7549C81.8058 13.6344 81.5613 13.4583 81.3607 13.2378C81.1602 13.0174 81.0081 12.7576 80.9142 12.4751C80.8203 12.1926 80.7868 11.8936 80.8157 11.5974ZM84.1581 11.5974C84.1581 10.6213 83.7184 10.0505 82.9467 10.0505C82.1721 10.0505 81.7363 10.6213 81.7363 11.5974C81.7363 12.5813 82.1721 13.1477 82.9467 13.1477C83.7184 13.1476 84.1581 12.5773 84.1581 11.5974Z"
            fill={color}
         />
         <path
            d="M86.2695 9.34794H87.1274V10.0633H87.194C87.307 9.80635 87.4975 9.59098 87.7391 9.44718C87.9806 9.30337 88.2611 9.2383 88.5415 9.26102C88.7612 9.24455 88.9819 9.27759 89.187 9.35768C89.3922 9.43778 89.5767 9.56288 89.7268 9.72373C89.8769 9.88457 89.9888 10.077 90.0542 10.2868C90.1195 10.4967 90.1367 10.7185 90.1045 10.9358V13.8508H89.2133V11.159C89.2133 10.4354 88.898 10.0755 88.2389 10.0755C88.0897 10.0686 87.9408 10.0939 87.8024 10.1497C87.6639 10.2055 87.5392 10.2906 87.4368 10.3989C87.3344 10.5073 87.2567 10.6365 87.2091 10.7776C87.1614 10.9188 87.1449 11.0685 87.1607 11.2166V13.8509H86.2695V9.34794Z"
            fill={color}
         />
         <path
            d="M95.1402 8.22681V9.3684H96.1186V10.1169H95.1402V12.4324C95.1402 12.904 95.3351 13.1106 95.7788 13.1106C95.8923 13.1102 96.0058 13.1034 96.1186 13.0901V13.8303C95.9585 13.8588 95.7964 13.874 95.6338 13.8757C94.6428 13.8757 94.2481 13.528 94.2481 12.6599V10.1169H93.5312V9.36836H94.2481V8.22681H95.1402Z"
            fill={color}
         />
         <path
            d="M97.3369 7.59009H98.2202V10.0715H98.2907C98.4092 9.81221 98.605 9.59562 98.8514 9.45127C99.0978 9.30693 99.3828 9.24184 99.6676 9.26489C99.8862 9.25303 100.105 9.28942 100.308 9.37146C100.51 9.4535 100.693 9.57918 100.841 9.73951C100.99 9.89985 101.101 10.0909 101.167 10.299C101.233 10.5072 101.252 10.7273 101.223 10.9436V13.8508H100.331V11.1628C100.331 10.4436 99.9947 10.0793 99.3651 10.0793C99.2119 10.0668 99.0579 10.0878 98.9137 10.1409C98.7695 10.1939 98.6387 10.2777 98.5304 10.3865C98.4221 10.4952 98.3389 10.6262 98.2867 10.7704C98.2345 10.9145 98.2145 11.0683 98.2281 11.2209V13.8508H97.337L97.3369 7.59009Z"
            fill={color}
         />
         <path
            d="M106.419 12.6349C106.298 13.0465 106.036 13.4025 105.678 13.6406C105.32 13.8788 104.89 13.9839 104.463 13.9377C104.165 13.9455 103.869 13.8887 103.596 13.7712C103.323 13.6536 103.079 13.4782 102.88 13.2571C102.682 13.0359 102.534 12.7744 102.447 12.4906C102.36 12.2067 102.336 11.9075 102.377 11.6135C102.337 11.3186 102.361 11.0187 102.448 10.734C102.535 10.4493 102.682 10.1865 102.879 9.96347C103.077 9.7404 103.32 9.56223 103.592 9.44102C103.865 9.31982 104.16 9.2584 104.459 9.26094C105.715 9.26094 106.473 10.1169 106.473 11.5309V11.841H103.284V11.8908C103.271 12.0561 103.291 12.2224 103.346 12.3791C103.4 12.5359 103.486 12.6796 103.6 12.8011C103.713 12.9225 103.85 13.019 104.003 13.0844C104.156 13.1498 104.321 13.1827 104.487 13.1808C104.7 13.2063 104.916 13.1681 105.108 13.0709C105.299 12.9736 105.457 12.8219 105.561 12.6349L106.419 12.6349ZM103.284 11.1838H105.565C105.576 11.0327 105.556 10.8809 105.505 10.7381C105.454 10.5954 105.373 10.4649 105.269 10.3551C105.164 10.2452 105.037 10.1584 104.897 10.1002C104.757 10.0421 104.606 10.0138 104.454 10.0173C104.3 10.0154 104.147 10.0442 104.004 10.1022C103.861 10.1601 103.731 10.246 103.622 10.3547C103.513 10.4634 103.427 10.5928 103.369 10.7351C103.311 10.8775 103.282 11.0301 103.284 11.1838H103.284Z"
            fill={color}
         />
      </svg>
   )
}

AppStoreButton.defaultProps = {
   color: '#000'
}