import { Config } from "./config";
import { handleErrors, authHeader } from "./utils";
import { IChat, IMessage, ICreationMessage } from "./model";

class API {
  public async fetchUserChats(
    authToken: string,
    filters?: { limit: number; offset: number; query: string }
  ): Promise<IChat[]> {
    const url = new URL(Config.API_ENDPOINT_GET_USER_CHATS);

    if (filters) {
      const { limit, offset, query } = filters;

      if (limit) url.searchParams.append("limit", limit.toString());
      if (offset) url.searchParams.append("offset", offset.toString());
      if (query) url.searchParams.append("query", query);
    }

    return handleErrors(
      fetch(url.toString(), {
        headers: authHeader(authToken),
      })
    );
  }

  public async fetchChatMessages(
    chatId: number,
    authToken: string,
    filters?: { limit?: number; offset?: number }
  ): Promise<IMessage[]> {
    const url = new URL(Config.API_ENDPOINT_GET_CHAT_MESSAGES(chatId));

    if (filters) {
      const { limit, offset } = filters;

      if (limit) url.searchParams.append("limit", limit.toString());
      if (offset) url.searchParams.append("offset", offset.toString());
    }

    return handleErrors(
      fetch(url.toString(), {
        headers: authHeader(authToken),
      })
    );
  }

  public async readMessages(
    ids: number[],
    authToken: string
  ): Promise<{ updated: boolean }> {
    return handleErrors(
      fetch(Config.API_ENDPOINT_READ_MESSAGE, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...authHeader(authToken),
        },
        body: JSON.stringify({
          messageIds: ids,
        }),
      })
    );
  }

  public async addMessage(
    authToken: string,
    chatId: number,
    message: ICreationMessage
  ): Promise<IMessage> {
    const formData = new FormData();

    formData.append("chatId", chatId.toString());
    formData.append("content", message.content);

    for (const attachment of message.attachments) {
      formData.append("attachments", attachment as any);
    }

    return handleErrors(
      fetch(Config.API_ENDPOINT_CREATE_MESSAGE, {
        method: "POST",
        headers: authHeader(authToken),
        body: formData,
      })
    );
  }
}

export const ChatAPI = new API();
