import { Form, Formik } from "formik";
import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useDispatch, useSelector } from "react-redux";
import { CloseIcon } from "../../assets/img/icons/Close";

// Icons
import { LogoIcon } from "../../assets/img/icons/Logo";
import Loader from "../../components/Loader";

// Components
import { LoginInput, PasswordInput } from "../../components/auth";
import PopupDownloadApp from "../../components/PopupDownloadApp";

// constants
import { deviceTokenKey } from "../../constants/Keys";

import {
  DevicePlatformEnum,
  // getUserAttributes,
  getUserState,
  loginAction,
  setUserStateAction,
} from "../../store";
import { getFCMToken } from "../../utils/FCMToken";

// Utils
import { uuid } from "../../utils/uuid";

interface IFields {
  login: string;
  password: string;
}

export const Logindiv: React.FC = () => {
  const dispatch = useDispatch();
  const userState = useSelector(getUserState);
  // const userAttributes = useSelector(getUserAttributes);
  // const [secondScreen, showSecond] = useState(false);
  const initialValues: IFields = {
    login: "",
    password: "",
  };

  React.useEffect(() => {
    if (window.location.pathname !== "" && window.location.pathname !== "/") {
      window.location.href = "/";
    }
  }, []);

  const login = async (login: string, password: string) => {
    dispatch(
      setUserStateAction({
        message: "Starta autentiseringssession",
        isLoading: true,
      })
    );

    let deviceToken = await localStorage.getItem(deviceTokenKey);
    if (!deviceToken) {
      deviceToken = uuid();
      localStorage.setItem(deviceTokenKey, deviceToken);
    }

    dispatch(
      setUserStateAction({
        message: "Kunde inte logga in! Försök senare.",
        isLoading: false,
        error: true,
      })
    );

    try {
      // const data = await UserAPI.initBankIdSession(personalNumber);
      const FCMToken = await getFCMToken();
      // if (data && data.autoStartToken && data.sessionId) {
      dispatch(
        loginAction({
          credentials: {
            login,
            password,
            FCMToken,
            platform: DevicePlatformEnum.Web,
          },
        })
      );
      // dispatch(initAuthPollingAction({
      //   sessionId: data.sessionId,
      //   deviceToken,
      //   FCMToken: FCMToken,
      //   platform: DevicePlatformEnum.Web
      // }));
      dispatch(
        setUserStateAction({
          message: "Ange lösenord i BankId app",
          isLoading: true,
          error: false,
        })
      );
      // } else {
      //   throw new Error()
      // }
    } catch (error) {
      dispatch(
        setUserStateAction({
          message: "Kunde inte logga in! Försök senare.",
          isLoading: false,
          error: true,
        })
      );
    }
  };

  const handleSubmit = (values: IFields) => {
    login(values.login, values.password);
  };

  // if (secondScreen) return <LoginSecondStepView/>

  return (
    <>
      <section className="login">
        <PopupDownloadApp />
        <Scrollbars>
          <div className="container">
            <div className="logo-wrap">
              <LogoIcon />
            </div>
            <Formik
              isInitialValid={false}
              onSubmit={handleSubmit}
              initialValues={initialValues}
              // validate={validation}
            >
              {({ handleSubmit, isValid }) => (
                <Form
                  className="form form-login"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                  translate={undefined}
                >
                  <LoginInput name="login" />
                  <PasswordInput name="password" />
                  <button
                    disabled={!isValid}
                    type={"button"}
                    className="btn btn-lighter"
                    onClick={() => handleSubmit()}
                  >
                    Logga in
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </Scrollbars>
      </section>
      {userState?.isLoading || userState?.error ? (
        <div
          className={
            "modal-wrap" +
            (userState?.isLoading || userState?.error ? " active" : "")
          }
          data-id="2"
        >
          <div className="modal modal-active">
            {!!userState?.error && (
              <button
                className="modal-close"
                onClick={() =>
                  dispatch(
                    setUserStateAction({
                      message: "",
                      isLoading: false,
                      error: false,
                    })
                  )
                }
              >
                <CloseIcon className="close-icon" fillColor={"#059483"} />
              </button>
            )}
            <div className="modal-content">
              {!userState.error && (
                <div className={"load-more-loader"}>
                  <Loader />
                </div>
              )}
              <span className="text">
                {userState.message && (
                  <span className="error-message">{userState.message}</span>
                )}
              </span>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Logindiv;
