import { all } from "redux-saga/effects";
import { createReducer, ActionType } from "typesafe-actions";

// Models
import { StateTargetsState } from "./model";
import { IStore, ISocketState, IStateWithTarget } from "./../model";
import { IChat, IMessage, ICreationMessage } from "./../../API/model";

// Handlers
import { listenChatSocketSaga } from "./handlers/listen";
import { addMessageSaga, readMessagesSaga } from "./handlers/messages";
import {
  fetchUserChatSaga,
  fetchChatMessagesSaga,
  fetchAdditionalMessagesSaga,
} from "./handlers/fetch";

// Actions
import * as actions from "./actions";
export * from "./actions";

export type ChatActionType = ActionType<typeof actions>;

export const chatSaga = function* () {
  yield all([
    fetchUserChatSaga(),
    fetchChatMessagesSaga(),
    listenChatSocketSaga(),
    addMessageSaga(),
    readMessagesSaga(),
    fetchAdditionalMessagesSaga(),
  ]);
};

export interface IChatState {
  chats: IChat[] | null;
  state: IStateWithTarget<StateTargetsState> | null;
  socketState: ISocketState;
  uploadedAllMessagesFor: number[];
  messages: Record<number, Array<IMessage | ICreationMessage>>;
}

/* Reducer */
const initialState: IChatState = {
  chats: null,
  state: null,
  messages: {},
  uploadedAllMessagesFor: [],
  socketState: {
    isConnected: false,
  },
};

export const chatReducer = createReducer<IChatState, ChatActionType>(
  initialState
)
  .handleAction(actions.fetchUserChatsAction.success, (state, { payload }) => ({
    ...state,
    chats: payload.chats,
  }))
  .handleAction(
    actions.fetchChatMessagesAction.success,
    (state, { payload }) => ({
      ...state,
      messages: {
        ...state.messages,
        [payload.chatId]: payload.messages,
      },
    })
  )
  .handleAction(actions.setChatStateAction, (state, { payload }) => ({
    ...state,
    state: payload,
  }))
  .handleAction(actions.addMessageAction.request, (state, { payload }) => {
      const { chatId, message } = payload;

      return {
        ...state,
        messages: {
          ...state.messages,
          [chatId]: [message, ...state.messages[chatId]],
        },
      };
    }
  )
  .handleAction(actions.addMessageAction.failure, (state, { payload }) => {
    const { chatId, message } = payload;

    return {
      ...state,
      messages: {
        ...state.messages,
        [chatId]: state.messages[chatId]?.map(msg => {
          if (msg.id === message.id) {
            return { ...message };
          }

          return msg;
        }) || []
      },
    };
  })
  .handleAction(
    actions.fetchAdditionalMessagesAction.success,
    (state, { payload }) => {
      const { chatId, messages, isAllUploaded } = payload;
      const uploadedFor = [...state.uploadedAllMessagesFor];

      if (isAllUploaded) {
        uploadedFor.push(chatId);
      }

      return {
        ...state,
        uploadedAllMessagesFor: uploadedFor,
        messages: {
          ...state.messages,
          [chatId]: [...state.messages[chatId], ...messages],
        },
      };
    }
  )
  .handleAction(actions.addMessageAction.success, (state, { payload }) => {
    const { chatId, message, tempMessageId } = payload;

    const chats = state.chats?.map((chat) => {
      if (chat.id === chatId) {
        return { ...chat, lastMessage: message };
      } else {
        return chat;
      }
    });

    return {
      ...state,
      chats: chats || null,
      messages: {
        ...state.messages,
        [chatId]: [
          message,
          ...state.messages[chatId]?.filter((msg) => msg.id !== tempMessageId),
        ],
      },
    };
  })
  .handleAction(actions.chatSocketNewMessageAction, (state, { payload }) => {
    const { message } = payload;

    const chatId = message.chatId;
    const messages = { ...state.messages };
    const chats = state.chats?.map((chat) => {
      if (chat.id === chatId) {
        return {
          ...chat,
          unreadNumber: chat.unreadNumber + 1,
          lastMessage: message,
        };
      } else {
        return chat;
      }
    });

    if (state.messages[chatId]) {
      messages[chatId] = [message, ...state.messages[chatId]];
    }

    return {
      ...state,
      messages,
      chats: chats || null,
    };
  })
  .handleAction(
    actions.readMessagesInChatAction.success,
    (state, { payload }) => {
      const { chatId } = payload;

      const chats = state.chats?.map((chat) => {
        if (chat.id === chatId) {
          return { ...chat, unreadNumber: 0 };
        } else {
          return chat;
        }
      });
      const messages = state.messages[chatId].map((message) => ({
        ...message,
        isReaded: true,
      }));

      return {
        ...state,
        chats: chats || null,
        messages: { ...state.messages, [chatId]: messages },
      };
    }
  )
  .handleAction(actions.chatSocketNewChatAction, (state, { payload }) => ({
    ...state,
    chats: state.chats ? [...state.chats.filter(chat => chat.id !== payload.chat.id), payload.chat] : [payload.chat]
  }))
  .handleAction(actions.chatSocketDeleteChatAction, (state, { payload }) => ({
    ...state,
    chats: state.chats?.filter(chat => chat.id !== payload.chatId) || null
  }))
  .handleAction(actions.chatSocketConnectedAction, (state) => ({
    ...state,
    socketState: { isConnected: true },
  }))
  .handleAction(actions.chatSocketDisconnectedAction as any, (state) => ({
    ...state,
    socketState: { isConnected: false },
  }));

/* Selectors */
export const getChatState = (state: IStore) => state.chat.state;
export const getUserChats = (state: IStore) => state.chat.chats;
export const getTotalUnreadedMessagesNumber = (store: IStore): number | null => {
  return store.chat.chats?.reduce((acc, chat) => acc + chat.unreadNumber, 0) || null;
};
export const getTotalUnreadedChatsNumber = (store: IStore) => {
  return store.chat.chats?.reduce((acc, chat) => chat.unreadNumber > 0 ? acc + 1 : acc, 0) || null;
};
export const getAllMessagesUploadedStatus = (chatId: number) => (
  state: IStore
): boolean => {
  return !!state.chat.uploadedAllMessagesFor.find((id) => chatId === id);
};
export const getChatMessages = (chatId: number) => (
  state: IStore
): Array<IMessage | ICreationMessage> | undefined => {
  return state.chat.messages[chatId];
};
export const getAllMessages = (store: IStore) => store.chat.messages;
export const getChat = (chatId: number) => (state: IStore) => {
  return state.chat.chats?.find((chat) => chat.id === chatId);
};
