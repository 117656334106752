import { APP_PREFIX } from '../constants'

class TimeStorageService {
  oneWeekMs = 604800000

  saveClosedTime(time: Date = new Date()): void {
    try {
      window.localStorage.setItem(this.closedTimeKey, String(time))
    } catch (e) {
      console.error(e)
    }
  }

  private get closedTimeKey(): string {
    return `${APP_PREFIX}/download-app-popup-closed`
  }

  get closedTime(): Date | null | undefined {
    try {
      const closedTime = window.localStorage.getItem(this.closedTimeKey)
      if (closedTime === null) {
        return null
      }
      return new Date(closedTime)
    } catch (e) {
      return undefined
    }
  }

  get popupWasClosedRecently(): boolean {
    if (this.closedTime) {
      return  Number(new Date()) - Number(this.closedTime) < this.oneWeekMs
    }
    return false
  }

  clearClosedTime() {
    try {
      if (window.localStorage.getItem(this.closedTimeKey)) {
        window.localStorage.removeItem(this.closedTimeKey)
      }
      window.location.reload()
    } catch (e) {
      console.error(e)
    }
  }  
}

export const timeStorageService = new TimeStorageService()
