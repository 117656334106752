import React from 'react';

interface IProgressIconProps {
  style?: React.CSSProperties;
  fillColor?: string;
  className?: string;
}

export const ProgressIcon: React.FC<IProgressIconProps> = props => {
  const { style, fillColor, className } = props;

  return (
    <svg style={style} className={className} viewBox="0 0 12 12">
      <path
        d="M3.54519 5.99998C3.54519 5.69875 3.30097 5.45453 2.99973 5.45453H0.817916C0.516679 5.45453 0.272461 5.69875 0.272461 5.99998C0.272461 6.30122 0.516679 6.54544 0.817916 6.54544H2.99973C3.30097 6.54544 3.54519 6.30122 3.54519 5.99998Z"
        fill={fillColor || '#CA2C92'}
      />

      <path
        d="M11.1813 5.45453H10.0904C9.78914 5.45453 9.54492 5.69875 9.54492 5.99998C9.54492 6.30122 9.78914 6.54544 10.0904 6.54544H11.1813C11.4825 6.54544 11.7267 6.30122 11.7267 5.99998C11.7267 5.69875 11.4825 5.45453 11.1813 5.45453Z"
        fill={fillColor || '#CA2C92'}
      />

      <path
        d="M6.27299 3.27273C6.57423 3.27273 6.81845 3.02851 6.81845 2.72727V0.545455C6.81845 0.244218 6.57423 0 6.27299 0C5.97176 0 5.72754 0.244218 5.72754 0.545455V2.72727C5.72754 3.02851 5.97176 3.27273 6.27299 3.27273Z"
        fill={fillColor || '#CA2C92'}
      />

      <path
        d="M6.27202 8.72729C5.97078 8.72729 5.72656 8.97151 5.72656 9.27275V11.4546C5.72656 11.7558 5.97078 12 6.27202 12C6.57325 12 6.81747 11.7558 6.81747 11.4546V9.27275C6.81747 8.97151 6.57325 8.72729 6.27202 8.72729Z"
        fill={fillColor || '#CA2C92'}
      />

      <path
        d="M2.80126 1.75735C2.58832 1.54433 2.24294 1.54436 2.02988 1.75735C1.81686 1.97036 1.81686 2.31571 2.02988 2.52873L3.57268 4.07156C3.67919 4.17807 3.81879 4.23135 3.95835 4.23135C4.09792 4.23135 4.23755 4.17807 4.34403 4.0716C4.55704 3.85858 4.55704 3.51324 4.34403 3.30022L2.80126 1.75735Z"
        fill={fillColor || '#CA2C92'}
      />

      <path
        d="M8.97215 7.92847C8.75916 7.71549 8.41378 7.71549 8.20077 7.92847C7.98775 8.14149 7.98778 8.48684 8.20077 8.69986L9.7436 10.2426C9.85011 10.3491 9.98971 10.4024 10.1293 10.4024C10.2689 10.4024 10.4085 10.3491 10.515 10.2426C10.728 10.0296 10.728 9.68426 10.515 9.47124L8.97215 7.92847Z"
        fill={fillColor || '#CA2C92'}
      />

      <path
        d="M3.57264 7.9285L2.02988 9.47127C1.81686 9.68428 1.81686 10.0296 2.02988 10.2426C2.13639 10.3492 2.27599 10.4024 2.41559 10.4024C2.55519 10.4024 2.69479 10.3492 2.80126 10.2426L4.34403 8.69988C4.55704 8.48687 4.55704 8.14152 4.34403 7.9285C4.13101 7.71548 3.78563 7.71548 3.57264 7.9285Z"
        fill={fillColor || '#CA2C92'}
      />
    </svg>
  );
};
