import React from 'react';
// import { OTSession, OTSubscriber, OTStreams } from 'opentok-react';
import {
  EventsAPI,
  IJoinToTheCallResponse,
  getAuthToken,
  IFullEvent
} from '../../store';
import { useSelector } from 'react-redux';
import {generatePath, RouteComponentProps} from "react-router";

// constants
import { UserTypeEnum } from '../../constants';

// Components
import { Loader } from '../../components/Loader';
import OTSessionWrapper from "../../components/OTSessionWrapper";

//routing
import schema from "../../routing";

interface IEventProps extends RouteComponentProps<{
  id: string;
}> {}


export const Event: React.FC<IEventProps> = props => {
  const { id: eventId } = props.match.params;

  const [event, setEvent] = React.useState<IFullEvent | null>(null);

  const [isLoading, setLoadingStatus] = React.useState(true);

  const authToken = useSelector(getAuthToken);

  const [
    credentials,
    setCredentials,
  ] = React.useState<IJoinToTheCallResponse | null>(null);
  const doctor = event?.participants.find(
    (user:any) => user.type.slug === UserTypeEnum.Doctor,
  );

  const cancelBtnClick = () => {
    props.history.push(`${generatePath(schema.getLink('event'), { id: eventId })}`)
  };

  React.useEffect(() => {
    if (authToken) {
      setLoadingStatus(true);

      EventsAPI.fetchEventDetails(Number(eventId), authToken)
        .then(event => {
          if (event) setEvent(event);
          setLoadingStatus(false);
        })
        .catch((e) => process.env.REACT_APP_SHOW_LOGS === "true" && console.error(e));
    }
  }, [authToken]);

  // Layout effects
  React.useEffect(() => {
    if (authToken && event) {
      (async () => {
        try {
          const callCredentials = await EventsAPI.joinToTheCall(
            event.id,
            authToken,
          );

          if (callCredentials) {
            setCredentials(callCredentials);
          }
        } catch (error) {
          process.env.REACT_APP_SHOW_LOGS === "true" && console.error(error);
        }
      })();
    }

    return () => {
    };
  }, [event]);

  if (!credentials || !event || isLoading) {
    return <Loader />;
  }

  return (<OTSessionWrapper doctor={doctor} credentials={credentials} cancelBtnClick={cancelBtnClick} event={event}/>);
};
