import React from 'react';
import {Route, Redirect, Switch} from 'react-router-dom';

//Custom components
import RoutingSchema from './index';
import {RouteComponentProps} from 'react-router';

const Routes = RoutingSchema.getSchema.map(
  ({wrapper: Wrapper, component: Component, path, name}) => {

    if (Wrapper) {
      return <Route
        exact
        key={name}
        path={path}
        component={
          (props: RouteComponentProps) => <Wrapper><Component {...props}/></Wrapper>
        }
      />
    }

    return <Route
      exact
      key={name}
      path={path}
      component={Component}
    />
  }
);

export const Routing: React.FC<{}> = () => {
  return (
    <Switch>
      {Routes}
      <Redirect to={'/conversations'}/>
    </Switch>
  );
}

export default Routing;
