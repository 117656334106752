import React from 'react'
import { notificationsService, NotificationsEnum } from '../services/notificationsService'
import { CheckIcon } from '../assets/img/icons/Check'
import { ErrorIcon } from '../assets/img/icons/Error'
import { CloseIcon } from '../assets/img/icons/Close'

const { emitter } = notificationsService

const Config = {
  HIDE_POS: 0,
  SHOW_POS: 78,
  HIDE_IN: 2500
}

type Props = {
  hideIn?: number
}

type State = {
  position: number
  notificationMessage: string
}

export class Notification extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    emitter.on(NotificationsEnum.SUCCESS, (message: string) => {
      this.notificationType = NotificationsEnum.SUCCESS
      this.onShow(message)
    })
    emitter.on(NotificationsEnum.ERROR, (message: string) => {
      this.notificationType = NotificationsEnum.ERROR
      this.onShow(message)
    })
  }

  notificationType: NotificationsEnum | undefined
  timeout: ReturnType<typeof setTimeout> | null = null

  state: Readonly<State> = {
    position: Config.HIDE_POS,
    notificationMessage: ''
  }

  onShow(message: string): void {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    this.showNotification(message)
  }

  showNotification(message: string, time?: number): void {
    this.setState({
      position: Config.SHOW_POS,
      notificationMessage: message
    }, () => {
      this.timeout = setTimeout(() => {
        this.setState({
          position: Config.HIDE_POS
        })
      }, time || this.props.hideIn || Config.HIDE_IN);
    })
  }

  get notificationIcon(): JSX.Element {
    switch (this.notificationType) {
      case NotificationsEnum.SUCCESS:
        return <CheckIcon className="notification-icon" />
      case NotificationsEnum.ERROR:
        return <ErrorIcon className="notification-icon" />
      default:
        return <div/>
    }
  }

  get closeIconColor(): string {
    switch (this.notificationType) {
      case NotificationsEnum.SUCCESS:
        return "#059483"
      case NotificationsEnum.ERROR:
        return "#dc3545"
      default:
        return "transparent"
    }
  }

  render() {
    const { position, notificationMessage } = this.state

    return (
      <div className="notification-wrap" style={{ bottom: position }}>
        <div className={`notification ${this.notificationType}`}>
          { this.notificationIcon }
          <span className="notification-text">{ notificationMessage }</span>
          <button className="btn-close">
            <CloseIcon className="notification-icon-close" fillColor={this.closeIconColor}/>
          </button>
        </div>
      </div>
    )
  }
}
