import { getChatMessages } from '../index';
import { take, select, put } from "redux-saga/effects";

// API
import { ChatAPI } from '../../../API';

// Actions
import {
  fetchChatMessagesAction,
  fetchAdditionalMessagesAction,
  fetchUserChatsAction,
  setChatStateAction,
} from '../actions';
import { forceLogout } from "../../User/handlers/auth";

// Selectors
import { getAuthToken, } from "../../User";

// Exceptions
import { Unauthorized } from "../../../Exceptions";

export const INITIAL_MESSAGES_FETCH_NUMBER = 30;
export function* fetchChatMessagesSaga() {
  while (true) {
    const {
      payload,
    }: ReturnType<typeof fetchChatMessagesAction.request> = yield take(
      fetchChatMessagesAction.request
    );

    const authToken: string = yield select(getAuthToken);
    yield put(
      setChatStateAction({
        isLoading: true,
        message: "Please wait, messages are loading!",
        target: "messages",
      })
    );

    try {
      if (!authToken) {
        throw new Error("Credentials wasn`t provided!");
      }

      // @ts-ignore
      const messages = yield ChatAPI.fetchChatMessages(
        payload.chatId,
        authToken,
        { limit: INITIAL_MESSAGES_FETCH_NUMBER }
      );

      yield put(
        fetchChatMessagesAction.success({
          chatId: payload.chatId,
          messages,
        })
      );
      yield put(
        setChatStateAction({
          isLoading: false,
          message: "Messages were loaded!",
          target: "messages",
        })
      );
    } catch (error) {
      yield put(fetchChatMessagesAction.failure());
      yield put(
        setChatStateAction({
          isLoading: false,
          message: "Failed to load messages!",
          target: "messages",
        })
      );

      if (error instanceof Unauthorized) {
        yield forceLogout();
      }
    }
  }
}

export function* fetchAdditionalMessagesSaga() {
  while (true) {
    const {
      payload,
    }: ReturnType<typeof fetchAdditionalMessagesAction.request> = yield take(
      fetchAdditionalMessagesAction.request
    );

    const authToken: string = yield select(getAuthToken);
    const chatMessages: string = yield select(getChatMessages(payload.chatId));
    yield put(
      setChatStateAction({
        isLoading: true,
        message: "Please wait, messages are loading!",
        target: "additional-messages",
      })
    );

    try {
      if (!authToken) {
        throw new Error("Credentials wasn`t provided!");
      }

      // @ts-ignore
      const messages = yield ChatAPI.fetchChatMessages(
        payload.chatId,
        authToken,
        {
          limit: INITIAL_MESSAGES_FETCH_NUMBER,
          offset: chatMessages?.length || 0,
        }
      );

      yield put(
        fetchAdditionalMessagesAction.success({
          chatId: payload.chatId,
          isAllUploaded: messages?.length === 0,
          messages,
        })
      );
      yield put(
        setChatStateAction({
          isLoading: false,
          message: "Messages were loaded!",
          target: "additional-messages",
        })
      );
    } catch (error) {
      yield put(fetchAdditionalMessagesAction.failure());
      yield put(
        setChatStateAction({
          isLoading: false,
          message: "Failed to load messages!",
          target: "additional-messages",
        })
      );

      if (error instanceof Unauthorized) {
        yield forceLogout();
      }
    }
  }
}

export function* fetchUserChatSaga() {
  while (true) {
    yield take(fetchUserChatsAction.request);

    const authToken: string = yield select(getAuthToken);
    console.log('authToken: ', authToken);
    yield put(
      setChatStateAction({
        isLoading: true,
        message: "Please wait, chats are loading!",
        target: "chat",
      })
    );

    try {
      if (!authToken) {
        throw new Error("Credentials wasn`t provided!");
      }

      // @ts-ignore
      const chats = yield ChatAPI.fetchUserChats(authToken);

      yield put(
        fetchUserChatsAction.success({
          chats,
        })
      );
      yield put(
        setChatStateAction({
          isLoading: false,
          message: "Chats were loaded!",
          target: "chat",
        })
      );
    } catch (error) {
      console.log('error: ', error);
      yield put(fetchUserChatsAction.failure());
      yield put(
        setChatStateAction({
          isLoading: false,
          message: "Failed to load chats!",
          target: "chat",
        })
      );

      if (error instanceof Unauthorized) {
        yield forceLogout();
      }
    }
  }
}
