import { take, put } from 'redux-saga/effects';

// Api
import { EventsAPI } from '../../../API';

// Actions
import { setUnreadedNumber } from '../actions';
import { setUserDataAction } from '../../User';

export function* initEventsSaga() {
  while(true) {
    const { payload }: ReturnType<typeof setUserDataAction> = yield take(setUserDataAction);

    try {
      const res: { count: number } = yield EventsAPI.getNumberOfNew(payload.authData.accessToken);

      if (typeof res?.count === "number") {
        yield put(setUnreadedNumber(res.count));
      }
    } catch (error) {
      console.log(error);
    }
  }
}
