import { take, select, put } from "redux-saga/effects";

// Types
import { IUserInfo } from '../../../API';

// Selectors
import { getAuthToken } from '../index';

// API
import { UserAPI } from '../../../API';

// Exceptions
import { Unauthorized } from '../../../Exceptions';

// Actions
import {
  updateProfileAction,
  setUserStateAction,
} from '../actions';
import { forceLogout } from "./auth";

export function* updateSaga() {
  while (true) {
    const {
      payload,
    }: ReturnType<typeof updateProfileAction.request> = yield take(
      updateProfileAction.request
    );

    yield put(
      setUserStateAction({
        isLoading: true,
        message: "Updating profile",
      })
    );

    let message: string;
    const authToken: string = yield select(getAuthToken);

    try {
      const updatedUserData = (yield UserAPI.updateProfile(
        authToken,
        payload.data
      )) as IUserInfo;

      if (updatedUserData) {
        yield put(updateProfileAction.success({ updatedUserData }));
      } else {
        yield put(updateProfileAction.failure());
      }

      message = updatedUserData ? "Uppdateringen lyckades" : "Uppdatering misslyckades";
      yield put(
        setUserStateAction({
          isLoading: false,
          message,
          error: !updatedUserData,
        })
      );
    } catch (error) {
      message = "Uppdatering misslyckades";
      if (error instanceof Unauthorized) {
        yield forceLogout();
      }

      yield put(
        setUserStateAction({
          isLoading: false,
          message,
          error: true,
        })
      );
    }

    if (typeof payload.cb === "function") {
      payload.cb(message);
    }
  }
}
