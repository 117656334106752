import React, { useEffect } from 'react';
import { IEvent } from '../store';
import {
  isWithinInterval,
  differenceInHours,
  formatDistance,
} from 'date-fns';

import Link from '../routing/Link';

// constants
import { UserTypeEnum, EventStatusEnum } from '../constants';

// Icons
import { CameraIcon } from '../assets/img/icons/Camera';
import { CheckIcon } from '../assets/img/icons/Check';
import { CloseIcon } from '../assets/img/icons/Close';
import { ProgressIcon } from '../assets/img/icons/Progress';
import { CalendarStatusIcon } from '../assets/img/icons/Calendar';
import { ClockIcon } from '../assets/img/icons/Clock';

// Components
import { UserImage } from './UserImage';

// Utils
import formatWithLocale from '../utils/formatWithLocale';

interface IEventItemProps extends IEvent {
  isInHistory?: boolean;
  warningMessage?: string;
}

let interval: null | number | NodeJS.Timeout = null;

export const EventItem: React.FC<IEventItemProps> = props => {
  const { id, participants, isInHistory, warningMessage } = props;

  const [status, setStatus] = React.useState<JSX.Element | null>(null);
  const startDate = new Date(props.startDate);
  const endDate = new Date(props.endDate);
  const doctor = participants.find(
    user => user.type === UserTypeEnum.Doctor,
  );

  const createStatusElement = (
    Icon: React.FC<any>,
    color: string,
    text: string,
  ) => {
    return (
      <>
        <Icon fillColor={color} className={"status-icon"}/>
        <span style={{ color }} className="status-text text status-text-loading">{text}</span>
      </>
    );
  };

  const updateStatus = () => {
    const currentDate = new Date();
    const hoursDifference = differenceInHours(startDate, currentDate);

    if (isWithinInterval(currentDate, { start: startDate, end: endDate })) {
      setStatus(createStatusElement(ProgressIcon, '#CA2C92', 'Pågående')); // In progress
    } else if (differenceInHours(startDate, currentDate) > 3) {
      setStatus(
        createStatusElement(CalendarStatusIcon, '#8662B2', 'Kommande besök'), // Future visit
      );
    } else if (hoursDifference > 0) {
      setStatus(
        createStatusElement(
          ClockIcon,
          '#F5C004',
          `Startar om ${formatDistance(currentDate, startDate)}`,
        ),
      );
    }
  };

  if (!doctor) {
    return null;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (isInHistory) {
      if (props.status.slug === EventStatusEnum.Canceled) {
        setStatus(createStatusElement(CloseIcon, "#889199", 'Avbokad')); // Canceled
      } else {
        setStatus(createStatusElement(CheckIcon, "#889199", 'Avklarad')); // Completed
      }
    } else {
      updateStatus();
      interval = setInterval(updateStatus, 60 * 1000);
    }

    return () => {
      if (typeof interval === "number") {
        clearInterval(interval);
      }
    };
  }, []);

  return (
    <Link className={ "event-feature" + (isInHistory ? " disabled" : "")} id={String(id)} to={'event'}>
      <div className={"photo-wrap"}>
        <UserImage
          className={"photo"}
        />

        <div className="camera-wrap">
          <CameraIcon className="camera"/>
        </div>
      </div>

      <div className="event-info event-info-tab">
        <span className="text text-bold">{doctor.name}</span>
        <div className="event-subinfo">{status}</div>
        {warningMessage && (
          <div>
            <span>{warningMessage}</span>
          </div>
        )}
      </div>

      <div className="event-date">
        <span className="date text">{formatWithLocale(startDate, 'dd.MM.yy HH:mm')}</span>
        {!props.isReaded && !isInHistory && (<span className="event-new text-bold">Nytt</span>)}
      </div>
    </Link>
  );
};
