import React from 'react';
import { useSelector } from 'react-redux';
import Scrollbars from "react-custom-scrollbars";

import { getEvents, getEventsState } from '../../../store';

// Components
import { EventItem } from '../../../components/EventItem';
import { TabMessage } from '../../../components/TabMessage';

// constants
import { EventStatusEnum } from '../../../constants';

export const ActiveEvents: React.FC = () => {
  const events = useSelector(getEvents);
  const eventsState = useSelector(getEventsState);
  const activeEvents = events?.filter(event => {
    const endDate = new Date(event.endDate);
    return event.status.slug === EventStatusEnum.Active && endDate.getTime() > new Date().getTime();
  })

  return (
    <div className={"subtab-content-item subtab-content-booked loading-element" + (!!eventsState?.isLoading ? " loading-page" : "")}>
      {!activeEvents || !activeEvents.length
        ? <TabMessage title={'Du har inga bokade videosamtal'}/>
        :
        <Scrollbars>
          {activeEvents.map(event => {
            return <EventItem key={event.id} {...event} />;
          })}
        </Scrollbars>
      }
    </div>
  );
};
