import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// @ts-ignore
import {
  getAuthStatus,
  getUserAttributes,
  UserAPI,
  loginAction,
  getAuthToken,
  DevicePlatformEnum, getAuthData
} from '../store';
import {clearAccess, getSavedAccess, saveAccess} from '../utils/manageAccess';

// Views
import { MainView } from "./Main";
import LoginView from "./Login";

// constants
// import {
//   authenticationTokenKey,
//   personalNumberKey,
// } from "../constants/Keys";
import Loader from "../components/Loader";

//Utils
import { getFCMToken } from "../utils/FCMToken";

export const RootView: React.FC = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getAuthStatus);
  const userAttributes: any = useSelector(getUserAttributes);
  const authData: any = useSelector(getAuthData);
  const authToken = useSelector(getAuthToken);
  let [loading, setLoading] = useState<boolean>(true);
  React.useEffect(() => {
    (async () => {
      try {
        const authData = getSavedAccess();
        if (authData) {
          try {
            const FCMToken = await getFCMToken();
            const userData = await UserAPI.initUserSession(
              authData.accessToken,
              {
                FCMToken: FCMToken,
                platform: DevicePlatformEnum.Web,
              }
            );

            if (typeof userData === "object" && userData?.attributes) {
              dispatch(
                loginAction({
                  credentials: {
                    login: "",
                    password: "",
                    FCMToken: FCMToken,
                    platform: DevicePlatformEnum.Web,
                  },
                  userData,
                  authData,
                })
              );
              setLoading(false);
            } else {
              setLoading(false);
            }
          } catch (error) {
            clearAccess()
            process.env.REACT_APP_SHOW_LOGS === "true" && console.log(error);
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      } catch (error) {
        process.env.REACT_APP_SHOW_LOGS === "true" && console.error(error);
        setLoading(false);
      }
    })();
  }, []);

  React.useEffect(() => {
    if (isAuthenticated) {
      if (userAttributes && authToken) {
        saveAccess(authData)
      }
    }
  }, [isAuthenticated]);

  if (loading) return <Loader show={true} />;

  if (isAuthenticated) {
    return <MainView />;
  }

  return <LoginView />;
};
