import React from 'react';

// ICons
import { AttachmentIcon } from '../assets/img/icons/Attachmen';

// Constants
interface IAttachmentProps {
  uri?: string;
  name: string;
}

export const Attachment: React.FC<IAttachmentProps> = props => {
  const { uri, name } = props;

  return (
    <a
      href={uri}
      download={true}
      key={name}
      className={"btn-file-saver"}
    >
      <AttachmentIcon className={"clip"} />
      <span className="file">{name}</span>
    </a>
  );
};