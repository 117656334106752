export function personalNumberFormatting(personalNumber: string) {
  if (!personalNumber || personalNumber.length !== 12) {
    return personalNumber;
  }

  const year = personalNumber.substr(0, 4);
  const month = personalNumber.substr(4, 2);
  const day = personalNumber.substr(6, 2);
  const id = personalNumber.substr(8);

  return `${year} ${month} ${day} - ${id}`;
}
