import { all, put, takeEvery, select } from "redux-saga/effects";
import {IAuthData} from '../../model';

// Exceptions
import { BadRequest } from '../../../Exceptions';

// Api
import { UserAPI } from '../../../API';
import { IUserInfo } from '../../../API';

// Actions
import { getAuthToken, getDeviceCreds } from '../index';
import {
  loginAction,
  setUserDataAction,
  setUserStateAction,
  logoutAction,
} from '../actions';
import { DevicePlatformEnum } from "../../../API";

export function* forceLogout() {
  const creds: {
    platform: DevicePlatformEnum;
    FCMToken: string;
  } = yield select(getDeviceCreds);

  yield put(logoutAction.request(creds));
}

export function* loginSaga({ payload }: ReturnType<typeof loginAction>) {
  if (payload.userData && payload.authData) {
    yield put(
      setUserDataAction({
        platform: payload.credentials.platform,
        FCMToken: payload.credentials.FCMToken,
        userData: payload.userData,
        authData: payload.authData,
      })
    );
  } else {
    yield put(
      setUserStateAction({
        isLoading: true,
        message: "Starta sessionen",
      })
    );

    try {
      const res = (yield UserAPI.login({
        login: payload.credentials.login,
        password: payload.credentials.password,
        // FCMToken: payload.credentials.FCMToken,
        platform: payload.credentials.platform,
      })) as { account: IUserInfo; tokens: IAuthData };

      if (res) {
        yield put(
          setUserDataAction({
            platform: payload.credentials.platform,
            FCMToken: payload.credentials.FCMToken,
            userData: res.account,
            authData: res.tokens
          })
        );

        yield put(
          setUserStateAction({
            isLoading: false,
            message: "Sessionen har börjat",
          })
        );
      } else {
        throw new BadRequest();
      }
    } catch (error) {
      yield put(
        setUserStateAction({
          isLoading: false,
          message: "Det gick inte att starta sessionen",
          error: true,
        })
      );
    }
  }
}

export function* logoutSaga({
  payload,
}: ReturnType<typeof logoutAction.request>) {
  //@ts-ignore
  const authToken = yield select(getAuthToken);

  try {
    //@ts-ignore
    const res = yield UserAPI.logout(authToken, payload);

    if (!res && res.code) {
      yield put(logoutAction.failure());
    } else {
      yield put(logoutAction.success());
    }
  } catch (error) {
    console.log(error);
    yield put(logoutAction.failure());
  }
}

const defaultMessage = "Starta autentiseringssession";

// export function* authPollingSaga({
//   payload,
// }: ReturnType<typeof initAuthPollingAction>) {
//   const { sessionId, deviceToken, FCMToken, platform } = payload;
//
//   yield put(
//     setUserStateAction({
//       message: defaultMessage,
//       isLoading: true,
//     })
//   );
//
//   while (true) {
//     try {
//       const isPaused: boolean = yield select(getPollingState);
//
//       if (isPaused) {
//         yield take(resumePolling);
//       }
//
//       const data = (yield UserAPI.validateBanksIdSession(
//         sessionId,
//         deviceToken
//       )) as IUserValidateResponse;
//
//       yield put(
//         setUserStateAction({
//           message: data?.userMessage || defaultMessage,
//           isLoading: !data?.isCompleted,
//         })
//       );
//
//       if (
//         !data ||
//         (data.isCompleted && (!data.authenticationToken || !data.patientInfo))
//       ) {
//         yield put(
//           setUserStateAction({
//             message: "Kunde inte logga in! Försök senare",
//             isLoading: false,
//             error: true,
//           })
//         );
//         break;
//       }
//
//       if (data.isCompleted) {
//         yield put(
//           loginAction({
//             authenticationToken: data.authenticationToken!,
//             credentials: {
//               // personalNumber: data.patientInfo!.personalNumber!,
//               FCMToken,
//               platform,
//             },
//           })
//         );
//         break;
//       }
//     } catch (error) {
//       console.log(error);
//       yield put(
//         setUserStateAction({
//           // @ts-ignore
//           message: error.message || "Kunde inte logga in! Försök senare",
//           isLoading: false,
//           error: true,
//         })
//       );
//       break;
//     }
//
//     yield delay(500);
//   }
// }

export function* authSaga() {
  yield all([
    takeEvery(loginAction, loginSaga),
    // takeEvery(initAuthPollingAction, authPollingSaga),
    takeEvery(logoutAction.request, logoutSaga),
  ]);
}
