import React from 'react';

interface ICameraIconProps {
  style?: React.CSSProperties;
  fillColor?: string;
  className?: string
}

export const CameraIcon: React.FC<ICameraIconProps> = props => {
  const { style, fillColor, className } = props;

  return (
    <svg style={style} viewBox="0 0 11 11" className={className}>
      <path
        fill={fillColor || "#237DB2"}
        d="M10.584 2.4784C10.336 2.34575 10.0377 2.3595 9.81026 2.51116L7.45117 4.03446L7.88982 4.71402L10.1914 3.22793V7.77249L7.88714 6.31106L7.45401 6.99399L9.80528 8.48535C9.93145 8.56946 10.0772 8.61193 10.2237 8.61193C10.3469 8.61193 10.4708 8.58201 10.584 8.5212C10.8406 8.38398 11.0001 8.11719 11.0001 7.82521V3.17439C11.0001 2.88242 10.8406 2.61577 10.584 2.4784Z"
      />
      <path 
        fill={fillColor || "#237DB2"}
        d="M7.4143 2.21753H0.674037C0.302375 2.21753 0 2.51988 0 2.89155V8.10853C0 8.48019 0.302375 8.78254 0.674037 8.78254H7.4143C7.78596 8.78254 8.08834 8.48017 8.08834 8.10853V2.89155C8.08834 2.51988 7.78596 2.21753 7.4143 2.21753ZM7.27951 7.97371H0.808832V3.02636H7.27951V7.97371Z"
      />
      <path 
        fill={fillColor || "#237DB2"}
        d="M4.664 3.99695H2.03529C1.81191 3.99695 1.63086 4.178 1.63086 4.40135C1.63086 4.62473 1.81191 4.80578 2.03529 4.80578H4.664C4.88737 4.80578 5.06842 4.62473 5.06842 4.40135C5.06842 4.17798 4.88737 3.99695 4.664 3.99695Z"
      />
    </svg>
  );
}

export const PhotoCameraIcon: React.FC<ICameraIconProps> = props => {
  const { style, fillColor, className } = props;

  return (
    <svg style={style} viewBox="0 0 39 31" className={className}>
      <path
        fill={fillColor || "#889199"}
        d="M35.75 5.425V4.125C35.75 3.76604 35.459 3.475 35.1 3.475H31.2C30.841 3.475 30.55 3.76604 30.55 4.125V5.425H28.9776L27.313 2.513C26.7335 1.50128 25.6573 0.876462 24.4914 0.875H14.5086C13.3427 0.876462 12.2665 1.50128 11.687 2.513L10.0223 5.425H9.75V3.475C9.75 3.11604 9.45896 2.825 9.1 2.825H3.9C3.54104 2.825 3.25 3.11604 3.25 3.475V5.425C1.456 5.42711 0.0021125 6.881 0 8.675V26.875C0.0021125 28.669 1.456 30.1229 3.25 30.125H35.75C37.544 30.1229 38.9979 28.669 39 26.875V8.675C38.9979 6.881 37.544 5.42711 35.75 5.425ZM31.85 4.775H34.45V5.425H31.85V4.775ZM4.55 4.125H8.45V5.425H4.55V4.125ZM37.7 26.875C37.7 27.952 36.827 28.825 35.75 28.825H3.25C2.17303 28.825 1.3 27.952 1.3 26.875V8.675C1.3 7.59803 2.17303 6.725 3.25 6.725H10.4C10.6327 6.72549 10.8478 6.6015 10.9642 6.4L12.8154 3.1604C13.1627 2.55257 13.8085 2.17671 14.5086 2.175H24.4914C25.1898 2.17736 25.8339 2.552 26.1813 3.1578L28.0358 6.4C28.1521 6.6015 28.3673 6.72549 28.6 6.725H35.75C36.827 6.725 37.7 7.59803 37.7 8.675V26.875Z"
      />
      <path
        fill={fillColor || "#889199"}
        d="M28.237 19.116C28.2364 19.1157 28.2359 19.1156 28.2354 19.1153C27.9 18.9872 27.5244 19.1551 27.3962 19.4904C25.725 23.8477 20.8379 26.0252 16.4806 24.3539C13.5496 23.2298 11.4834 20.5734 11.115 17.4559L12.7017 19.0425C12.9511 19.3007 13.3625 19.3079 13.6208 19.0585C13.879 18.809 13.8861 18.3976 13.6367 18.1394C13.6315 18.1339 13.6261 18.1286 13.6208 18.1234L10.8628 15.3655C10.609 15.1117 10.1975 15.1117 9.94371 15.3655L7.1825 18.1234C6.93307 18.3816 6.94022 18.7931 7.19843 19.0425C7.4503 19.2859 7.84973 19.2859 8.1016 19.0425L9.7916 17.3525C10.2777 22.7154 15.0191 26.6687 20.382 26.1825C24.0871 25.8467 27.2782 23.4317 28.6078 19.957C28.7376 19.6224 28.5717 19.2458 28.237 19.116Z"
      />
      <path
        fill={fillColor || "#889199"}
        d="M30.9152 13.8916C30.9097 13.8968 30.9044 13.9022 30.8992 13.9075L29.2092 15.5975C28.7242 10.2346 23.9835 6.28042 18.6206 6.76548C14.8998 7.10202 11.6985 9.53513 10.3781 13.03C10.2512 13.3658 10.4206 13.741 10.7564 13.8679C11.0922 13.9948 11.4673 13.8254 11.5942 13.4896C13.2469 9.12522 18.1247 6.92692 22.489 8.57955C25.4392 9.69674 27.5215 12.3643 27.8891 15.4974L26.3024 13.9075C26.0531 13.6493 25.6415 13.6422 25.3833 13.8916C25.1251 14.1409 25.118 14.5525 25.3674 14.8107C25.3726 14.8161 25.378 14.8214 25.3833 14.8266L28.1413 17.5846C28.3951 17.8383 28.8066 17.8383 29.0604 17.5846L31.8183 14.8266C32.0765 14.5773 32.0837 14.1657 31.8342 13.9075C31.5849 13.6493 31.1734 13.6422 30.9152 13.8916Z"
      />
    </svg>
  );
}