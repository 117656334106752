import { all } from "redux-saga/effects";
import { createReducer, ActionType, getType } from "typesafe-actions";

// Handlers
import { fetchEventsSaga } from "./handlers/fetch";
import { initEventsSaga } from "./handlers/init";
import { eventsGeneralSaga } from "./handlers/events";
import { listenEventSocketSaga } from "./handlers/listen";

// Models
import { IStore, IState } from "./../model";
import { IEvent } from "./../../API/model";

// Actions
import * as actions from "./actions";
export * from "./actions";

export type EventsActionType = ActionType<typeof actions>;

export const eventsSaga = function* () {
  yield all([
    fetchEventsSaga(),
    initEventsSaga(),
    eventsGeneralSaga(),
    listenEventSocketSaga()
  ]);
};

export interface IEventsState {
  events: IEvent[] | null;
  state: IState | null;
  unreadedNumber: number;
  allEventsWasFetched: boolean;
}

/* Reducer */
const initialState: IEventsState = {
  events: null,
  state: null,
  unreadedNumber: 0,
  allEventsWasFetched: false
};

export const eventsReducer = createReducer<IEventsState, EventsActionType>(
  initialState
)
  .handleAction(actions.fetchEventsAction.success, (state, { payload }) => ({
    ...state,
    events: [...payload.events].sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime()),
    allEventsWasFetched: true,
    state: {
      isLoading: false,
      message: "Events successfully has been fetched!",
    },
  }))
  .handleAction(actions.eventsSocketNewItemAction, (state, { payload }) => ({
    ...state,
    events: state.events
              ? [...state.events, payload.event].sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())
              : [payload.event],
    unreadedNumber: state.unreadedNumber + 1
  }))
  .handleAction(
    [actions.fetchEventsAction.failure, actions.fetchEventsAction.request],
    (state, { type }) => ({
      ...state,
      state:
        type === getType(actions.fetchEventsAction.failure)
          ? {
              isLoading: false,
              message: "Failed to fetch events",
            }
          : {
              isLoading: true,
              message: "Fetching events",
            },
    })
  )
  .handleAction(actions.readEventAction.success as any, (state, { payload }) => ({
    ...state,
    unreadedNumber: state.unreadedNumber > 0 ? state.unreadedNumber - 1 : 0,
    events: state.events?.map(event => {
      if (event.id === payload.eventId) {
        return {
          ...event,
          isReaded: true
        }
      }

      return event;
    }) || null
  }))
  .handleAction(actions.setUnreadedNumber as any, (state, { payload }) => ({ ...state, unreadedNumber: payload }))
  .handleAction(actions.eventsSocketUpdateItemAction as any, (state, { payload }) => ({
    ...state, events: state.events?.map(event => {
      if (event.id === payload.event.id) {
        return { ...payload.event };
      }

      return event;
    }) || null
  }));

/* Selectors */
export const getEvents = (store: IStore) => store.events.events;
export const getEventsState = (store: IStore) => store.events.state;
export const getTotalUnreadedEventsNumber = (store: IStore) => store.events.unreadedNumber || null;
export const getEventsFetchStatus = (store: IStore) => store.events.allEventsWasFetched;
