import { AuthTypeEnum } from "../../models/models";
import { Unauthorized, BadRequest } from '../Exceptions';

export async function handleErrors<T = {}>(
  fetch: Promise<Response>
): Promise<T> {
  try {
    const res = await fetch;

    // console.log('fetch_res: ', res);

    if (!res.ok) {
      // console.log("Error res", res);
      const error = await res.json();

      // console.log("Error: ", res);

      if (error?.statusCode) {
        if (error.statusCode == 403 || error.statusCode == 401) {
          throw new Unauthorized(error?.message);
        } else {
          throw new BadRequest(error?.message);
        }
      } else if (error?.code || error?.message || error?.userMessage) {
        if (error.code === "Unauthorized") {
          throw new Unauthorized(error?.message || error?.userMessage);
        } else {
          throw new BadRequest(error?.message || error?.userMessage);
        }
      } else {
        throw new Error(`Request failed with code: ${res.status}`);
      }
    }

    const data = await res.json();

    return data as T;
  } catch (error) {
    console.log("Fetch error: ", error);
    throw error;
  }
}

export function authHeader(token: string) {
  return {
    [AuthTypeEnum.access]: `Bearer ${token}`,
    // "Ocp-Apim-Subscription-Key": Config.OCP_APIM_SUBSCRIPTION_KEY,
  };
}
