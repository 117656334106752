import React from "react";
import { Link, generatePath } from "react-router-dom";
import schema, { Pages } from "./index";

interface ILinkProps {
  to: Pages;
  id?: string;
  orderNumber?: string;
  className?: string;
  onClick?: () => void;
}

export const InternalLink: React.FC<ILinkProps> = ({
  to,
  id,
  orderNumber,
  children,
  className,
  onClick
}) => {
  return (
    <Link
      to={
        id || orderNumber
          ? `${generatePath(schema.getLink(to), { id, orderNumber })}`
          : schema.getLink(to) || "/error"
      }
      className={className || ""}
      onClick={() => (onClick ? onClick() : () => {})}
    >
      {children}
    </Link>
  );
};

export default InternalLink;
