import { createAsyncAction, createAction } from "typesafe-actions";

// Types
import { IUserInfo } from "../../API/model";
import {IAuthData} from '../model';
import { DevicePlatformEnum } from "./../../API/config";

/* Actions */
const widgetName = "user";

export type DeviceCreds = { FCMToken?: string; platform: DevicePlatformEnum };

export const pausePolling = createAction(`${widgetName}/AUTH_POLLING_PAUSE`)();
export const resumePolling = createAction(
  `${widgetName}/AUTH_POLLING_RESUME`
)();
export const initAuthPollingAction = createAction(`${widgetName}/AUTH_POLLING`)<
  DeviceCreds & {
    sessionId: string;
    deviceToken: string;
  }
>();
export const setUserStateAction = createAction(`${widgetName}/SET_USER_STATE`)<{
  message: string;
  isLoading: boolean;
  error?: boolean;
}>();
export const loginAction = createAction(`${widgetName}/LOGIN`)<{
  credentials: DeviceCreds & { login: string; password: string };
  userData?: IUserInfo;
  authData?: IAuthData;
}>();
export const setUserDataAction = createAction(`${widgetName}/SET_USER_DATA`)<
  DeviceCreds & {
    userData: IUserInfo;
    authData: IAuthData;
  }
>();
export const updateProfileAction = createAsyncAction(
  `${widgetName}/UPDATE_PROFILE_REQUEST`,
  `${widgetName}/UPDATE_PROFILE_SUCCESS`,
  `${widgetName}/UPDATE_PROFILE_FILED`
)<
  { data: FormData; cb?: (msg: string) => void },
  { updatedUserData: IUserInfo },
  undefined
>();
export const logoutAction = createAsyncAction(
  `${widgetName}/LOGOUT_REQUEST`,
  `${widgetName}/LOGOUT_SUCCESS`,
  `${widgetName}/LOGOUT_FILED`
)<DeviceCreds, undefined, undefined>();
