import React from 'react';

interface IProps {
  show?: boolean
}

export const Loader: React.FC<IProps> = ({show = true}) => {
  return (
    <div className={"wrapper-loader" + (show ? " show" : "")}>
      <div className="lds-spinner">
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
      </div>
    </div>
  );
};

export default Loader