import { IAuthData } from "../Controllers";
import { Config, DevicePlatformEnum } from "./config";
import {
  IUserInfo,
  IUserValidateResponse,
  IUserInitSessionResponse,
} from "./model";
import { handleErrors, authHeader } from "./utils";

class API {
  public async initBankIdSession(
    personalNumber: string
  ): Promise<IUserInitSessionResponse | null> {
    return handleErrors(
      fetch(
        `${Config.USER_API_INIT_BANK_ID}?personalNumber=${personalNumber}`,
        {
          method: "POST",
        }
      )
    );
  }

  public async validateBanksIdSession(
    sessionId: string,
    deviceToken?: string
  ): Promise<IUserValidateResponse | null> {
    const url = new URL(Config.USER_API_VALIDATE_BANK_ID);

    url.searchParams.append("sessionId", sessionId);
    if (deviceToken) url.searchParams.append("deviceToken", deviceToken);

    return handleErrors(fetch(url.toString(), { method: "POST" }));
  }

  public logout(
    authToken: string,
    body: { FCMToken?: string; platform: DevicePlatformEnum }
  ): Promise<string | null> {
    return handleErrors(
      fetch(Config.USER_API_LOGOUT, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          ...authHeader(authToken),
        },
      })
    );
  }

  public async initUserSession(
    accessToken: string,
    body: {
      FCMToken?: string;
      platform?: DevicePlatformEnum;
    }
  ): Promise<IUserInfo | null> {
    return handleErrors(
      fetch(Config.USER_API_INIT_USER_SESSION, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...authHeader(accessToken),
        },
        body: JSON.stringify(body),
      })
    );
  }

  public async login(body: {
    login: string;
    password: string;
    platform?: DevicePlatformEnum;
  }): Promise<{ account: IUserInfo; tokens: IAuthData } | null> {
    return handleErrors(
      fetch(Config.USER_API_LOGIN, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
    );
  }

  public async updateProfile(
    authToken: string,
    formData: FormData
  ): Promise<IUserInfo | null> {
    return handleErrors(
      fetch(Config.USER_API_UPDATE_PROFILE, {
        method: "POST",
        headers: authHeader(authToken),
        body: formData,
      })
    );
  }
}

export const UserAPI = new API();
