import React from 'react';

interface ITabMessageProps {
  title: string;
  subtitle?: string;
}

export const TabMessage: React.FC<ITabMessageProps> = props => {
  const { title, subtitle } = props;

  return (
    <div className={"tab-message"}>
      <span>{title}. </span>
      {subtitle && <span >{subtitle}</span>}
    </div>
  );
};
